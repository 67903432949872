import React from "react";
import { defineMessages } from "react-intl";
import { Field } from "react-final-form";
import { ReportOptions } from "cci-admin";
import { withRouter } from "cci-utility";

const messages = defineMessages({
  certificate: {
    id: "certificate",
    defaultMessage: "Certificate"
  },
  view: {
    id: "view",
    defaultMessage: "View"
  }
});

/**
 * Options for Certificate report.
 */
const Options = ({ location, match, ...props }) => {
  const physicalCountId =
    (location.state && location.state.physicalCountId) ||
    match.params.physicalCountId;
  return (
    <ReportOptions
      title={messages.certificate}
      initialValues={{ physicalCountId }}
      downloadFileType="pdf"
      previewButtonTitle={messages.view}
      {...props}
    >
      <Field name="physicalCountId">{() => null}</Field>
    </ReportOptions>
  );
};

export default withRouter(Options);
