import React, { Fragment, useState } from "react";
import { TouchableOpacity } from "react-native";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import styled, { ThemeProvider, withTheme } from "styled-components/native";
import {
  array as yupArray,
  object as yupObject,
  string as yupString
} from "yup";
import {
  Button,
  Container,
  containerCss,
  Dialog,
  FormCell,
  FormDateField,
  FormSelectValueField,
  FormTextField,
  FormToManyField,
  FormUploadImageField,
  Icon,
  Label,
  layoutCss,
  RowText
} from "cci-component";
import { localize, serverFormatDateRegex } from "cci-utility";
import {
  findPatientByIdQuery,
  findPatientsByNameQuery,
  savePatientMutation,
  deletePatientMutation
} from "../query/Patient";
import CrudField from "./CrudField";
import { countries } from "./countries";

const messages = defineMessages({
  searchByNameEmail: {
    id: "searchByNameEmail",
    defaultMessage: "Search By Name or E-mail"
  },
  noAddressData: {
    id: "noAddressData",
    defaultMessage: "No Address"
  },
  noPhoneData: {
    id: "noPhoneData",
    defaultMessage: "No Phone"
  },
  patient: {
    id: "patient",
    defaultMessage: "Player Name (or blank for team stock)"
  },
  date: {
    id: "date",
    defaultMessage: "Date"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company Name"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  birthDate: {
    id: "birthDate",
    defaultMessage: "Birthdate"
  },
  identifierNumber: {
    id: "identifierNumber",
    defaultMessage: "Identifier Number"
  },
  identifierNumberPlaceholder: {
    id: "identifierNumberPlaceholder",
    defaultMessage: "##### ####"
  },
  socialSecurity: {
    id: "socialSecurity",
    defaultMessage: "Social Security"
  },
  passport: {
    id: "passport",
    defaultMessage: "Passport"
  },
  driverLicense: {
    id: "driverLicense",
    defaultMessage: "Driver License"
  },
  other: {
    id: "other",
    defaultMessage: "Other"
  },
  unknown: {
    id: "unknown",
    defaultMessage: "Unknown"
  },
  work: {
    id: "work",
    defaultMessage: "Work"
  },
  personal: {
    id: "personal",
    defaultMessage: "Personal"
  },
  billing: {
    id: "billing",
    defaultMessage: "Billing"
  },
  home: {
    id: "home",
    defaultMessage: "Home"
  },
  previous: {
    id: "previous",
    defaultMessage: "Previous"
  },
  mobile: {
    id: "mobile",
    defaultMessage: "Mobile"
  },
  main: {
    id: "main",
    defaultMessage: "Main"
  },
  fax: {
    id: "fax",
    defaultMessage: "Fax"
  },
  emailAddress: {
    id: "emailAddress",
    defaultMessage: "E-mail Address"
  },
  internalNote: {
    id: "internalNote",
    defaultMessage: "Internal Note"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  street1: {
    id: "street1",
    defaultMessage: "Street 1"
  },
  zipCode: {
    id: "zipCode",
    defaultMessage: "Zip Code"
  },
  state: {
    id: "state",
    defaultMessage: "State"
  },
  city: {
    id: "city",
    defaultMessage: "City"
  },
  country: {
    id: "country",
    defaultMessage: "Country"
  },
  number: {
    id: "number",
    defaultMessage: "Number"
  },
  extension: {
    id: "extension",
    defaultMessage: "Ext."
  },
  emptyMessage: {
    id: "emptyMessage",
    defaultMessage: "Personnel not found"
  }
});

const RowWrapper = styled.View`
  ${props => props.theme.listRowStyle};
  ${containerCss};
`;

const StyledText2 = styled.Text`
  ${layoutCss};
  color: ${props => props.color || props.theme.fontColor};
  font-weight: ${props => props.fontWeight || props.theme.fontWeight || "normal"};
`;

const StyledText = ({ children, ...props }) => (
  <StyledText2 numberOfLines={1} {...props}>
    {children}
  </StyledText2>
);

const RoundedButton = styled(Button)`
  width: 20px;
  height: 20px;
`;

/**
 * Crud Field for Personnel Model
 */
const PersonnelCrudField = ({
  theme,
  disabled,
  intl,
  ...restProps
}) => {
  const [isAddressListVisible, setIsAddressListVisible] = useState(false),
    [selectedAddressIndex, setSelectedAddressIndex] = useState(0),
    selectAddress = index => {
      setIsAddressListVisible(!isAddressListVisible);
      setSelectedAddressIndex(index);
    },
    showAddressList = data => {
      if (data && data.contact.addresses.length > 0 && !disabled) {
        setIsAddressListVisible(!isAddressListVisible);
      }
    };

  return (
    <CrudField
      {...restProps}
      title={messages.patient}
      label={messages.patient}
      photo={false}
      queryById={findPatientByIdQuery}
      queryByName={findPatientsByNameQuery}
      queryName="findPatientById"
      queryNameByName="findPatientsByName"
      saveMutation={savePatientMutation}
      saveMutationName="savePatient"
      deleteMutation={deletePatientMutation}
      entityName="Patient"
      objectWrapper="patient"
      emptyMessage={messages.emptyMessage}
      searchPlaceholder={messages.searchByNameEmail}
      disabled={disabled}
      inspectProps={{
        autoSnap: false,
        height: 0.8,
        width: 0.6,
        style: { minWidth: 300 }
      }}
      validationSchema={yupObject().shape({
        contact: yupObject().shape({
          birthDate: yupString()
            .nullable()
            .matches(serverFormatDateRegex, "invalidDate"),
          companyName: yupString()
            .nullable()
            .max(50, "max50"),
          firstName: yupString()
            .max(35, "max35")
            .required(),
          lastName: yupString()
            .max(35, "max35")
            .required(),
          emails: yupArray().of(
            yupObject().shape({
              address: yupString().email("invalidEmailFieldError")
            })
          )
        })
      })}
      selectedDisplay={selectedData => (
        <Container width={180} flexDirection="row" height="50px">
          <Container flex={1} margins="0 0 0 10px">
            {selectedData && selectedData.contact && (
              <Fragment>
                <Label numberOfLines={1} fontWeight="bold">
                  {selectedData.contact.firstName +
                    " " +
                    selectedData.contact.lastName}
                </Label>
                <TouchableOpacity onPress={() => showAddressList(selectedData)}>
                  <Label numberOfLines={1} underline="true">
                    {selectedData.contact.addresses[selectedAddressIndex]
                      ? selectedData.contact.addresses[selectedAddressIndex]
                        .line1
                      : selectedData.contact.identifiers[selectedAddressIndex]
                      ? (localize(intl, messages[selectedData.contact.identifiers[selectedAddressIndex].type]) + ': ' + selectedData.contact.identifiers[selectedAddressIndex].identifierNumber)
                      : localize(intl, messages.noAddressData)}
                  </Label>
                </TouchableOpacity>
                <Label numberOfLines={1}>
                  {selectedData.contact.phones[0]
                    ? selectedData.contact.phones[0].phoneNumber
                    : localize(intl, messages.noPhoneData)}
                </Label>
              </Fragment>
            )}
          </Container>

          {/* Addresses List */}
          <Dialog
            isVisible={isAddressListVisible}
            paddings="20px"
            autoSnap={false}
            style={{
              minWidth: "50%",
              maxHeight: "90%",
              maxWidth: "95%"
            }}
            onBackdropPress={() => selectAddress(selectedAddressIndex)}
          >
            {selectedData &&
              selectedData.contact.addresses.map((item, index) => (
                <TouchableOpacity
                  key={item.id}
                  onPress={() => selectAddress(index)}
                >
                  <RowWrapper
                    style={{
                      backgroundColor: "white"
                    }}
                  >
                    <ThemeProvider
                      theme={{
                        fontColor: theme.fontColor
                      }}
                    >
                      <RowText>
                        {item.line1 +
                          " - " +
                          item.city +
                          ", " +
                          item.state +
                          " " +
                          item.zipCode}
                      </RowText>
                    </ThemeProvider>
                  </RowWrapper>
                </TouchableOpacity>
              ))}
          </Dialog>
        </Container>
      )}
      rowData={data => (
        <Fragment>
          <StyledText fontWeight="bold">
            {data.item.contact.firstName + " " + data.item.contact.lastName}
          </StyledText>
          <StyledText>
            {(data.item.contact.addresses &&
              data.item.contact.addresses[0] &&
              data.item.contact.addresses[0].line1) ||
              (data.item.contact.identifiers &&
                data.item.contact.identifiers[0] &&
                (localize(intl, messages[data.item.contact.identifiers[0].type]) + ': ' + data.item.contact.identifiers[0].identifierNumber)) ||
              localize(intl, messages.noAddressData)}
          </StyledText>
          <StyledText>
            {(data.item.contact.phones &&
              data.item.contact.phones[0] &&
              "Mobile: +" + data.item.contact.phones[0].phoneNumber) ||
              localize(intl, messages.noPhoneData)}
          </StyledText>
        </Fragment>
      )}
      inspectContent={form => (
        <Fragment>
          <Section>
            {/* Photo */}
            <FormCell size={60} style={{ paddingRight: 0 }}>
              <FormUploadImageField
                name="contact.photoId"
                size={50}
                border={4}
                entityName="Contact"
                alignSelf="center"
              />
            </FormCell>
            <FormCell size="stretch">
              <FormTextField
                placeholder={messages.companyName}
                name="contact.companyName"
              />
              <FormTextField
                placeholder={messages.firstName}
                name="contact.firstName"
                margins="10px 0"
              />
              <FormTextField
                placeholder={messages.lastName}
                name="contact.lastName"
              />
              <FormDateField
                placeholder={messages.birthDate}
                name="contact.birthDate"
                margins="10px 0 0"
              />
            </FormCell>
          </Section>

          <FormToManyField
            name="contact.identifiers"
            addValidation={yupObject().shape({
              identifierNumber: yupString()
                .required()
            })}
            defaultValues={{ type: "driverLicense" }}
            renderNewOnButton
          >
            {({ name, isNew, push, remove, plus }) => (
              <Container key={name} paddings="10px" width="100%">
                <Section>
                  <FormCell
                    size={25}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 55,
                      justifyContent: "center"
                    }}
                  >
                    <Icon
                      iconSize={25}
                      color={theme.fieldFontColor}
                      name="id-card"
                      weight="bold"
                    />
                  </FormCell>
                  <FormCell
                    size="stretch"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                    <FormSelectValueField
                      options={[
                        messages.socialSecurity,
                        messages.passport,
                        messages.driverLicense,
                        messages.other,
                        messages.unknown
                      ]}
                      name={`${name}.type`}
                      placeholder={messages.driverLicense}
                      backgroundColor="transparent"
                      borderColor="transparent"
                      color={theme.fieldFontColor}
                      width={135}
                    />
                  </FormCell>

                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="minus"
                      disabled={isNew}
                      onPress={remove}
                      iconSize={15}
                    />
                  </FormCell>

                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="plus"
                      disabled={isNew}
                      iconSize={15}
                      fontColor={theme.fontColor}
                      backgroundColor={theme.fieldFontColor}
                      onPress={plus}
                    />
                  </FormCell>
                </Section>

                <Section>
                  <FormTextField
                    flex={1}
                    placeholder={messages.identifierNumberPlaceholder}
                    name={`${name}.identifierNumber`}
                    onBlur={push}
                  />
                </Section>
              </Container>
            )}
          </FormToManyField>

          <FormToManyField
            name="contact.emails"
            addValidation={yupObject().shape({
              address: yupString()
                .required()
                .email("invalidEmailFieldError")
            })}
            renderNewOnButton
          >
            {({ name, isNew, push, remove, value, plus }) => (
              <Container key={name} paddings="10px" width="100%">
                <Section>
                  <FormCell
                    size={25}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 55,
                      justifyContent: "center"
                    }}
                  >
                    <Icon
                      iconSize={25}
                      color={theme.fieldFontColor}
                      name={"envelope"}
                      weight="bold"
                    />
                  </FormCell>
                  <FormCell
                    size="stretch"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                    <FormSelectValueField
                      options={[
                        messages.work,
                        messages.personal,
                        messages.billing
                      ]}
                      name={`${name}.tags`}
                      backgroundColor="transparent"
                      borderColor="transparent"
                      color={theme.fieldFontColor}
                      contentWidth
                    />
                  </FormCell>
                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="minus"
                      disabled={isNew}
                      onPress={remove}
                      iconSize={15}
                    />
                  </FormCell>

                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="plus"
                      disabled={isNew}
                      iconSize={15}
                      fontColor={theme.fontColor}
                      backgroundColor={theme.fieldFontColor}
                      onPress={plus}
                    />
                  </FormCell>
                </Section>

                <Fragment>
                  <Section>
                    <FormTextField
                      flex={1}
                      placeholder={messages.emailAddress}
                      name={`${name}.address`}
                      onBlur={push}
                    />
                  </Section>

                  <Section>
                    <FormTextField
                      flex={1}
                      placeholder={messages.internalNote}
                      name={`${name}.internalNote`}
                      margins="10px 0 0"
                    />
                  </Section>
                </Fragment>
              </Container>
            )}
          </FormToManyField>

          <FormToManyField
            name="contact.addresses"
            addValidation={yupObject().shape({
              line1: yupString().required(),
              city: yupString().required(),
              country: yupString().required()
            })}
            renderNewOnButton
          >
            {({ name, isNew, push, remove, value, plus }) => (
              <Container key={name} paddings="10px" width="100%">
                <Section>
                  <FormCell
                    size={25}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 55,
                      justifyContent: "center"
                    }}
                  >
                    <Icon
                      iconSize={25}
                      color={theme.fieldFontColor}
                      name={"map-marker-alt"}
                      weight="bold"
                    />
                  </FormCell>
                  <FormCell
                    size="stretch"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                    <FormSelectValueField
                      options={[
                        messages.main,
                        messages.work,
                        messages.home,
                        messages.billing,
                        messages.previous
                      ]}
                      name={`${name}.tags`}
                      backgroundColor="transparent"
                      borderColor="transparent"
                      color={theme.fieldFontColor}
                      contentWidth
                    />
                  </FormCell>
                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="minus"
                      disabled={isNew}
                      onPress={remove}
                      iconSize={15}
                    />
                  </FormCell>

                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="plus"
                      disabled={isNew}
                      iconSize={15}
                      fontColor={theme.fontColor}
                      backgroundColor={theme.fieldFontColor}
                      onPress={plus}
                    />
                  </FormCell>
                </Section>

                <Section>
                  <FormTextField
                    flex={1}
                    placeholder={messages.street1}
                    name={`${name}.line1`}
                    onBlur={push}
                  />
                </Section>

                <Section>
                  <FormTextField
                    flex={1}
                    placeholder={messages.zipCode}
                    name={`${name}.zipCode`}
                    margins="10px 0 0"
                  />
                  <FormTextField
                    flex={1}
                    placeholder={messages.state}
                    name={`${name}.state`}
                    margins="10px 0 0 10px"
                  />
                </Section>
                <Section>
                  <FormTextField
                    placeholder={messages.city}
                    name={`${name}.city`}
                    onBlur={push}
                    margins="10px 0 0"
                    flex={1}
                  />
                </Section>
                <Section>
                  <FormSelectValueField
                    options={countries}
                    name={`${name}.country`}
                    placeholder={messages.country}
                    onChangeText={push}
                    margins="10px 0 0"
                    flex={1}
                  />
                </Section>
              </Container>
            )}
          </FormToManyField>

          <FormToManyField
            name="contact.phones"
            addValidation={yupObject().shape({
              phoneNumber: yupString().required()
            })}
            renderNewOnButton
          >
            {({ name, isNew, push, remove, value, plus }) => (
              <Container key={name} paddings="10px" width="100%">
                <Section>
                  <FormCell
                    size={25}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 55,
                      justifyContent: "center"
                    }}
                  >
                    <Icon
                      iconSize={25}
                      color={theme.fieldFontColor}
                      name={"phone"}
                      weight="bold"
                    />
                  </FormCell>
                  <FormCell
                    size="stretch"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                    <FormSelectValueField
                      options={[
                        messages.work,
                        messages.home,
                        messages.mobile,
                        messages.main,
                        messages.fax,
                        messages.billing
                      ]}
                      name={`${name}.tags`}
                      backgroundColor="transparent"
                      borderColor="transparent"
                      color={theme.fieldFontColor}
                      contentWidth
                    />
                  </FormCell>
                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="minus"
                      disabled={isNew}
                      onPress={remove}
                      iconSize={15}
                    />
                  </FormCell>

                  <FormCell size={40}>
                    <RoundedButton
                      ui="miniRound"
                      leftIcon="plus"
                      disabled={isNew}
                      iconSize={15}
                      fontColor={theme.fontColor}
                      backgroundColor={theme.fieldFontColor}
                      onPress={plus}
                    />
                  </FormCell>
                </Section>

                <Section>
                  <FormTextField
                    flex={1.2}
                    placeholder={messages.number}
                    name={`${name}.phoneNumber`}
                    onBlur={push}
                    margins="10px 0 0"
                  />
                  <FormTextField
                    flex={1}
                    placeholder={messages.extension}
                    name={`${name}.extension`}
                    margins="10px 0 0 10px"
                  />
                </Section>
                <Section>
                  <FormTextField
                    placeholder={messages.internalNote}
                    name={`${name}.internalNote`}
                    margins="10px 0 0"
                    flex={1}
                  />
                </Section>
              </Container>
            )}
          </FormToManyField>
        </Fragment>
      )}
    />
  );
};

export default injectIntl(withTheme(PersonnelCrudField));
