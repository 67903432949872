/**
 * TeamGreoupsPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/teamGroup/List";
import Inspect from "../view/teamGroup/Inspect";

const messages = defineMessages({
  teamGroupPlaceholder: {
    id: "teamGroupPlaceholder",
    defaultMessage:
      "Select a Team Group the left to view or edit it.\n\n" +
      "Select + to add a new one."
  }
});

const TeamGreoupsPage = () => (
  <PrimaryPage>
    <ListInspect
      rootPath="/teamgroups"
      left={List}
      placeholderMessage={messages.teamGroupPlaceholder}
      selectionPath="/teamgroups/:id"
      right={Inspect}
    />
  </PrimaryPage>
);

export default TeamGreoupsPage;
