import gql from "graphql-tag";

const physicalCountFieldsFragment = gql`
  fragment PhysicalCountFields on PhysicalCount {
    id
    version
    status
    createdOn
    updatedOn
    submittedStamp
    updatedBy {
      id
      contact {
        id
        firstName
        lastName
      }
    }
    counts {
      id
      version
      status
      onHandQuantity
      countQuantity
      productUnit {
        id
        version
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
      transactions {
        id
        version
        status
        compound
        expirationDate
        scriptNumber
        quantity
        onHandQuantity
        reason
        reset
      }
    }
  }
`;

const physicalCountFields2Fragment = gql`
  fragment PhysicalCountFields2 on PhysicalCount {
    id
    version
    status
    createdOn
    updatedOn
    submittedStamp
    updatedBy {
      id
      contact {
        id
        firstName
        lastName
      }
    }
  }
`;

const physicalCountProductFieldsFragment = gql`
  fragment PhysicalCountProductFields on PhysicalCountProduct {
    id
    version
    status
    onHandQuantity
    countQuantity
    productUnit {
      id
      version
      product {
        id
        version
        name
      }
      unit {
        id
        version
        name
      }
    }
    transactions {
      id
      version
      expirationDate
      scriptNumber
      quantity
      onHandQuantity
      reason
      reset
    }
  }
`;

const findPhysicalCountByIdQuery = gql`
  query findPhysicalCountById($id: UUID) {
    findPhysicalCountById(id: $id) {
      ...PhysicalCountFields
    }
  }
  ${physicalCountFieldsFragment}
`;

const savePhysicalCountMutation = gql`
  mutation savePhysicalCount($physicalCount: PhysicalCountInput) {
    savePhysicalCount(physicalCount: $physicalCount) {
      ...PhysicalCountFields
    }
  }
  ${physicalCountFieldsFragment}
`;

const deletePhysicalCountMutation = gql`
  mutation deletePhysicalCount($physicalCount: PhysicalCountInput) {
    deletePhysicalCount(physicalCount: $physicalCount) {
      id
      version
    }
  }
`;

const reconcileCountProductMutation = gql`
  mutation resetCountProduct($transaction: OfficeProductUnitTransactionInput) {
    resetCountProduct(transaction: $transaction) {
      ...PhysicalCountProductFields
    }
  }
  ${physicalCountProductFieldsFragment}
`;

const findPhysicalCountsByNameQuery = gql`
  query findPhysicalCountsByName(
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findPhysicalCountsByName(
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...PhysicalCountFields2
      }
    }
  }
  ${physicalCountFields2Fragment}
`;

const findNotCountedTransactionsByProductUnitQuery = gql`
  query findNotCountedTransactionsByProductUnit($productUnit: ProductUnitInput) {
    findNotCountedTransactionsByProductUnit(productUnit: $productUnit) {
      expirationDate
      scriptNumber
      sum
      compound
    }
  }
`;

export {
  findPhysicalCountByIdQuery,
  savePhysicalCountMutation,
  deletePhysicalCountMutation,
  findPhysicalCountsByNameQuery,
  findNotCountedTransactionsByProductUnitQuery,
  reconcileCountProductMutation
};
