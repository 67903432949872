import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { graphql, withApollo } from "react-apollo";
import { Field, FormSpy } from "react-final-form";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { withTheme } from "styled-components/native";
import {
  array as yupArray,
  boolean as yupBoolean,
  date as yupDate,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import { InspectSubtitle, ImmutableInspect, MutableInspect } from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  Error,
  Label,
  FormCell,
  FormCheckbox,
  FormDateField,
  FormNumberField,
  FormSelectValueField,
  FormSelectRecordField,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  TextField
} from "cci-component";
import { findBusinessByIdQuery, getSessionInformation, SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import { localize, serverFormatDateRegex, withRouter } from "cci-utility";
import {
  findInventoryUseByIdQuery,
  saveInventoryUseMutation,
  findTransactionsByProductUnitQuery
} from "../../query/InventoryUse";
import PersonnelCrudField from "../PersonnelCrudField";
import { findUnitsByProductQuery } from "../../query/ProductUnit";
import { findProductsByNameQuery } from "../../query/Product";

const messages = defineMessages({
  inventoryUse: {
    id: "inventoryUse",
    defaultMessage: "Dispense Record"
  },
  hystoryMedication: {
    id: "hystoryMedication",
    defaultMessage: "Dispensed History"
  },
  patient: {
    id: "patient",
    defaultMessage: "Personnel"
  },
  date: {
    id: "date",
    defaultMessage: "Date"
  },
  products: {
    id: "products",
    defaultMessage: "Products"
  },
  payType: {
    id: "payType",
    defaultMessage: "Pay Type"
  },
  injuryAilment: {
    id: "injuryAilment",
    defaultMessage: "Notes" // "Injury / Ailment"
  },
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  unitsPackage: {
    id: "unitsPackage",
    defaultMessage: "Strength"
  },
  quantity: {
    id: "quantity",
    defaultMessage: "Quantity"
  },
  availableQty: {
    id: "availableQty",
    defaultMessage: "Available Qty"
  },
  compound: {
    id: "compound",
    defaultMessage: "Compound"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  scriptNumber: {
    id: "scriptNumber",
    defaultMessage: "Rx Number"
  },
  maxQuantity: {
    id: "maxQuantity",
    defaultMessage: "{max} is the maximum"
  },
  expiration: {
    id: "expiration",
    defaultMessage: "Expiration"
  },
  noInventoryAvailable: {
    id: "noneInInventory",
    defaultMessage: "None in Inventory"
  },
  createNew: {
    id: "createNew",
    defaultMessage: "Click to Create a New Order"
  },
  atLeastOne: {
    id: "atLeastOne",
    defaultMessage: "Please enter at least one non-zero quantity"
  },
  team: {
    id: "team",
    defaultMessage: "Team"
  }
});

const payTypeOptions = [
  { defaultMessage: "Worker's Compensation", id: "workers_compensation" },
  { defaultMessage: "Club Pay", id: "club_pay" },
  { defaultMessage: "Personnel Pay", id: "patient_pay" },
  { defaultMessage: "Other", id: "other" },
];

/**
 * Inspect component for the InventoryUses entity, allows the user to create,
 * edit or delete records if they have permission to do so.
 */
const Inspect = ({
  theme,
  intl,
  historical,
  match,
  history,
  client,
  data,
  ...restProps
}) => {
  const InspectComponent = historical ? ImmutableInspect : MutableInspect,
    disabled = match.params.id === "new" ? false : true;
  const [numberError, setNumberError] = useState([]),
    [productSelected, setProductSelected] = useState(null),
    [businessData, setBusinessData] = useState(null),
    [valueState, setValueState] = useState(false);

  if (businessData === null) {
    client.query({
      query: findBusinessByIdQuery,
      variables: {
        id: data.sessionInformation.currentBusiness.id
      }
    })
      .then(({ data: business, loading }) => {
        if (!loading) {
          setBusinessData(business.findBusinessById);
        }
      });
  }

  if (!businessData) {
    return null;
  }

  return (
    <InspectComponent
      title={historical ? messages.dispensedHistory : messages.inventoryUse}
      entityName="InventoryUse"
      query={findInventoryUseByIdQuery}
      queryName="findInventoryUseById"
      mutation={saveInventoryUseMutation}
      mutationName="saveInventoryUse"
      objectWrapper="inventoryUse"
      defaultValues={{}}
      validationSchema={yupObject().shape({
        patient: yupObject()
          .nullable()
          .required(),
        ocurredOn: yupString()
          .nullable()
          .matches(serverFormatDateRegex, "invalidDate")
          .required(),
        payType: yupString().required(),
        description: yupString().required(),
        items: yupArray()
          .of(
            yupObject().shape({
              product: yupObject()
                .nullable()
                .required(),
              productUnit: yupObject()
                .nullable()
                .required(),
              transactions: yupArray()
                .of(
                  yupObject().shape({
                    compound: yupBoolean(),
                    scriptNumber: yupString()
                      .when(
                        "compound",
                        (compound, schema) => compound === true ? schema : schema.required()
                      ),
                    expirationDate: yupDate()
                      .nullable()
                      .when(
                        "compound",
                        (compound, schema) => compound === true ? schema : schema.required()),
                    onHandQuantity: yupNumber(),
                    quantity: yupNumber().when(
                      "onHandQuantity",
                      (onHandQuantity, schema) => {
                        // TODO ' ' blank id will return an INTL error
                        return schema.max(onHandQuantity, " ");
                      }
                    )
                    // .required()
                  })

                ).test(
                  "atLeastOne",
                  "atLeastOne",
                  value => {
                    let atLeastOne = false;
                    value.some(tr => {
                      atLeastOne = (tr.quantity && tr.quantity !== undefined && tr.quantity !== null && tr.quantity !== 0) || false;
                      return atLeastOne;
                    })

                    return atLeastOne;
                  }
                )
                .required()
            })
          )
          .required()
      })}
      {...restProps}
    >
      {() => (
        <Container width="100%">
          <Section style={{ zIndex: 99 }}>

            {/* PERSONNEL */}
            <FormCell xlSize="stretch" style={{ zIndex: 99 }}>
              <SessionQuery>
                {({ wrapOffice }) => (
                  <PersonnelCrudField
                    name="patient"
                    listMargins="10px"
                    topList={60}
                    defaultValues={{
                      contact: {
                        office: wrapOffice().office,
                        type: "person"
                      }
                    }}
                    disabled={disabled}
                  />
                )}
              </SessionQuery>
            </FormCell>

            {/* OCURRED ON */}
            <FormCell smSize="1/2" xlSize="1/4" style={{ zIndex: 98 }}>
              <FormDateField
                label={messages.date}
                name="ocurredOn"
                disabled={disabled}
              />
            </FormCell>

            {/* PAY TYPE */}
            <FormCell smSize="1/2" xlSize="1/3" style={{ zIndex: 97 }}>
              <FormSelectValueField
                name="payType"
                label={messages.payType}
                options={payTypeOptions}
                isDisabled={disabled}
              />
            </FormCell>
          </Section>

          {/* NOTES */}
          <Section>
            <FormCell stretch>
              <FormSpy>
                {({ form: { getFieldState } }) => (
                  <FormTextField
                    label={messages.injuryAilment}
                    name="description"
                    autoComplete="off"
                    clearButtonMode="unless-editing"
                    maxLength={256}
                    disabled={disabled}
                    onBlur={() => {
                      getFieldState("business").change({
                        id: businessData.id,
                        version: businessData.version
                      });
                    }}
                  />
                )}
              </FormSpy>
            </FormCell>
          </Section>

          {/* TEAM */}
          <Field name="business">
            {({ input }) => {
              if (disabled && businessData.id !== input.value.id) {
                return (
                  <Section>
                    <FormCell smSize="1/2" xlSize="1/3">
                      <TextField
                        value={input.value && input.value.contact && input.value.contact.companyName}
                        label={messages.team}
                        disabled
                      />
                    </FormCell>
                  </Section>
                );
              }
              return null;
            }}
          </Field>

          <InspectSubtitle title={messages.products} />

          {/* ITEMS */}
          <FormToManyField
            name="items"
            addValidation={yupObject().shape({
              product: yupObject().required(),
              productUnit: yupObject().required(),
              transactions: yupArray()
                .of(
                  yupObject().shape({
                    compound: yupBoolean(),
                    scriptNumber: yupString()
                      .when(
                        "compound",
                        (compound, schema) => compound === true ? schema : schema.required()
                      ),
                    expirationDate: yupDate()
                      .nullable()
                      .when(
                        "compound",
                        (compound, schema) => compound === true ? schema : schema.required()),
                    // maxQuantity: yupNumber(),
                    quantity: yupNumber() /*.when("maxQuantity", (maxQuantity, schema) => {
                        return schema.max(maxQuantity, "maxQuantity")
                      })*/
                    // .required()
                  })
                )
                .required()
            })}
            renderNewOnButton
          >
            {({ name, isNew, push, remove, value, index, plus, length }) => (
              <Container
                key={name}
                flexDirection="row"
                flexShrink={1}
                paddings="0 10px"
                width="100%"
              >
                {!disabled ? (
                  <DeletableItem
                    allowDelete={!isNew}
                    height={60}
                    margins="10px 0 0"
                    iconName="times"
                    confirmMessage={messages.confirmDeleteItem}
                    onConfirm={remove}
                    checkPermissions={{
                      objects: "InventoryUse",
                      actions: ["update"]
                    }}
                  >
                    <RoundedIcon
                      iconSize={50}
                      color={theme.fieldFontColor}
                      name={isNew ? "plus" : "capsules"}
                    />
                  </DeletableItem>
                ) : (
                  <Container margins="10px 0 0">
                    <RoundedIcon
                      iconSize={50}
                      color={theme.fieldFontColor}
                      name={isNew ? "plus" : "capsules"}
                    />
                  </Container>
                )}

                <Section style={{ flex: 1 }}>
                  <FormSpy>
                    {({ form: { getFieldState } }) => (
                      <Fragment>

                        {/* PRODUCT */}
                        <Container
                          margins="10px 0 0 10px"
                          flex={2}
                          style={{ minWidth: 200 }}
                        >
                          <FormSelectRecordField
                            query={findProductsByNameQuery}
                            enableServerSearch
                            variables={{
                              allTeam: disabled,
                              business: {
                                id: businessData.id
                              }
                            }} // User is allowed to query all Products when this Medication is already saved and disabled to edit. This is because of the Personnel from other Teams.
                            name={`${name}.product`}
                            label={messages.product}
                            onChangeText={prodSelected => {
                              setProductSelected(prodSelected);
                              getFieldState(`${name}.productUnit`).change(
                                undefined
                              );
                              getFieldState(`${name}.transactions`).change(
                                []
                              );
                              setValueState(false);
                              if (push) push();
                            }}
                            isDisabled={disabled}
                          />
                        </Container>

                        {/* PRODUCT UNIT */}
                        <Container
                          margins="10px 0 0 10px"
                          flex={1}
                          style={{ minWidth: 150 }}
                        >
                          <FormSelectRecordField
                            query={findUnitsByProductQuery}
                            skip={!productSelected}
                            variables={{
                              business: {
                                id: businessData.id
                              },
                              product: {
                                id: productSelected && productSelected.id
                              }
                            }}
                            name={`${name}.productUnit`}
                            label={messages.unitsPackage}
                            labelNumberofLines={1}
                            onChangeText={pUnitSelected => {
                              client
                                .query({
                                  query: findTransactionsByProductUnitQuery,
                                  fetchPolicy: "no-cache",
                                  skip: !pUnitSelected,
                                  variables: {
                                    business: {
                                      id: businessData.id
                                    },
                                    productUnit: {
                                      id: pUnitSelected && pUnitSelected.id
                                    }
                                  }
                                })
                                .then(tResult => {
                                  if (
                                    tResult.data
                                      .findTransactionsByProductUnit &&
                                    tResult.data
                                      .findTransactionsByProductUnit
                                      .length > 0
                                  ) {
                                    const newTransactions = tResult.data.findTransactionsByProductUnit.map(
                                      transactionOrdered => {
                                        return {
                                          business: {
                                            id: businessData.id,
                                            version: businessData.version
                                          },
                                          compound:
                                            transactionOrdered.compound,
                                          scriptNumber:
                                            transactionOrdered.scriptNumber,
                                          expirationDate:
                                            transactionOrdered.expirationDate,
                                          // maxQuantity:
                                          onHandQuantity:
                                            transactionOrdered.sum
                                        };
                                      }
                                    );
                                    getFieldState(
                                      `${name}.transactions`
                                    ).change(newTransactions);
                                    if (push) push();
                                    setValueState(true);
                                  } else {
                                    getFieldState(
                                      `${name}.transactions`
                                    ).change([]);
                                    setValueState(false);
                                  }
                                });
                            }}
                            labelKey="unit.name"
                            isDisabled={disabled}
                            excludeValues={{
                              values: value.map(
                                productUnitItem =>
                                  productUnitItem.productUnit
                              ),
                              equalsFn: (option, productUnitValue) =>
                                option &&
                                productUnitValue &&
                                option.id === productUnitValue.id
                            }}
                          />
                        </Container>

                        {/* TRANSACTIONS */}
                        <Container
                          margins="10px 0 0 10px"
                          flex={3}
                          style={{ minWidth: 210 }}
                        >
                          <FormToManyField
                            name={`${name}.transactions`}
                            renderNewOnButton
                            flexDirection="column"
                          >
                            {({ name: nameT, index }) => (
                              <Container
                                key={index}
                                flexDirection="row"
                                width="100%"
                                flexWrap="wrap"
                              >
                                {getFieldState(`${name}.transactions`) &&
                                  getFieldState(`${name}.transactions`).value &&
                                  getFieldState(`${name}.transactions`).value.length > 0 ? (
                                  <Fragment>
                                    <Field name={`${nameT}.compound`}>{() => null}</Field>

                                    {/* Compound CHECKMARK */}
                                    {getFieldState(`${nameT}.compound`) && getFieldState(`${nameT}.compound`).value === true && (
                                      <Container
                                        margins="0 5px 16px 0"
                                        width={70}
                                      >
                                        <FormCheckbox
                                          leftLabel={messages.compound}
                                          name={`${nameT}.compound`}
                                          flexDirection="column"
                                          justifyContent="space-between"
                                          backgroundColor='white'
                                          labelMargins="0 5px 10px 0"
                                          disabled
                                        />
                                      </Container>
                                    )}

                                    {getFieldState(`${nameT}.compound`) && getFieldState(`${nameT}.compound`).value !== true && (
                                      <Fragment>

                                        {/* RX NUMBER */}
                                        <Container
                                          style={{ minWidth: 80 }}
                                          flex={1}
                                          margins="0 5px 16px 0"
                                        >
                                          <FormTextField
                                            name={`${nameT}.scriptNumber`}
                                            label={messages.scriptNumber}
                                            labelNumberofLines={1}
                                            disabled
                                          />
                                        </Container>

                                        {/* EXPIRATION DATE */}
                                        <Container
                                          style={{ minWidth: 115 }}
                                          flex={1.1}
                                          margins="0 5px 16px 0"
                                        >
                                          <FormDateField
                                            name={`${nameT}.expirationDate`}
                                            label={messages.expiration}
                                            disabled
                                          />
                                        </Container>

                                      </Fragment>
                                    )}

                                    {/* ON-HAND QUANTITY */}
                                    {!disabled ? (
                                      <Container
                                        style={{ minWidth: 90 }}
                                        flex={1}
                                        margins="0 5px 16px 0"
                                      >
                                        <FormNumberField
                                          name={`${nameT}.onHandQuantity`}
                                          label={messages.availableQty}
                                          labelNumberofLines={1}
                                          disabled
                                        />
                                      </Container>
                                    ) : null}

                                    {/* QUANTITY */}
                                    <Container
                                      style={{ minWidth: 60 }}
                                      flexDirection="column"
                                      margins="0 0 16px 0"
                                      flex={1}
                                    >
                                      <FormNumberField
                                        label={messages.quantity}
                                        name={`${nameT}.quantity`}
                                        onChangeText={number => {
                                          const valuesArray = getFieldState(
                                            `${name}.transactions`
                                          ).value,
                                            greaterThanArray = valuesArray.map(
                                              (transaction, indexTrans) => {
                                                const test = yupNumber()
                                                  .test(
                                                    "maxQuantity",
                                                    "maxQuantity",
                                                    value =>
                                                      value >
                                                      transaction.onHandQuantity
                                                  )
                                                  .isValidSync(
                                                    transaction.quantity
                                                  );
                                                if (test) {
                                                  return {
                                                    message: localize(
                                                      intl,
                                                      messages.maxQuantity,
                                                      {
                                                        max:
                                                          transaction.onHandQuantity
                                                      }
                                                    ),
                                                    index: indexTrans
                                                  };
                                                }

                                                return test;
                                              }
                                            );
                                          setNumberError(greaterThanArray);
                                        }}
                                        autoComplete="off"
                                        setNegative
                                        disabled={disabled}
                                      />
                                      {numberError.length > 0 &&
                                        numberError.find(
                                          errorI => errorI.index === index
                                        ) && (
                                          <Error>
                                            {
                                              numberError.find(
                                                errorI =>
                                                  errorI.index === index
                                              ).message
                                            }
                                          </Error>
                                        )}
                                    </Container>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Label
                                      margins="30px 0 0"
                                      flex={1}
                                      numberOfLines={1}
                                    >
                                      {localize(
                                        intl,
                                        messages.noInventoryAvailable
                                      ) + ","}
                                    </Label>
                                    <Button
                                      title={messages.createNew}
                                      margins="30px 0 0 5px"
                                      ui="link"
                                      fontSize={14}
                                      letterSpacing="0"
                                      onPress={() =>
                                        history.push("/savedorders/new")
                                      }
                                    />
                                  </Fragment>
                                )}
                              </Container>
                            )}
                          </FormToManyField>
                        </Container>
                      </Fragment>
                    )}
                  </FormSpy>

                  {/* PLUS BUTTON */}
                  <SecuredComponent
                    checkPermissions={{
                      objects: "InventoryUseItem",
                      actions: ["create"]
                    }}
                  >
                    {!isNew &&
                      index + 1 === length &&
                      !disabled &&
                      valueState && (
                        <Button
                          leftIcon="plus"
                          margins="0 0 25px 10px"
                          ui="dangerLink"
                          alignSelf="flex-end"
                          leftIconWeight={900}
                          onPress={plus}
                        />
                      )}
                  </SecuredComponent>
                </Section>
              </Container>
            )}
          </FormToManyField>
        </Container>
      )}
    </InspectComponent >
  );
};

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

export default graphql(getSessionInformation)(withApollo(injectIntl(withTheme(withRouter(Inspect)))));
