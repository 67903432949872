/**
 * PatientsPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/patient/List";
import Inspect from "../view/patient/Inspect";

const messages = defineMessages({
  patientsPlaceholder: {
    id: "patientsPlaceholder",
    defaultMessage:
      "Select personnel from the left to view or edit.\n\n" +
      "Select + to add new personnel. "
  }
});

const ProductsPage = ({ teamChange }) => (
  <PrimaryPage>
    <ListInspect
      rootPath={teamChange ? "/personnelteam" : "/personnel"}
      left={List}
      placeholderMessage={messages.patientsPlaceholder}
      selectionPath={teamChange ? "/personnelteam/:id" : "/personnel/:id"}
      right={Inspect}
      leftRightOptions={{ teamChange }}
    />
  </PrimaryPage>
);

export default ProductsPage;
