import React from "react";
import { defineMessages } from "react-intl";
import { ReportOptions } from "cci-admin";
import { SessionQuery } from "cci-data";
import { Field } from "react-final-form";

const messages = defineMessages({
  inventoryOnHand: {
    id: "inventoryOnHand",
    defaultMessage: "Inventory On-Hand"
  },
  view: {
    id: "view",
    defaultMessage: "View"
  }
});

/**
 * Options for Inventory On-Hand report.
 */
const Options = props => (
  <SessionQuery>
    {({ wrapBusiness }) => (
      <ReportOptions
        title={messages.inventoryOnHand}
        initialValues={{ businessId: wrapBusiness().business.id }}
        downloadFileType="pdf"
        previewButtonTitle={messages.view}
        {...props}
      >
        <Field name="businessId">{() => null}</Field>
      </ReportOptions>
    )}
  </SessionQuery>
);

export default Options;
