import gql from "graphql-tag";
import { businessFieldsFragment } from "cci-data";

const inventoryUseFieldsFragment = gql`
  fragment InventoryUseFields on InventoryUse {
    id
    version
    ocurredOn
    description
    payType
    business {
      ...BusinessFields
    }
    items {
      id
      quantity
      version
      product {
        id
        version
        name
        status
      }
      productUnit {
        id
        version
        status
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
      transactions {
        id
        version
        expirationDate
        scriptNumber
        quantity
        onHandQuantity
      }
    }
    patient {
      id
      version
      contact {
        id
        version
        companyName
        firstName
        lastName
        photoId
        emails {
          id
          version
          address
          status
          tags
        }
        addresses {
          id
          version
          city
          line1
          state
          tags
          zipCode
        }
        phones {
          id
          version
          phoneNumber
          tags
        }
      }
    }
  }
  ${businessFieldsFragment}
`;

const findInventoryUseByIdQuery = gql`
  query findInventoryUseById($id: UUID) {
    findInventoryUseById(id: $id) {
      ...InventoryUseFields
    }
  }
  ${inventoryUseFieldsFragment}
`;

const findTransactionsByProductUnitQuery = gql`
  query findTransactionsByProductUnit(
    $business: BusinessInput
    $productUnit: ProductUnitInput
  ) {
    findTransactionsByProductUnit(
      business: $business,
      productUnit: $productUnit
    ) {
      expirationDate
      scriptNumber
      sum
      compound
    }
  }
`;

const findTransactionsByScriptNumberQuery = gql`
  query findByScriptNumber (
    $scriptNumber: String
    $notId: String
    $business: BusinessInput
  ) {
    findByScriptNumber (
      scriptNumber: $scriptNumber
      notId: $notId
      business: $business,
    ) {
      id
      expirationDate
      scriptNumber
      quantity
    }
  }
`;

const findBusinessTransactionsQuery = gql`
  query findBusinessTransactions(
    $business: BusinessInput
  ) {
    findBusinessTransactions(
      business: $business
    ) {
      productUnit {
        id
        version
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
      expirationDate
      scriptNumber
      sum
      compound
    }
  }
`;

const findInventoryUsesByNameQuery = gql`
  query findInventoryUsesByName(
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findInventoryUsesByName(
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
        sort: { orders: [] }
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        id
        version
        ocurredOn
        patient {
          id
          version
          contact {
            id
            version
            firstName
            lastName
            photoId
          }
        }
      }
    }
  }
`;

const saveInventoryUseMutation = gql`
  mutation($inventoryUse: InventoryUseInput) {
    saveInventoryUse(inventoryUse: $inventoryUse) {
      ...InventoryUseFields
    }
  }
  ${inventoryUseFieldsFragment}
`;

const deleteInventoryUseMutation = gql`
  mutation($inventoryUse: InventoryUseInput) {
    deleteInventoryUse(inventoryUse: $inventoryUse) {
      id
      version
    }
  }
`;

export {
  findInventoryUseByIdQuery,
  findInventoryUsesByNameQuery,
  saveInventoryUseMutation,
  deleteInventoryUseMutation,
  findTransactionsByProductUnitQuery,
  findTransactionsByScriptNumberQuery,
  findBusinessTransactionsQuery
};
