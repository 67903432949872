import React, { useState } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { ThemeProvider, withTheme } from "styled-components/native";
import { debounce } from "lodash";
import {
  array as yupArray,
  date as yupDate,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import { graphql, withApollo } from "react-apollo";
import { Field, FormSpy } from "react-final-form";
import { InspectSubtitle, InspectTitle, MutableInspect } from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  Dialog,
  Error,
  FormCell,
  FormDateField,
  FormNumberField,
  FormSelectRecordField,
  FormSelectValueField,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  ToastContext
} from "cci-component";
import { findBusinessByIdQuery, getSessionInformation, SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import { localize, serverFormatDateRegex, withRouter } from "cci-utility";
import {
  deleteOutsideInventoryMutation,
  findOutsideInventoryByIdQuery,
  saveOutsideInventoryMutation,
  countByOutsideItemQuery
} from "../../query/OutsideInventory";
import { findUnitsByProductQuery } from "../../query/ProductUnit";
import { findProductsByNameQuery } from "../../query/Product";
import { findTransactionsByScriptNumberQuery } from "../../query/InventoryUse";
import ProductInspect from "../product/Inspect";

const messages = defineMessages({
  newTitle: {
    id: "newTitle",
    defaultMessage: "New"
  },
  notes: {
    id: "notes",
    defaultMessage: "Notes"
  },
  products: {
    id: "products",
    defaultMessage: "Products"
  },
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  strengthSize: {
    id: "strengthSize",
    defaultMessage: "Strength / Size"
  },
  packaging: {
    id: "packaging",
    defaultMessage: "Packaging"
  },
  qtyPackage: {
    id: "qtyPackage",
    defaultMessage: "Qty / Pkg"
  },
  numberOfPkgs: {
    id: "numberOfPkgs",
    defaultMessage: "Number of Pkgs"
  },
  directions: {
    id: "directions",
    defaultMessage: "Directions"
  },
  fulfillShip: {
    id: "fulfillShip",
    defaultMessage: "Fulfill & Ship"
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit"
  },
  status: {
    id: "status",
    defaultMessage: "Status"
  },
  expiration: {
    id: "expiration",
    defaultMessage: "Expiration"
  },
  scriptNumber: {
    id: "scriptNumber",
    defaultMessage: "Rx Number"
  },
  noPatientInfo: {
    id: "noPatientInfo",
    defaultMessage: "No Personnel Information"
  },
  saveForLater: {
    id: "saveForLater",
    defaultMessage: "Save for Later"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  cannotDelete: {
    id: "cannotDelete",
    defaultMessage: "You cannot delete or modify this item. It has been used already."
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company Name"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  type: {
    id: "type",
    defaultMessage: "Status"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  other: {
    id: "other",
    defaultMessage: "Other"
  },
  doctor: {
    id: "doctor",
    defaultMessage: "Doctor"
  },
  trackingNumber: {
    id: "trackingNumber",
    defaultMessage: "Tracking Number"
  },
  deliveryAddressShippingInstructions: {
    id: "deliveryAddressShippingInstructions",
    defaultMessage: "Delivery Address / Shipping Instructions"
  },
  fedexClickHere: {
    id: "fedexClickHere",
    defaultMessage: "Click to track your delivery on fedex.com: {value}"
  },
  payType: {
    id: "payType",
    defaultMessage: "Pay Type"
  },
  alreadyExists: {
    id: "alreadyExists",
    defaultMessage: "This RX Number {value} already exists."
  },
  selectCorrectTeam: {
    id: "selectCorrectTeam",
    defaultMessage: "Do not forget to select the correct team in the header bar."
  }
});

const packagingOptions = [
  // { defaultMessage: "Vial/Bottle", id: "vialBottle" },
  { defaultMessage: "Standard", id: "vialBottle" },
  { defaultMessage: "Blister Pack", id: "blisterPack" }
];

const RXNumberField = ({ client, theme, wrapBusiness, nameT, pushT, indexT, valueT, disabled, getState, getFieldState, foundRXs, setFoundRXs }) => {
  const [errorRX, setErrorRX] = useState(false)

  return (
    <ToastContext.Consumer>
      {toast => (
        <ThemeProvider
          theme={{
            fieldBorderColor: errorRX ? theme.fieldErrorColor : theme.fieldBorderColor,
            fieldBorderTopWidth: errorRX ? 2 : theme.fieldBorderTopWidth,
            fieldBorderRightWidth: errorRX ? 2 : theme.fieldBorderRightWidth,
            fieldBorderBottomWidth: errorRX ? 2 : theme.fieldBorderBottomWidth,
            fieldBorderLeftWidth: errorRX ? 2 : theme.fieldBorderLeftWidth,
            fieldStyle: "border-style: solid; height: 35px"
          }}
        >
          <FormTextField
            label={messages.scriptNumber}
            labelNumberofLines={1}
            name={`${nameT}.scriptNumber`}
            onBlur={({ nativeEvent: { text } }) => {
              let tmp = foundRXs;

              client.query({
                query: findTransactionsByScriptNumberQuery,
                variables: wrapBusiness({
                  scriptNumber: text,
                  notId: valueT && valueT[indexT] && valueT[indexT].id ? valueT[indexT].id : "00000000-0000-0000-0000-000000000000"
                }),
                fetchPolicy: "no-cache"
              })
                .then(({ data }) => {

                  if (text && data && data.findByScriptNumber && data.findByScriptNumber.length > 0) {
                    toast.error(messages.alreadyExists, { value: text });

                    setErrorRX(true);

                    if (!tmp.find(foundRX => foundRX.rx === text)) {
                      tmp.push({ rx: text, ids: data.findByScriptNumber.map(tr => tr.id) });
                      setFoundRXs(tmp);
                    }
                  } else {
                    setErrorRX(false);
                    pushT && pushT()
                  }

                })
                .catch(res => console.log('err', res))
            }}
            disabled={disabled && !getState().hasSubmitErrors}
          />
        </ThemeProvider>
      )}
    </ToastContext.Consumer>
  )
};

/**
 * Function which sets the quantity of transactions on the item.
 * 
 * @param {
 * - nmbr Number of Transactions
 * - getFieldState kind of FieldState getter and setter
 * - name Field's name
 * - businessData Business object for the transaction.
 * } param0 
 */
const transactionChanger = ({ nmbr, getFieldState, name, businessData }) => {
  if (Number(nmbr) > 0) {
    let transactions = getFieldState(`${name}.transactions`);

    if (transactions) {
      const newObj = {
        expirationDate: "",
        scriptNumber: "",
        quantity: getFieldState(`${name}.quantityPackage`).value,
        business: {
          id: businessData.id,
          version: businessData.version
        }
      };

      let tmp = [];
      for (let i = 0; i < Number(nmbr); i++) {
        tmp.push(transactions.value && transactions.value[i] ? transactions.value[i] : newObj);
      }
      transactions.change(tmp);
    }
  }
}

/**
 * transactionChanger Function debounced on 500 ms
 */
const debouncedTransactionChanger = debounce(transactionChanger, 750)

/**
 * Inspect component for the OutsideInventory entity, allows the user to create,
 * edit or delete records if he has permission to do so.
 */
const Inspect = ({ data, client, theme, intl, match, ...restProps }) => {
  const [nameForOther, setNameForOther] = useState(null),
    [foundRXs, setFoundRXs] = useState([]),
    [dataQry, setData] = useState(null),
    [businessData, setBusinessData] = useState(null);
  let disabled;

  if (businessData === null) {
    client.query({
      query: findBusinessByIdQuery,
      variables: {
        id: data.sessionInformation.currentBusiness.id
      }
    })
      .then(({ data: business, loading }) => {
        if (!loading) {
          setBusinessData(business.findBusinessById);
        }
      });
  }

  if (!businessData) {
    return null;
  }

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <MutableInspect
          entityName="OutsideInventory"
          query={findOutsideInventoryByIdQuery}
          queryName="findOutsideInventoryById"
          mutation={saveOutsideInventoryMutation}
          mutationName="saveOutsideInventory"
          objectWrapper="outsideInventory"
          defaultValues={{
            business: {
              id: businessData.id,
              version: businessData.version
            },
            status: "active"
          }}
          onQueryCompleted={data => {
            setData(data.findOutsideInventoryById);
          }}
          validationSchema={yupObject().shape({
            status: yupString().required(),
            items: yupArray()
              .of(
                yupObject().shape({
                  productUnit: yupObject().nullable().required(),
                  packaging: yupString().required(),
                  quantity: yupNumber().required().min(1, "minimum1"),
                  quantityPackage: yupNumber().when("packaging", (packaging, schema) =>
                    packaging !== "vial"
                      ? schema.required().min(1, "minimum1")
                      : schema
                  ),
                  transactions: yupArray().of(
                    yupObject().shape({
                      expirationDate: yupString()
                        .nullable()
                        .matches(serverFormatDateRegex, "invalidDate")
                        .required(),
                      scriptNumber: yupString().test(
                        "notRepeatedRX",
                        "notRepeatedRX",
                        function (value) {
                          const found = foundRXs.find(foundRX => value === foundRX.rx && !foundRX.ids.find(id => id === this.parent.id));

                          return !found;
                        }
                      ).required()
                    })
                  )
                })
              )
              .required()
          })}
          deleteMutation={deleteOutsideInventoryMutation}
          saveMessage={messages.save}
          {...restProps}
        >
          {() => (
            <FormSpy subscription={{ initialValues: true, modified: true, values: true }}>
              {({ form: { getFieldState, getState } }) => {
                disabled = false;

                return (
                  <Container>
                    <Field name="referenceId">
                      {({ input }) => (
                        <Field name="createdOn">
                          {({ input: dateInput }) => (
                            <InspectTitle
                              title={
                                input.value && !(getState() && getState().values && getState().values.id)
                                  ? input.value
                                  : dateInput.value
                                    ? intl.formatDate(dateInput.value, {
                                      timeZone: "UTC",
                                      year: "numeric",
                                      month: "long",
                                      day: "2-digit"
                                    })
                                    : messages.newTitle
                              }
                            />
                          )}
                        </Field>
                      )}
                    </Field>
                    <Field name="business">{() => null}</Field>

                    <Section>
                      <FormCell xlSize="1/3">
                        {/* Reference Id */}
                        <FormTextField
                          name="referenceId"
                          label={messages.notes}
                          maxLength={64}
                          disabled={disabled}
                        />
                      </FormCell>

                      {/* Status */}
                      {/* <FormCell xlSize="1/3">
                        <FormGeneralStatusField />
                      </FormCell> */}
                    </Section>

                    <InspectSubtitle title={messages.products} />

                    <FormToManyField
                      name="items"
                      addValidation={yupObject().shape({
                        productUnit: yupObject().nullable().required(),
                        // packaging: yupString().required(),
                        // quantity: yupNumber().required().min(1, "minimum1"),
                        // quantityPackage: yupNumber().when("packaging", (packaging, schema) =>
                        //   packaging !== "vial"
                        //     ? schema.required().min(1, "minimum1")
                        //     : schema
                        // )
                      })}
                      width="100%"
                      renderNewOnButton
                    >
                      {({
                        name,
                        isNew,
                        push,
                        remove,
                        value,
                        index,
                        plus,
                        length
                      }) => (
                        < ItemComp
                          key={name}
                          {...{ client, theme, intl, wrapBusiness, getFieldState, getState, name, isNew, push, remove, value, index, plus, length, businessData, dataQry, foundRXs, setFoundRXs, setNameForOther }}
                        />
                      )}
                    </FormToManyField>

                    <Dialog
                      isVisible={nameForOther}
                      paddings="5px"
                      width={0.8}
                      height={0.8}
                      autoSnap={false}
                      onBackdropPress={() => setNameForOther(null)}
                    >
                      <ProductInspect
                        myId="new"
                        mdStyle={{ width: "90%" }}
                        marginTop={20}
                        negativeMargin={false}
                        onCompleted={savedData => {
                          setNameForOther(null);
                          getFieldState(`${nameForOther}.productUnit.product`).change(savedData);
                        }}
                        refetchQueries={["findProductsByName"]}
                        blockRedirection
                      />
                    </Dialog>
                  </Container>
                )
              }}
            </FormSpy>
          )}
        </MutableInspect>
      )}
    </SessionQuery>
  );
};

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

const ItemComp = ({ client, theme, intl, wrapBusiness, getFieldState, getState, name, isNew, push, remove, value, index, plus, length, foundRXs, businessData, dataQry, setNameForOther, setFoundRXs }) => {
  const [disabled, setDisabled] = useState(false);
  const itemI = value[index];

  if (itemI && itemI.id) {
    client.query({
      query: countByOutsideItemQuery,
      variables: wrapBusiness({ id: itemI.id }),
      fetchPolicy: "no-cache"
    })
      .then(({ data: dataCount }) => {
        let count = [];
        if (dataCount && dataCount.countByOutsideItem) {
          count = dataCount.countByOutsideItem;
        }
        if (count.find(cnt => cnt.count > 1)) {
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      })
      .catch(res => console.log('err count item.id - ' + itemI.id, res))
  }
  return (
    <Container
      flexDirection="row"
      paddings="0 10px"
      flexShrink={1}
      width="100%"
      style={{
        zIndex: index !== undefined ? 100 - index * 10 : 0
      }}
    >

      {/* DELETABLE ITEM */}
      {disabled ? (
        <Container margins="10px 0 0" style={{ maxWidth: 50 }}>
          <RoundedIcon
            iconSize={50}
            color={theme.fieldFontColor}
            name={isNew ? "plus" : "capsules"}
          />
          <Container margins="10px 0 0">
            <ThemeProvider theme={{ fontSize: 10 }}>
              <Error>{localize(intl, messages.cannotDelete)}</Error>
            </ThemeProvider>
          </Container>
        </Container>
      ) : (
        <DeletableItem
          allowDelete={!isNew}
          height={60}
          margins="10px 0 0"
          iconName="times"
          confirmMessage={messages.confirmDeleteItem}
          onConfirm={remove}
          checkPermissions={{
            objects: "OutsideInventoryItem",
            actions: ["delete"]
          }}
        >
          <RoundedIcon
            iconSize={50}
            color={theme.fieldFontColor}
            name={isNew ? "plus" : "capsules"}
          />
        </DeletableItem>
      )}

      {/* Business Id (Not displayed) */}
      <Field name={`${name}.business`}>{() => null}</Field>
      <Field name={`${name}.oldQuantity`}>{() => null}</Field>
      <Field name={`${name}.oldQuantityPackage`}>{() => null}</Field>

      <Section style={{ flex: 1 }}>
        <Container
          margins="10px 0 0 10px"
          flex={1.5}
          style={{ minWidth: 200 }}
        >

          {/* PRODUCT NAME */}
          <FormSelectRecordField
            query={findProductsByNameQuery}
            enableServerSearch
            variables={{
              business: {
                id: getFieldState("business") && getFieldState("business").value
                  ? getFieldState("business").value.id
                  : wrapBusiness().business.id
              }
            }}
            labelSearch={value => {
              if (value && value.name) {
                return value.name;
              }
            }}
            name={`${name}.productUnit.product`}
            label={messages.product}
            onChangeText={selected => {
              if (selected && selected.id === "new") {
                setNameForOther(name);
              }

              let transactions = getFieldState(`${name}.transactions`);
              let business = getFieldState("business");

              if (!(transactions && transactions.value)) {
                transactions.change([
                  {
                    expirationDate: "",
                    scriptNumber: "",
                    quantity: 0,
                    business: {
                      id: businessData.id,
                      version: businessData.version
                    }
                  }
                ]);
              }
              if (!(business && business.value)) {
                business.change({
                  id: businessData.id,
                  version: businessData.version
                })
              }

              push && push();
            }}
            enableNew
            newLabel={messages.other}
            isDisabled={disabled}
          />
        </Container>

        {/* UNIT / Strength */}
        {getFieldState(`${name}.productUnit.product`) ? (
          <Container
            flex={1}
            margins="10px 0 0 10px"
            style={{ minWidth: 120 }}
          >
            <FormSelectRecordField
              query={findUnitsByProductQuery}
              skip={
                !getFieldState(`${name}.productUnit.product`).value
                || (getFieldState(`${name}.productUnit.product`).value
                  && getFieldState(`${name}.productUnit.product`).value.id === "new")
              }
              variables={wrapBusiness({
                product: {
                  id:
                    getFieldState(`${name}.productUnit.product`).value
                    && getFieldState(`${name}.productUnit.product`).value.id
                }
              })}
              name={`${name}.productUnit`}
              label={messages.strengthSize}
              onChangeText={push}
              labelKey="unit.name"
              isDisabled={disabled}
            />
          </Container>
        ) : null}

        {/* PACKAGING */}
        <Container
          flex={0.5}
          margins="10px 0 0 10px"
          style={{ minWidth: 100 }}
        >
          <FormSelectValueField
            name={`${name}.packaging`}
            label={messages.packaging}
            options={packagingOptions}
            onBlur={push}
            isDisabled={disabled}
          />
        </Container>

        {/* QTY / PKG */}
        <Container
          flex={0.5}
          margins="10px 0 0 10px"
          style={{ minWidth: 70 }}
        >
          <FormNumberField
            name={`${name}.quantityPackage`}
            label={messages.qtyPackage}
            onBlur={push}
            disabled={disabled}
            onChangeText={nmbr => {
              let transactions = getFieldState(`${name}.transactions`);
              let business = getFieldState(`${name}.business`);

              if (transactions && transactions.value && transactions.value.length > 0 && Number(nmbr) > 0) {
                let tmp = new Array(transactions.value.length);
                tmp = transactions.value.map(t => ({ ...t, quantity: Number(nmbr) }));
                transactions.change(tmp);
              }

              if (getFieldState(`${name}.oldQuantity`).value === null) {
                getFieldState(`${name}.oldQuantity`).change(dataQry.items[index].quantity);
              }

              if (getFieldState(`${name}.oldQuantityPackage`).value === null) {
                getFieldState(`${name}.oldQuantityPackage`).change(dataQry.items[index].quantityPackage);
              }

              if (!business.value) {
                business.change({
                  id: businessData.id,
                  version: businessData.version
                });
              }


            }}
          />
        </Container>

        {/* QUANTITY */}
        <Container
          flex={0.7}
          margins="10px 0 0 10px"
          style={{ minWidth: 110 }}
        >
          <FormNumberField
            label={messages.numberOfPkgs}
            name={`${name}.quantity`}
            // onBlur={push}
            disabled={disabled}
            setNegative
            onChangeText={(nmbr) => {
              debouncedTransactionChanger({ nmbr, getFieldState, name, businessData });

              if (getFieldState(`${name}.oldQuantity`).value === null) {
                getFieldState(`${name}.oldQuantity`).change(dataQry.items[index].quantity);
              }

              if (getFieldState(`${name}.oldQuantityPackage`).value === null) {
                getFieldState(`${name}.oldQuantityPackage`).change(dataQry.items[index].quantityPackage);
              }
            }}
          />
        </Container>

        {/* TRANSACTIONS */}
        <Container
          flex={2}
          margins="10px 0 0"
          style={{ minWidth: 220 }}
        >
          <FormToManyField
            name={`${name}.transactions`}
            addValidation={yupObject().shape({
              expirationDate: yupDate(),//.required(),
              scriptNumber: yupString()//.required()
            })}
            renderNewOnButton
          >
            {({
              name: nameT,
              push: pushT,
              index: indexT,
              value: valueT
            }) => (
              <Container
                key={nameT}
                width="100%"
                flexDirection="row"
                margins={
                  indexT !== 0 &&
                  indexT !== undefined &&
                  "10px 0 0"
                }
              >
                <Container
                  style={{ minWidth: 120 }}
                  margins="0 0 0 10px"
                  flex={1}
                >
                  <FormDateField
                    label={messages.expiration}
                    name={`${nameT}.expirationDate`}
                    // onBlur={pushT}
                    disabled={disabled && !getState().hasSubmitErrors}
                  />
                </Container>
                <Container
                  style={{ minWidth: 80 }}
                  margins="0 0 0 10px"
                  flex={1}
                >
                  <RXNumberField
                    {...{
                      client,
                      theme,
                      wrapBusiness,
                      nameT,
                      pushT,
                      indexT,
                      valueT,
                      disabled,
                      getState,
                      getFieldState,
                      foundRXs,
                      setFoundRXs
                    }}
                  />
                </Container>
              </Container>
            )}
          </FormToManyField>
        </Container>

        {/* New Item Button */}
        <SecuredComponent
          checkPermissions={{
            objects: "OutsideInventoryItem",
            actions: ["create"]
          }}
        >
          {!isNew &&
            index + 1 === length && (
              <Button
                leftIcon="plus"
                margins="40px 0 0 10px"
                ui="dangerLink"
                leftIconWeight={900}
                onPress={plus}
              />
            )}
        </SecuredComponent>

      </Section>
    </Container>
  )
};

export default graphql(getSessionInformation)(withApollo(withRouter(injectIntl(withTheme(Inspect)))));
