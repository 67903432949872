/**
 * InventoryPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/inventoryUses/List";
import Inspect from "../view/inventoryUses/Inspect";

const messages = defineMessages({
  inventoryUsesPlaceholder: {
    id: "inventoryUsesPlaceholder",
    defaultMessage:
      "Select a report from the left to see the its detail or add a new one."
  },
  inventoryUsesHistoryPlaceholder: {
    id: "inventoryUsesHistoryPlaceholder",
    defaultMessage: "Select personnel from the left to view history."
  }
});

const InventoryUsesPage = ({ historical }) => {
  const route = historical ? "inventoryuseshistory" : "inventoryuses";
  return (
    <PrimaryPage>
      <ListInspect
        rootPath={`/${route}`}
        left={List}
        placeholderMessage={
          historical
            ? messages.inventoryUsesHistoryPlaceholder
            : messages.inventoryUsesPlaceholder
        }
        selectionPath={`/${route}/:id`}
        right={Inspect}
        leftRightOptions={{ historical }}
      />
    </PrimaryPage>
  );
};

export default InventoryUsesPage;
