import React, { Component, Fragment } from "react";
import { Mutation } from "react-apollo";
import { defineMessages } from "react-intl";
import {
  ConfirmButton,
  Container,
  FormCheckbox,
  FormDateField,
  FormNumberField,
  FormSelectValueField,
  FormTextField,
  FormToManyField,
  Icon,
  ToastContext
} from "cci-component";
import {
  reconcileCountProductMutation
} from "../../query/PhysicalCount";
import { Field, FormSpy } from "react-final-form";
import { ThemeProvider, withTheme } from "styled-components/native";

const messages = defineMessages({
  physicalQty: {
    id: "physicalQty",
    defaultMessage: "Physical Qty"
  },
  virtualQty: {
    id: "virtualQty",
    defaultMessage: "Virtual Qty"
  },
  scriptNumber: {
    id: "scriptNumber",
    defaultMessage: "Rx Number"
  },
  maxQuantity: {
    id: "maxQuantity",
    defaultMessage: "{max} is the maximum"
  },
  compound: {
    id: "compound",
    defaultMessage: "Compound"
  },
  expiration: {
    id: "expiration",
    defaultMessage: "Expiration"
  },
  noInventoryAvailable: {
    id: "noInventoryAvailable",
    defaultMessage: "No Inventory Available"
  },
  reconcile: {
    id: "reconcile",
    defaultMessage: "Reconcile"
  },
  confirmReconcileMutation: {
    id: "confirmReconcileMutation",
    defaultMessage:
      "This transaction CANNOT be undone.\nAre you sure you want to continue?"
  },
  reconcileSuccess: {
    id: "reconcileSuccess",
    defaultMessage: "Inventory Audit reconcile successfully"
  },
  reconcileError: {
    id: "reconcileError",
    defaultMessage: "Inventory Audit reconcile error occurred"
  },
  dispensedWithoutRecording: {
    id: "dispensedWithoutRecording",
    defaultMessage: "Physician dispensed without recording"
  },
  dispensedVitingTeam: {
    id: "dispensedVitingTeam",
    defaultMessage: "Dispensed to visiting team"
  },
  dispensedNonPersonnel: {
    id: "dispensedNonPersonnel",
    defaultMessage: "Dispensed to non-personnel"
  },
  lostByPlayer: {
    id: "lostByPlayer",
    defaultMessage: "Lost by player after dispensing"
  },
  lostByDepartment: {
    id: "lostByDepartment",
    defaultMessage: "Lost by department prior to dispensing"
  },
  ruinedDueToWaterHeat: {
    id: "ruinedDueToWaterHeat",
    defaultMessage: "Ruined due to water or heat"
  },
  disposed: {
    id: "disposed",
    defaultMessage: "Disposed"
  },
  returned: {
    id: "returned",
    defaultMessage: "Returned"
  },
  other: {
    id: "other",
    defaultMessage: "Other"
  },
  reason: {
    id: "reason",
    defaultMessage: "Reason"
  },
  remainderOpenB: {
    id: "remainderOpenB",
    defaultMessage: "Remainder of open bottle discarded"
  },
  erroneousOrder: {
    id: "erroneousOrder",
    defaultMessage: "Erroneous Order"
  },
  userCorrection: {
    id: "userCorrection",
    defaultMessage: "User Correction"
  }
});

/**
 * Component to avoid re-render of Transactions Array
 */
class Transactions extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.getFieldState(`${nextProps.name}.transactions`).modified
  }

  render() {
    const { theme, name, push, mutableValues, getFieldState } = this.props;

    return (
      <FormToManyField
        name={`${name}.transactions`}
        renderNewOnButton
        flexDirection="column"
      >
        {({ name: nameT, index }) => (
          <Container
            key={nameT}
            flexDirection="row"
            width="100%"
            // flexShrink={1}
            flexWrap="wrap"
          >
            <Fragment>
              <Field name={`${nameT}.compound`}>{() => null}</Field>

              <FormSpy subscription={{ modified: true, values: true, initialValues: true }}>
                {({ form: { getFieldState } }) => (
                  <Fragment>

                    {/* Compound CHECKMARK */}
                    {getFieldState(`${nameT}.compound`) && getFieldState(`${nameT}.compound`).value === true && (
                      <Container
                        margins="0 5px 16px 0"
                        width={70}
                      >
                        <FormCheckbox
                          leftLabel={messages.compound}
                          name={`${nameT}.compound`}
                          flexDirection="column"
                          justifyContent="space-between"
                          backgroundColor='white'
                          labelMargins="0 5px 10px 0"
                          disabled
                        />
                      </Container>
                    )}

                    {getFieldState(`${nameT}.compound`) && getFieldState(`${nameT}.compound`).value !== true && (
                      <Fragment>
                        {/* SCRIPT NUMBER */}
                        <Container
                          style={{ minWidth: 100 }}
                          flex={1}
                          margins="0 0 16px 0"
                        >
                          <FormTextField
                            name={`${nameT}.scriptNumber`}
                            label={messages.scriptNumber}
                            disabled
                          />
                        </Container>

                        {/* EXPIRATION DATE */}
                        <Container
                          style={{ minWidth: 120 }}
                          margins="0 0 16px 5px"
                          flex={1}
                        >
                          <FormSpy subscription={{ modified: true, values: true, initialValues: true }}>
                            {({ form: { getFieldState, getState } }) => {
                              const yellow = getFieldState(`${nameT}.expirationDate`)
                                && getFieldState(`${nameT}.expirationDate`).value
                                && new Date(
                                  getFieldState(`${nameT}.expirationDate`).value.split("-")[0],
                                  getFieldState(`${nameT}.expirationDate`).value.split("-")[1] - 1,
                                  getFieldState(`${nameT}.expirationDate`).value.split("-")[2]
                                ) < new Date()
                                ? 'rgb(249, 232, 0)'
                                : null;

                              return (
                                <ThemeProvider
                                  theme={{
                                    fieldFontColor: yellow ? theme.fieldBackgroundColor : theme.fieldFontColor,
                                    fieldBackgroundColor: yellow || theme.fieldBackgroundColor
                                  }}
                                >
                                  <FormDateField
                                    name={`${nameT}.expirationDate`}
                                    label={messages.expiration}
                                    disabled
                                  />
                                </ThemeProvider>
                              )
                            }}
                          </FormSpy>
                        </Container>
                      </Fragment>
                    )}

                  </Fragment>
                )}
              </FormSpy>

              {/* QUANTITY */}
              <Container
                style={{ minWidth: 90 }}
                flex={0.7}
                margins="0 0 16px 5px"
              >
                <FormNumberField
                  labelNumberofLines={1}
                  label={messages.physicalQty}
                  name={`${nameT}.quantity`}
                  disabled={!mutableValues}
                  onBlur={push}
                  backgroundColor={getFieldState(`${nameT}.quantity`) && getFieldState(`${nameT}.quantity`).dirty ? 'rgb(9, 34, 72)' : null}
                />
              </Container>

              {/* ON HAND QUANTITY */}
              <Container
                style={{ minWidth: 90 }}
                flex={0.7}
                margins="0 0 16px 5px"
              >
                <FormNumberField
                  labelNumberofLines={1}
                  label={messages.virtualQty}
                  name={`${nameT}.onHandQuantity`}
                  disabled
                />
              </Container>

              {/* Reason */}
              {!mutableValues
                && getFieldState(`${name}.transactions`)
                && getFieldState(`${name}.transactions`).value
                && getFieldState(`${name}.transactions`).value[index]
                && (
                  Number(getFieldState(`${name}.transactions`).value[index].quantity) !==
                    getFieldState(`${name}.transactions`).value[index].onHandQuantity
                  || getFieldState(`${name}.transactions`).value[index].reset
                )
                ? (
                  <Container
                    style={{ minWidth: 150 }}
                    flex={0.7}
                    margins="0 0 16px 5px"
                  >
                    <FormSelectValueField
                      label={messages.reason}
                      name={`${nameT}.reason`}
                      borderColor={!getFieldState(`${name}.transactions`).value[index].reason && "#d61e3a"}
                      options={
                        getFieldState(`${name}.transactions`).value[index].expirationDate
                          && new Date(
                            getFieldState(`${name}.transactions`).value[index].expirationDate.split("-")[0],
                            getFieldState(`${name}.transactions`).value[index].expirationDate.split("-")[1] - 1,
                            getFieldState(`${name}.transactions`).value[index].expirationDate.split("-")[2]
                          ) < new Date()
                          ? [
                            messages.disposed,
                            messages.returned,
                            messages.other
                          ]
                          : [
                            messages.dispensedWithoutRecording,
                            messages.dispensedVitingTeam,
                            messages.dispensedNonPersonnel,
                            messages.lostByPlayer,
                            messages.lostByDepartment,
                            messages.ruinedDueToWaterHeat,
                            messages.remainderOpenB,
                            messages.erroneousOrder,
                            messages.userCorrection,
                            messages.other
                          ]
                      }
                      isDisabled={getFieldState(`${name}.transactions`).value[index].reset}
                    />
                  </Container>
                )
                : null
              }

              {/* RECONCILE Icon & Button */}
              {!mutableValues && getFieldState(`${name}.transactions`).value ? (
                <Fragment>
                  <Icon
                    margins="0 0 0 10px"
                    iconSize={25}
                    color={getFieldState(`${name}.transactions`).value[index] &&
                      Number(getFieldState(`${name}.transactions`).value[index].quantity) !==
                        getFieldState(`${name}.transactions`).value[index].onHandQuantity
                        ? "#e74c3c"
                        : "#2ecc71"
                    }
                    name={getFieldState(`${name}.transactions`).value[index] &&
                      Number(getFieldState(`${name}.transactions`).value[index].quantity) !==
                        getFieldState(`${name}.transactions`).value[index].onHandQuantity
                        ? "times-circle"
                        : "check-circle"
                    }
                    weight="solid"
                  />
                  {getFieldState(`${name}.transactions`).value[index]
                    && Number(getFieldState(`${name}.transactions`).value[index].quantity) !==
                      getFieldState(`${name}.transactions`).value[index].onHandQuantity
                    && (
                      <Container
                        margins="0 0 0 10px"
                        flexDirection="row"
                        justifyContent="flex-end"
                      >
                        <ToastContext.Consumer>
                          {toast => (
                            <Mutation
                              mutation={reconcileCountProductMutation}
                              variables={{
                                transaction: {
                                  id: getFieldState(`${name}.transactions`).value[index].id,
                                  version: getFieldState(`${name}.transactions`).value[index].version,
                                  reason: getFieldState(`${name}.transactions`).value[index].reason
                                }
                              }}
                              refetchQueries={[
                                "findPhysicalCountsByName",
                                "findPhysicalCountById"
                              ]}
                              onCompleted={() => toast.success(messages.reconcileSuccess)}
                              onError={() => toast.error(messages.reconcileError)}
                            >
                              {handleMutation => (
                                <ConfirmButton
                                  title={messages.reconcile}
                                  message={messages.confirmReconcileMutation}
                                  // ui="swipe"
                                  margins="10px 0 16px"
                                  paddings="10px"
                                  alignSelf="flex-end"
                                  onConfirm={handleMutation}
                                  disabled={!getFieldState(`${name}.transactions`).value[index].reason}
                                />
                              )}
                            </Mutation>
                          )}
                        </ToastContext.Consumer>
                      </Container>
                    )
                  }
                </Fragment>
              ) : null}

            </Fragment>
          </Container>
        )}
      </FormToManyField>
    );
  };
};

export default withTheme(Transactions);
