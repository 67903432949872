/**
 * OutsideInventoryPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/outsideInventory/List";
import Inspect from "../view/outsideInventory/Inspect";

const messages = defineMessages({
  outsideInventoryPlaceholder: {
    id: "outsideInventoryPlaceholder",
    defaultMessage:
      "Select one from the left to view or edit external inventory.\n\n" +
      "Select + to add external inventory."
  }
});

const OutsideInventoryPage = () => (
  <PrimaryPage>
    <ListInspect
      rootPath="/outsideinventory"
      left={List}
      placeholderMessage={messages.outsideInventoryPlaceholder}
      selectionPath="/outsideinventory/:id"
      right={Inspect}
    />
  </PrimaryPage>
);

export default OutsideInventoryPage;
