import gql from "graphql-tag";
import { businessFieldsFragment } from "cci-data";

const doctorFieldsFragment = gql`
  fragment DoctorFields on Doctor {
    id
    version
    status
    contact {
      id
      version
      firstName
      lastName
      companyName
      birthDate
      photoId
    }
    business {
      ...BusinessFields
    }
  }
  ${businessFieldsFragment}
`;

const findDoctorByIdQuery = gql`
  query findDoctorById($id: UUID) {
    findDoctorById(id: $id) {
      ...DoctorFields
    }
  }
  ${doctorFieldsFragment}
`;

const findDoctorsByNameQuery = gql`
  query findDoctorsByName(
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findDoctorsByName(
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
        sort: {
          orders: [
            { direction: "ASC", property: "contact.lastName" }
            { direction: "ASC", property: "contact.firstName" }
          ]
        }
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...DoctorFields
      }
    }
  }
  ${doctorFieldsFragment}
`;

const saveDoctorMutation = gql`
  mutation($doctor: DoctorInput) {
    saveDoctor(doctor: $doctor) {
      ...DoctorFields
    }
  }
  ${doctorFieldsFragment}
`;

const deleteDoctorMutation = gql`
  mutation($doctor: DoctorInput) {
    deleteDoctor(doctor: $doctor) {
      id
      version
    }
  }
`;

export {
  findDoctorByIdQuery,
  findDoctorsByNameQuery,
  saveDoctorMutation,
  deleteDoctorMutation
};
