import { enUS as en, es } from "date-fns/esm/locale";

/**
 * Loads the date locales for the web since these must be included a compile time.
 *
 * Messages are loaded async.
 */
export default () => ({
  date: {
    en,
    es
  }
});
