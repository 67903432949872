import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { ThemeProvider, withTheme } from "styled-components/native";
import { format } from "date-fns";
import {
  array as yupArray,
  date as yupDate,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import { graphql, withApollo } from "react-apollo";
import { Field, FormSpy } from "react-final-form";
import { InspectSubtitle, InspectTitle, MutableInspect } from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  FormCell,
  FormCheckbox,
  FormDateField,
  FormImageField,
  FormNumberField,
  FormSelectRecordField,
  FormSelectValueField,
  FormTextAreaField,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  RowText,
  ToastContext
} from "cci-component";
import { findBusinessByIdQuery, getSessionInformation, SessionQuery } from "cci-data";
import { hasPermissions, SecuredComponent } from "cci-logic";
import { localize, withRouter } from "cci-utility";
import {
  deleteCustomerOrderMutation,
  findCustomerOrdersByKeywordQuery,
  findCustomerOrderByIdQuery,
  saveCustomerOrderMutation,
  emailOrderSubmittedMutation
} from "../../query/CustomerOrder";
import { findTransactionsByScriptNumberQuery } from "../../query/InventoryUse";
import { findUnitsByProductQuery } from "../../query/ProductUnit";
import teamLogo from "../../assets/images/order.png";
import PersonnelCrudField from "../PersonnelCrudField";
import DoctorCrudField from "../DoctorCrudField";
import TeamCrudField from "../TeamCrudField";
import ProductField from "./ProductField";

const messages = defineMessages({
  orderTitle: {
    id: "orderTitle",
    defaultMessage: "Order #{number}"
  },
  newOrderTitle: {
    id: "newOrderTitle",
    defaultMessage: "New Order"
  },
  patient: {
    id: "patient",
    defaultMessage: "Personnel"
  },
  team: {
    id: "team",
    defaultMessage: "Team"
  },
  date: {
    id: "date",
    defaultMessage: "Date"
  },
  products: {
    id: "products",
    defaultMessage: "Products"
  },
  strengthSize: {
    id: "strengthSize",
    defaultMessage: "Strength / Size"
  },
  packaging: {
    id: "packaging",
    defaultMessage: "Packaging"
  },
  qtyPackage: {
    id: "qtyPackage",
    defaultMessage: "Qty / Pkg"
  },
  numberOfPkgs: {
    id: "numberOfPkgs",
    defaultMessage: "Number of Pkgs"
  },
  directions: {
    id: "directions",
    defaultMessage: "Directions"
  },
  fulfillShip: {
    id: "fulfillShip",
    defaultMessage: "Fulfill & Ship"
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit"
  },
  status: {
    id: "status",
    defaultMessage: "Status"
  },
  expiration: {
    id: "expiration",
    defaultMessage: "Expiration"
  },
  scriptNumber: {
    id: "scriptNumber",
    defaultMessage: "Rx Number"
  },
  compound: {
    id: "compound",
    defaultMessage: "Compound"
  },
  noPatientInfo: {
    id: "noPatientInfo",
    defaultMessage: "No Personnel Information"
  },
  saveForLater: {
    id: "saveForLater",
    defaultMessage: "Save for Later"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company Name"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  type: {
    id: "type",
    defaultMessage: "Status"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  doctor: {
    id: "doctor",
    defaultMessage: "Doctor"
  },
  trackingNumber: {
    id: "trackingNumber",
    defaultMessage: "Tracking Number"
  },
  deliveryAddressShippingInstructions: {
    id: "deliveryAddressShippingInstructions",
    defaultMessage: "Delivery Address / Shipping Instructions"
  },
  fedexClickHere: {
    id: "fedexClickHere",
    defaultMessage: "Click to track your delivery on fedex.com: {value}"
  },
  payType: {
    id: "payType",
    defaultMessage: "Pay Type"
  },
  alreadyExists: {
    id: "alreadyExists",
    defaultMessage: "This RX Number {value} already exists."
  },
  selectCorrectTeam: {
    id: "selectCorrectTeam",
    defaultMessage: "Do not forget to select the correct team in the header bar."
  },
  cannotDeleteError: {
    id: "cannotDeleteError",
    defaultMessage:
      "This record cannot be deleted, this is most likely because the record is in use."
  },
  deletedSuccessfully: {
    id: "deletedSuccesfully",
    defaultMessage: "Information deleted successfully!"
  },
});

const statusOptions = [
  { defaultMessage: "Saved", id: "saved" },
  { defaultMessage: "Submitted", id: "submitted" },
  { defaultMessage: "Fulfilled", id: "delivered" }
],
  statusFarmaOptions = [
    { defaultMessage: "Saved", id: "saved" },
    { defaultMessage: "Pending", id: "submitted" },
    { defaultMessage: "Fulfilled", id: "delivered" }
  ],
  packagingOptions = [
    // { defaultMessage: "Vial/Bottle", id: "vialBottle" },
    { defaultMessage: "Standard", id: "vialBottle" },
    { defaultMessage: "Blister Pack", id: "blisterPack" }
  ],
  payTypeOptions = [
    { defaultMessage: "Worker's Compensation", id: "workers_compensation" },
    { defaultMessage: "Club Pay", id: "club_pay" },
    { defaultMessage: "Personnel Pay", id: "patient_pay" },
    { defaultMessage: "Other", id: "other" },
  ];

const RXNumberField = ({ client, theme, businessData, nameT, indexT, valueT, pushT, disabled, getState, getFieldState, foundRXs, setFoundRXs }) => {
  const [errorRX, setErrorRX] = useState(false)

  return (
    <ToastContext.Consumer>
      {toast => (
        <ThemeProvider
          theme={{
            fieldBorderColor: errorRX ? theme.fieldErrorColor : theme.fieldBorderColor,
            fieldBorderTopWidth: errorRX ? 2 : theme.fieldBorderTopWidth,
            fieldBorderRightWidth: errorRX ? 2 : theme.fieldBorderRightWidth,
            fieldBorderBottomWidth: errorRX ? 2 : theme.fieldBorderBottomWidth,
            fieldBorderLeftWidth: errorRX ? 2 : theme.fieldBorderLeftWidth,
            fieldStyle: "border-style: solid; height: 35px"
          }}
        >
          <FormTextField
            label={messages.scriptNumber}
            labelNumberofLines={1}
            name={`${nameT}.scriptNumber`}
            onBlur={({ nativeEvent: { text } }) => {
              let tmp = foundRXs;

              client.query({
                query: findTransactionsByScriptNumberQuery,
                variables: {
                  business: { id: businessData.id },
                  scriptNumber: text,
                  notId: valueT && valueT[indexT] && valueT[indexT].id ? valueT[indexT].id : "00000000-0000-0000-0000-000000000000"
                }
              })
                .then(({ data }) => {

                  if (text && data && data.findByScriptNumber && data.findByScriptNumber.length > 0) {
                    toast.error(messages.alreadyExists, { value: text });

                    setErrorRX(true);

                    if (!tmp.find(foundRX => foundRX.rx === text)) {
                      tmp.push({ rx: text, ids: data.findByScriptNumber.map(tr => tr.id) });
                      setFoundRXs(tmp);
                    }
                  } else {
                    setErrorRX(false);
                    pushT && pushT()
                  }
                })
                .catch(res => console.log('err', res))
            }}
            disabled={disabled && !getState().hasSubmitErrors}
          />
        </ThemeProvider>
      )}
    </ToastContext.Consumer>
  )
};

/**
 * Inspect component for the Order entity, allows the user to create,
 * edit or delete records if they have permission to do so.
 * If props.orderPage === "admin" then this is a Farma Order Inspect
 */
const Inspect = ({ data, client, orderPage, theme, intl, history, match, ...restProps }) => {
  const [foundRXs, setFoundRXs] = useState([]),
    [dataQry, setData] = useState(null),
    [businessData, setBusinessData] = useState(null),
    adminAllowed = data.sessionInformation &&
      hasPermissions(data.sessionInformation.user.permissions, {
        objects: "Administrator",
        actions: ["read"]
      }),
    BottomComponent = ({
      form,
      handleSubmit,
      loadingSave,
      setSaving
    }) =>
      !form.getState().submitFailed && loadingSave ? null : (
        <Button
          margins="0 15px"
          title={orderPage === "admin" ? messages.fulfillShip : messages.submit}
          disabled={
            form.getState().hasSubmitErrors
              ? false
              : orderPage === "admin"
                ? form.getState().values.status !== "submitted"
                : form.getState().values.status !== "saved"
          }
          onPress={() => {
            setSaving();
            form.mutators.setStatus();
            handleSubmit();
          }}
        />
      );
  let disabled;

  if (orderPage === "admin") {
    disabled = true;
  } else if (orderPage === "sent") {
    disabled = true;
  } else if (orderPage === "saved") {
    disabled = false;
  }

  if (businessData === null) {
    client.query({
      query: findBusinessByIdQuery,
      variables: {
        id: data.sessionInformation.currentBusiness.id
      }
    })
      .then(({ data: business, loading }) => {
        if (!loading) {
          setBusinessData(business.findBusinessById);
        }
      });
  }

  if (!businessData) {
    return null;
  }

  return (
    <SessionQuery>
      {({ wrapBusiness, wrapOffice }) => (
        <MutableInspect
          entityName="CustomerOrder"
          query={findCustomerOrderByIdQuery}
          queryName="findCustomerOrderById"
          mutation={saveCustomerOrderMutation}
          mutationName="saveCustomerOrder"
          checkDeleted
          objectWrapper="customerOrder"
          onQueryCompleted={data => {
            setData(data.findCustomerOrderById);
            data.findCustomerOrderById && setBusinessData(data.findCustomerOrderById.business);
          }}
          refetchQueries={[
            {
              query: findCustomerOrdersByKeywordQuery,
              variables: {
                ...wrapBusiness({
                  name: "",
                  startIndex: 0,
                  stopIndex: 40,
                  status: "saved"
                })
              }
            },
            {
              query: findCustomerOrdersByKeywordQuery,
              variables: {
                ...wrapBusiness({
                  name: "",
                  startIndex: 0,
                  stopIndex: 40,
                  status: "i"
                })
              }
            }
          ]}
          defaultValues={{
            number: 0,
            deliveryDate: format(Date.now(), "yyyy-MM-dd"),
            status: "saved",
            business: {
              id: businessData.id,
              version: businessData.version
            }
          }}
          beforeMutate={({ options }) => {
            // Avoiding the need of implement null deserializer of LocalDate on server
            options.variables.customerOrder.items.forEach(itm => {
              itm.transactions && itm.transactions.forEach(tr => {
                if (tr.expirationDate === "") {
                  delete tr.expirationDate
                }
              })
            })
          }}
          validationSchema={yupObject().shape({
            deliveryDate: yupDate().required(),
            status: orderPage === "admin" && yupString().required(),
            items: yupArray().when("status", (status, schema) => {
              if (status === "delivered") {
                return schema.of(
                  yupObject().shape({
                    productUnit: yupObject().nullable().required(),
                    packaging: yupString().required(),
                    quantity: yupNumber().required().min(1, "minimum1"),
                    quantityPackage:
                      yupNumber().when("packaging", (packaging, schema) =>
                        packaging !== "vial"
                          ? schema.required().min(1, "minimum1")
                          : schema
                      ),
                    transactions: yupArray().of(
                      yupObject().shape({
                        expirationDate:
                          orderPage === "admin"
                          && yupString()
                            .nullable()
                            .matches(/$|^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "invalidDate")
                            .test(
                              "compound", // function name
                              "notCompoundRequiredMSG", // intl message
                              function (value) {
                                const thisItem = this.from.find(a => a.value.__typename === "CustomerOrderItem");
                                return !!value || !!thisItem.value.compound;
                              }
                            ),
                        scriptNumber:
                          orderPage === "admin"
                          && yupString()
                            .nullable()
                            .test(
                              "notRepeatedRX", // function name
                              "notRepeatedRX", // intl message
                              function (value) {
                                const found = foundRXs.find(foundRX => value === foundRX.rx && !foundRX.ids.find(id => id === this.parent.id));
                                return !found;
                              }
                            )
                            .test(
                              "compound", // function name
                              "notCompoundRequiredMSG", // intl message
                              function (value) {
                                const thisItem = this.from.find(a => a.value.__typename === "CustomerOrderItem");
                                return !!value || !!thisItem.value.compound;
                              }
                            )
                      })
                    )
                  })
                )
              }
              return schema
            })
              .of(
                yupObject().shape({
                  productUnit: yupObject().nullable().required(),
                  packaging: yupString().required(),
                  quantity: yupNumber().required().min(1, "minimum1"),
                  quantityPackage:
                    yupNumber().when("packaging", (packaging, schema) =>
                      packaging !== "vial"
                        ? schema.required().min(1, "minimum1")
                        : schema
                    ),
                  transactions: yupArray().of(
                    yupObject().shape({
                      expirationDate:
                        orderPage === "admin"
                        && yupString()
                          .nullable()
                          .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "invalidDate"),
                      scriptNumber:
                        orderPage === "admin"
                        && yupString()
                          .nullable()
                          .test(
                            "notRepeatedRX", // function name
                            "notRepeatedRX", // intl message
                            function (value) {
                              const found = foundRXs.find(foundRX => value === foundRX.rx && !foundRX.ids.find(id => id === this.parent.id));

                              return !found;
                            }
                          )
                    })
                  )
                })
              )
              .required()
          })}
          mutators={{
            setStatus: (args, state, utils) => {
              utils.changeValue(state, "status", () =>
                orderPage === "admin" ? "delivered" : "submitted"
              );
              utils.changeValue(state, "deliveryDate", () =>
                format(Date.now(), "yyyy-MM-dd")
              );
            }
          }}
          onValidationError={form => {
            orderPage === "admin" && form.getFieldState("status").value === "delivered" && form.change('status', 'submitted');
          }}
          onCompleted={savedData => {
            if (savedData.status === "submitted") {
              client.mutate({
                mutation: emailOrderSubmittedMutation,
                variables: {
                  order: { id: savedData.id, version: savedData.version }
                },
                refetchQueries: [
                  {
                    query: findCustomerOrderByIdQuery,
                    variables: {
                      id: savedData.id
                    }
                  }
                ]
              })
                .then(() => null)
                .catch(err => console.log("err", err));
            }
          }}
          disableSaveChanges={form =>
            form.getState().hasSubmitErrors
              ? false
              : orderPage === "admin"
                ? false
                : form.getState().values.status !== "saved"
          }
          footerAddedComponent={BottomComponent}
          deleteMutation={deleteCustomerOrderMutation}
          saveMessage={messages.saveForLater}
          onDeleteCheck={(data, toast) => {
            if (data.deleteCustomerOrder) {
              toast.error(messages.cannotDeleteError)
            } else {
              // Record was deleted successfully
              toast.success(messages.deletedSuccessfully);

              // Redirect the user to the root path since the record no longer exists and they should not be allowed to make changes
              history.replace(restProps.rootPath);
            }
          }}
          {...restProps}
        >
          {() => (
            <FormSpy subscription={{ initialValues: true, modified: true, values: true }}>
              {({ form: { getFieldState, getState } }) => {
                if (orderPage === "admin") {
                  disabled = getState().values.status === "delivered";
                }
                if (orderPage === "saved") {
                  disabled = getState().values.status !== "saved";
                }
                return (
                  <Container width='100%'>
                    <Field name="number">
                      {({ input }) => (
                        <InspectTitle
                          title={
                            input.value === 0
                              ? messages.newOrderTitle
                              : messages.orderTitle
                          }
                          values={{ number: input.value }}
                        />
                      )}
                    </Field>

                    <Section style={{ zIndex: 150 }}>
                      <FormCell xlSize="1/3" style={{ zIndex: 150 }}>
                        {/* DOCTOR */}
                        <ToastContext.Consumer>
                          {toast => (
                            <DoctorCrudField
                              name="doctor"
                              listMargins="20px 10px"
                              label={messages.doctor}
                              defaultValues={{
                                business: {
                                  id: businessData.id,
                                  version: businessData.version
                                },
                                contact: {
                                  type: "person"
                                }
                              }}
                              variablesByName={{
                                business: { id: businessData.id }
                              }}
                              disabled={disabled}
                            />
                          )}
                        </ToastContext.Consumer>
                      </FormCell>

                      {(orderPage === "admin" || orderPage === "sent") && (
                        <Fragment>
                          <FormCell xlSize="1/3" style={{ zIndex: 140 }}>
                            {/* TRACKING NUMBER */}
                            <FormTextField
                              name="trackingNumber"
                              label={messages.trackingNumber}
                              maxLength={60}
                              disabled={orderPage !== "admin"}
                            />
                          </FormCell>

                          <FormCell xlSize={200} style={{ zIndex: 130 }}>
                            <Field name="trackingNumber">
                              {({ input }) => (
                                <Button
                                  margins="20px 0 0"
                                  ui="link"
                                  fontSize={14}
                                  letterSpacing="0"
                                  title={messages.fedexClickHere}
                                  intlValues={{ value: input.value }}
                                  onPress={() => {
                                    window.open(
                                      `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${input.value}&cntry_code=us`,
                                      "_blank"
                                    );
                                  }}
                                />
                              )}
                            </Field>
                          </FormCell>
                        </Fragment>
                      )}
                    </Section>

                    {/* Delivery Address / Shipping Instructions */}
                    <Section style={{ zIndex: 110 }}>
                      <FormCell stretch>
                        <FormTextAreaField
                          label={messages.deliveryAddressShippingInstructions}
                          name="deliveryAddress"
                          autoComplete="off"
                          clearButtonMode="unless-editing"
                          disabled={disabled}
                        />
                      </FormCell>
                    </Section>

                    {/* TEAM */}
                    <Section style={{ zIndex: 105 }}>
                      <SecuredComponent
                        checkPermissions={{
                          objects: "Administrator",
                          actions: ["read"]
                        }}
                      >
                        {match.params.id !== "new" ? (
                          <FormCell
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row"
                            }}
                            xlStyle={{
                              alignItems: "center",
                              justifyContent: "flex-start",
                              flexDirection: "row"
                            }}
                            size="stretch"
                          >
                            <FormImageField
                              size={70}
                              templateIndex={0}
                              name={"business.contact.photoId"}
                              placeholder={teamLogo}
                            />

                            <Field name="business">
                              {({ input }) => (
                                <Container margins="0 0 0 10px">
                                  <RowText>
                                    {input.value &&
                                      input.value.contact &&
                                      input.value.contact.companyName}
                                  </RowText>
                                  <RowText>
                                    {input.value &&
                                      input.value.contact &&
                                      input.value.contact.emails &&
                                      input.value.contact.emails[0] &&
                                      input.value.contact.emails[0].address}
                                  </RowText>
                                </Container>
                              )}
                            </Field>
                          </FormCell>
                        ) : (
                          <FormCell xlSize="1/2" size="1">
                            <TeamCrudField
                              name="business"
                              label={messages.team}
                              onSelect={selectedTeam => {
                                setBusinessData(selectedTeam);
                              }}
                              hideTeamPhoto={false}
                              topList={65}
                            />
                          </FormCell>
                        )}
                      </SecuredComponent>

                      {orderPage === "admin" && (
                        <Fragment>
                          {/* DATE */}
                          <FormCell xlSize="1/4">
                            <FormDateField
                              label={messages.date}
                              name="deliveryDate"
                              disabled
                            />
                          </FormCell>

                          {/* STATUS */}
                          <FormCell xlSize="1/4" xxlSize="1/3">
                            <FormSelectValueField
                              name="status"
                              isDisabled
                              label={messages.status}
                              options={
                                orderPage === "admin"
                                  ? statusFarmaOptions
                                  : statusOptions
                              }
                            />
                          </FormCell>
                        </Fragment>
                      )}
                    </Section>

                    <InspectSubtitle title={messages.products} />

                    <FormToManyField
                      name="items"
                      addValidation={yupObject().shape({
                        productUnit: yupObject().nullable().required(),
                        packaging: orderPage !== "admin" && yupString().required(),
                        quantity: orderPage !== "admin" && yupNumber().required().min(1, "minimum1"),
                        quantityPackage: orderPage !== "admin" &&
                          yupNumber().when("packaging", (packaging, schema) =>
                            packaging !== "vial"
                              ? schema.required().min(1, "minimum1")
                              : schema
                          ),
                        patient: yupObject().nullable()
                        // expirationDate: orderPage === "admin" && yupDate().required(),
                        // scriptNumber: orderPage === "admin" && yupNumber().required(),
                      })}
                      width="100%"
                      renderNewOnButton
                    >
                      {({
                        name,
                        isNew,
                        push,
                        remove,
                        index,
                        plus,
                        length
                      }) => (
                        <Container
                          key={name}
                          flexDirection="row"
                          paddings="0 10px"
                          flexShrink={1}
                          width="100%"
                          style={{
                            zIndex: index !== undefined ? 100 - index * 10 : 0
                          }}
                        >
                          {!disabled && getState().values.status !== "delivered" ? (
                            <DeletableItem
                              allowDelete={!isNew}
                              height={60}
                              margins="10px 0 0"
                              iconName="times"
                              confirmMessage={messages.confirmDeleteItem}
                              onConfirm={remove}
                              checkPermissions={{
                                objects: "CustomerOrderItem",
                                actions: ["delete"]
                              }}
                            >
                              <RoundedIcon
                                iconSize={50}
                                color={theme.fieldFontColor}
                                name={isNew ? "plus" : "capsules"}
                              />
                            </DeletableItem>
                          ) : (
                            <Container margins="10px 0 0">
                              <RoundedIcon
                                iconSize={50}
                                color={theme.fieldFontColor}
                                name={isNew ? "plus" : "capsules"}
                              />
                            </Container>
                          )}

                          <Field name={`${name}.oldQuantity`}>{() => null}</Field>
                          <Field name={`${name}.oldQuantityPackage`}>{() => null}</Field>

                          <Section style={{ flex: 1 }}>
                            <Container
                              margins="10px 0 0 10px"
                              flex={1.5}
                              style={{ minWidth: 200 }}
                            >
                              {/* PRODUCT NAME */}
                              <ProductField {...{ name, push, businessData, disabled, getFieldState }} />
                              {orderPage === "admin" && getState().values.status === "delivered" && (
                                <Field name={`${name}.patient`}>
                                  {({ input }) => (
                                    <RowText margins="10px 0">
                                      {input.value && input.value.contact
                                        ? input.value.contact.firstName
                                        + " "
                                        + input.value.contact.lastName
                                        : localize(intl, messages.noPatientInfo)}
                                    </RowText>
                                  )}
                                </Field>
                              )}
                            </Container>

                            <Fragment>
                              {getFieldState(`${name}.productUnit.product`) ? (
                                <Container
                                  flex={1}
                                  margins="10px 0 0 10px"
                                  style={{ minWidth: 120 }}
                                >
                                  {/* PRODUCTUNIT */}
                                  <FormSelectRecordField
                                    query={findUnitsByProductQuery}
                                    skip={
                                      !getFieldState(`${name}.productUnit.product`).value
                                      || (getFieldState(`${name}.productUnit.product`).value
                                        && getFieldState(`${name}.productUnit.product`).value.id === "new")
                                    }
                                    variables={{
                                      business: { id: businessData.id },
                                      product: {
                                        id:
                                          getFieldState(`${name}.productUnit.product`).value
                                          && getFieldState(`${name}.productUnit.product`).value.id
                                      }
                                    }}
                                    name={`${name}.productUnit`}
                                    label={messages.strengthSize}
                                    onChangeText={push}
                                    labelKey="unit.name"
                                    isDisabled={disabled}
                                  />

                                  {/* QUANTITY TEXT */}
                                  {orderPage === "admin" && disabled && (
                                    <Field name={`${name}.quantity`}>
                                      {({ input }) => (
                                        <RowText margins="10px 0">
                                          {localize(intl, messages.numberOfPkgs)
                                            + ": "
                                            + input.value
                                          }
                                        </RowText>
                                      )}
                                    </Field>
                                  )}
                                </Container>
                              ) : null}

                              {/* PACKAGING */}
                              <Container
                                flex={0.5}
                                margins="10px 0 0 10px"
                                style={{ minWidth: 100 }}
                              >
                                <FormSelectValueField
                                  name={`${name}.packaging`}
                                  label={messages.packaging}
                                  options={packagingOptions}
                                  onBlur={push}
                                  isDisabled={disabled}
                                />
                              </Container>

                              {/* QUANTITY PACKAGE */}
                              <Container
                                flex={0.5}
                                margins="10px 0 0 10px"
                                style={{ minWidth: 70 }}
                              >
                                <FormNumberField
                                  name={`${name}.quantityPackage`}
                                  label={messages.qtyPackage}
                                  onBlur={push}
                                  disabled={disabled}
                                  onChangeText={nmbr => {
                                    let transactions = getFieldState(`${name}.transactions`);

                                    if (transactions && transactions.value && transactions.value.length > 0 && Number(nmbr) > 0) {
                                      let tmp = new Array(transactions.value.length);
                                      tmp = transactions.value.map(t => ({ ...t, quantity: Number(nmbr) }));
                                      transactions.change(tmp);
                                    }

                                    if (getFieldState(`${name}.oldQuantity`).value === null && dataQry) {
                                      getFieldState(`${name}.oldQuantity`).change(dataQry.items[index].quantity);
                                    }

                                    if (getFieldState(`${name}.oldQuantityPackage`).value === null && dataQry) {
                                      getFieldState(`${name}.oldQuantityPackage`).change(dataQry.items[index].quantityPackage);
                                    }
                                  }}
                                />
                              </Container>

                              {/* QUANTITY */}
                              {(orderPage !== "admin" || getState().values.status !== "delivered") && (
                                <Container
                                  flex={0.7}
                                  margins="10px 0 0 10px"
                                  style={{ minWidth: 110 }}
                                >
                                  <FormNumberField
                                    label={messages.numberOfPkgs}
                                    name={`${name}.quantity`}
                                    onBlur={push}
                                    disabled={disabled}
                                    onChangeText={(nmbr) => {
                                      if (Number(nmbr) > 0 && getState().values.status === "submitted") {
                                        let transactions = getFieldState(`${name}.transactions`);
                                        const newObj = {
                                          expirationDate: "",
                                          scriptNumber: "",
                                          quantity: getFieldState(`${name}.quantityPackage`).value,
                                          business: {
                                            id: businessData.id,
                                            version: businessData.version
                                          }
                                        };

                                        let tmp = [];
                                        for (let i = 0; i < Number(nmbr); i++) {
                                          tmp.push(transactions.value && transactions.value[i] ? transactions.value[i] : newObj);
                                        }
                                        transactions.change(tmp);

                                        if (getFieldState(`${name}.oldQuantity`).value === null && dataQry) {
                                          getFieldState(`${name}.oldQuantity`).change(dataQry.items[index].quantity);
                                        }

                                        if (getFieldState(`${name}.oldQuantityPackage`).value === null && dataQry) {
                                          getFieldState(`${name}.oldQuantityPackage`).change(dataQry.items[index].quantityPackage);
                                        }
                                      }
                                    }}
                                  />
                                </Container>
                              )}

                              {/* PERSONNEL */}
                              {(orderPage !== "admin" || getState().values.status !== "delivered") && (
                                <Container
                                  flex={2}
                                  margins="10px 0 0 10px"
                                  style={{
                                    minWidth: 200,
                                    maxWidth: 400,
                                    zIndex:
                                      index !== undefined
                                        ? 100 - index * 10 - 1
                                        : 0
                                  }}
                                >
                                  <PersonnelCrudField
                                    name={`${name}.patient`}
                                    listMargins="10px 0"
                                    onSelect={push}
                                    defaultValues={{
                                      contact: {
                                        office: wrapOffice().office,
                                        type: "person"
                                      }
                                    }}
                                    variablesByName={{
                                      business: { id: businessData.id }
                                    }}
                                    disabled={disabled}
                                    onPressEdit={adminAllowed
                                      ? ({ form }, dataI) => {
                                        if (form.getState() && form.getState().values && form.getState().values.id) {
                                          history.push(`/personnelteam/${form.getState().values.id}`);
                                        } else {
                                          history.push(`/personnelteam/${dataI.id}`);
                                        }
                                      }
                                      : null
                                    }
                                    hidePlus={adminAllowed}
                                  />
                                </Container>
                              )}

                              {getFieldState(`${name}.patient`) &&
                                getFieldState(`${name}.patient`).value && (
                                  <Fragment>
                                    {/* PAYTYPE */}
                                    <Container
                                      flex={1}
                                      margins="10px 0 0 10px"
                                      style={{
                                        minWidth: 100,
                                        zIndex:
                                          index !== undefined
                                            ? 100 - index * 10 - 2
                                            : -1
                                      }}
                                    >
                                      <FormSelectValueField
                                        name={`${name}.payType`}
                                        label={messages.payType}
                                        options={payTypeOptions}
                                        isDisabled={disabled}
                                      />
                                    </Container>

                                    {/* DIRECTIONS */}
                                    <Container
                                      flex={2}
                                      margins="10px 0 0 10px"
                                      style={{
                                        minWidth: 300,
                                        zIndex:
                                          index !== undefined
                                            ? 100 - index * 10 - 2
                                            : -1
                                      }}
                                    >
                                      <FormTextField
                                        label={messages.directions}
                                        name={`${name}.directions`}
                                        autoComplete="off"
                                        clearButtonMode="unless-editing"
                                        disabled={disabled}
                                      />
                                    </Container>
                                  </Fragment>
                                )}
                            </Fragment>

                            {/* Compound CHECKMARK */}
                            {orderPage === "admin" && (
                              <Container
                                margins="10px 0 0 10px"
                                width={70}
                              >
                                <FormCheckbox
                                  leftLabel={messages.compound}
                                  name={`${name}.compound`}
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  backgroundColor='white'
                                  labelMargins="0 5px 10px 0"
                                />
                              </Container>
                            )}

                            {/* TRANSACTIONS */}
                            {orderPage === "admin" && getFieldState(`${name}.compound`) && getFieldState(`${name}.compound`).value !== true &&
                            (
                              <Container
                                flex={2}
                                margins="10px 0 0"
                                style={{ minWidth: 220 }}
                              >
                                <FormToManyField
                                  name={`${name}.transactions`}
                                  addValidation={yupObject().shape({
                                    expirationDate: yupDate().required(),
                                    scriptNumber: yupString().required()
                                  })}
                                  renderNewOnButton
                                >
                                  {({
                                    name: nameT,
                                    push: pushT,
                                    index: indexT,
                                    value: valueT,
                                  }) => (
                                    <Container
                                      key={nameT}
                                      width="100%"
                                      flexDirection="row"
                                      margins={
                                        indexT !== 0 &&
                                        indexT !== undefined &&
                                        "10px 0 0"
                                      }
                                    >
                                      {/* EXPIRATION DATE */}
                                      <Container
                                        style={{ minWidth: 120 }}
                                        margins="0 0 0 10px"
                                        flex={1}
                                      >
                                        <FormDateField
                                          label={messages.expiration}
                                          name={`${nameT}.expirationDate`}
                                          onBlur={pushT}
                                          disabled={disabled && !getState().hasSubmitErrors}
                                        />
                                      </Container>
                                      
                                      {/* RX Number */}
                                      <Container
                                        style={{ minWidth: 80 }}
                                        margins="0 0 0 10px"
                                        flex={1}
                                      >
                                        <RXNumberField
                                          {...{
                                            client,
                                            theme,
                                            businessData,
                                            nameT,
                                            pushT,
                                            indexT,
                                            valueT,
                                            disabled,
                                            getState,
                                            getFieldState,
                                            foundRXs,
                                            setFoundRXs
                                          }}
                                        />
                                      </Container>

                                    </Container>
                                  )}
                                </FormToManyField>
                              </Container>
                            )}

                            {/* New Item Button */}
                            <SecuredComponent
                              checkPermissions={{
                                objects: "CustomerOrderItem",
                                actions: ["create"]
                              }}
                            >
                              {!isNew &&
                                index + 1 === length &&
                                !disabled && getState().values.status !== "delivered" && (
                                  <Button
                                    leftIcon="plus"
                                    margins="40px 0 0 10px"
                                    ui="dangerLink"
                                    leftIconWeight={900}
                                    onPress={plus}
                                  />
                                )}
                            </SecuredComponent>

                          </Section>
                        </Container>
                      )}
                    </FormToManyField>

                  </Container>
                )
              }}
            </FormSpy>
          )}
        </MutableInspect>
      )}
    </SessionQuery>
  );
};

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

export default graphql(getSessionInformation)(withApollo(withRouter(injectIntl(withTheme(Inspect)))));
