/**
 * PhysicalCountsPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/physicalCounts/List";
import Inspect from "../view/physicalCounts/Inspect";

const messages = defineMessages({
  physicalCountsPlaceholder: {
    id: "physicalCountsPlaceholder",
    defaultMessage:
      "Select a physical count from the left to see the results and print the certificate or start a new physical count."
  }
});

const PhysicalCountsPage = () => (
  <PrimaryPage>
    <ListInspect
      rootPath="/physicalcounts"
      left={List}
      placeholderMessage={messages.physicalCountsPlaceholder}
      selectionPath="/physicalcounts/:id"
      right={Inspect}
    />
  </PrimaryPage>
);

export default PhysicalCountsPage;
