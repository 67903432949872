import gql from "graphql-tag";

const customerOrderFieldsFragment = gql`
  fragment CustomerOrderFields on CustomerOrder {
    id
    version
    status
    number
    referenceId
    submittedStamp
    deliveryDate
    total
    trackingNumber
    deliveryAddress
    doctor {
      id
      version
      status
      contact {
        id
        version
        firstName
        lastName
      }
    }
    items {
      id
      version
      quantity
      packaging
      quantityPackage
      oldQuantity
      oldQuantityPackage
      payType
      directions
      compound
      productUnit {
        id
        version
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
      transactions {
        id
        version
        expirationDate
        scriptNumber
      }
      patient {
        id
        version
        status
        contact {
          id
          version
          companyName
          firstName
          lastName
          photoId
          emails {
            id
            version
            address
            status
            tags
          }
          addresses {
            id
            version
            city
            line1
            state
            tags
            zipCode
          }
          phones {
            id
            version
            phoneNumber
            tags
          }
        }
      }
    }
    business {
      id
      version
      status
      contact {
        id
        version
        status
        firstName
        lastName
        companyName
        photoId
        emails {
          id
          version
          address
        }
      }
    }
  }
`;

const findCustomerOrderByIdQuery = gql`
  query findCustomerOrderById($id: UUID) {
    findCustomerOrderById(id: $id) {
      ...CustomerOrderFields
    }
  }
  ${customerOrderFieldsFragment}
`;

const saveCustomerOrderMutation = gql`
  mutation saveCustomerOrder($customerOrder: CustomerOrderInput) {
    saveCustomerOrder(customerOrder: $customerOrder) {
      ...CustomerOrderFields
    }
  }
  ${customerOrderFieldsFragment}
`;

const deleteCustomerOrderMutation = gql`
  mutation deleteCustomerOrder($customerOrder: CustomerOrderInput) {
    deleteCustomerOrder(customerOrder: $customerOrder) {
      id
      version
    }
  }
`;

const findCustomerOrdersByKeywordQuery = gql`
  query findCustomerOrdersByKeyword(
    $business: BusinessInput
    $name: String
    $status: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findCustomerOrdersByKeyword(
      business: $business
      name: $name
      status: $status
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...CustomerOrderFields
      }
    }
  }
  ${customerOrderFieldsFragment}
`;

const saveAddressesMutation = gql`
  mutation saveAddresses ($submittedAddresses: SubmittedOrderAddressesInput) {
    saveAddresses (submittedAddresses: $submittedAddresses) {
      submitted {
        address
      }
    }
  }
`;

const readAddressesQuery = gql`
  query readAddresses {
    readAddresses {
      submitted {
        address
      }
    }
  }
`;

const emailOrderSubmittedMutation = gql`
  mutation emailOrderSubmitted($order: CustomerOrderInput) {
    emailOrderSubmitted(order: $order) {
      id
    }
  }
`;

export {
  findCustomerOrderByIdQuery,
  saveCustomerOrderMutation,
  deleteCustomerOrderMutation,
  findCustomerOrdersByKeywordQuery,
  readAddressesQuery,
  saveAddressesMutation,
  emailOrderSubmittedMutation
};
