import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { ThemeProvider, withTheme } from "styled-components/native";
import {
  array as yupArray,
  object as yupObject,
  string as yupString
} from "yup";
import {
  FormGeneralStatusField,
  InspectSubtitle,
  MutableInspect
} from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  FormCell,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  StoredRoundImage
} from "cci-component";
import { SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import { withRouter } from "cci-utility";
import {
  findTeamGroupByIdQuery,
  saveTeamGroupMutation,
  deleteTeamGroupMutation
} from "../../query/TeamGroup";
import TeamCrudField from "../TeamCrudField";

const messages = defineMessages({
  teamGroup: {
    id: "teamGroup",
    defaultMessage: "Team Group"
  },
  name: {
    id: "name",
    defaultMessage: "Name"
  },
  teams: {
    id: "teams",
    defaultMessage: "Teams"
  },
  team: {
    id: "team",
    defaultMessage: "Team"
  },
  selectOne: {
    id: "selectOne",
    defaultMessage: "Select one..."
  },
  orCreateOne: {
    id: "orCreateOne",
    defaultMessage: "Or create a new one..."
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  emptyMessage: {
    id: "emptyMessage",
    defaultMessage: "Not found"
  }
});

/**
 * Inspect component for the TeamGroup entity, allows the user to create,
 * edit or delete records if they have permission to do so.
 */
const Inspect = ({ theme, intl, history, ...restProps }) => (
  <MutableInspect
    title={messages.teamGroup}
    entityName="TeamGroup"
    query={findTeamGroupByIdQuery}
    queryName="findTeamGroupById"
    mutation={saveTeamGroupMutation}
    mutationName="saveTeamGroup"
    objectWrapper="teamGroup"
    defaultValues={{
      status: "active"
    }}
    validationSchema={yupObject().shape({
      name: yupString().required(),
      teams: yupArray()
        // .of(
        //   yupObject().shape({
        //     unit: yupObject()
        //       .required()
        //       .nullable()
        //   })
        // )
        .required()
    })}
    deleteMutation={deleteTeamGroupMutation}
    beforeMutate={({ options }) => {
      if (options.variables.teamGroup.name) {
        options.variables.teamGroup.name = options.variables.teamGroup.name.trim();
      }
    }}
    {...restProps}
  >
    {({ form }) => (
      <Fragment>
        <Section>
          <FormCell size="stretch">
            <FormTextField label={messages.name} name="name" />
          </FormCell>

          {/* Status */}
          <FormCell xlSize={150}>
            <FormGeneralStatusField />
          </FormCell>
        </Section>

        <InspectSubtitle
          title={messages.teams}
          lgStyle={restProps.negativeMargin === false && { marginLeft: 0 }}
        />

        <SessionQuery>
          {({ wrapBusiness }) => (
            <FormToManyField
              name="teams"
              // addValidation={
              //   initialQuantity &&
              //   yupObject().shape({
              //     unit: yupObject().required(),
              //     childUnitQuantity: yupNumber().required()
              //   })
              // }
              renderNewOnButton
            >
              {({ name, isNew, push, remove, value, index, plus, length }) => (
                <Container
                  key={name}
                  flexDirection="row"
                  alignItems="center"
                  paddings="10px"
                  width="100%"
                  style={{ zIndex: 99 - index || 0, maxWidth: 300 }}
                >
                  <DeletableItem
                    allowDelete={!isNew}
                    margins="10px 0 0"
                    height={60}
                    iconName="times"
                    confirmMessage={messages.confirmDeleteItem}
                    onConfirm={() => {
                      // TODO This is a Workaround. Correct fix is to UPGRADE ARRAY FIELDS.
                      // These are similar bugs:
                      // https://github.com/final-form/react-final-form-arrays/issues/56
                      // https://github.com/final-form/react-final-form-arrays/issues/107
                      // I tried to upgrade it and the result was an error:
                      // "useField must be used inside of a <Form> component".
                      if (length - 1 === index) {
                        form.change("name", form.getFieldState("name").value + " ");
                      }
                      remove();
                    }}
                    checkPermissions={{
                      objects: "BusinessTeamGroup",
                      actions: ["delete"]
                    }}
                  >
                    {form.getFieldState("teams").value &&
                      form.getFieldState("teams").value[index] &&
                      form.getFieldState("teams").value[index].business &&
                      form.getFieldState("teams").value[index].business.contact &&
                      form.getFieldState("teams").value[index].business.contact.photoId ?
                      (
                        <StoredRoundImage
                          size={60}
                          imageId={form.getFieldState("teams").value[index].business.contact.photoId}
                        />
                      ) : (
                        <RoundedIcon
                          iconSize={60}
                          color={theme.fieldFontColor}
                          name={isNew ? "plus" : "building"}
                        />
                      )
                    }
                  </DeletableItem>

                  <Section style={{ flex: 1 }}>
                    <FormCell size="stretch">
                      <ThemeProvider theme={{ fontColor: "#0054a4" }}>
                        <TeamCrudField
                          name={`${name}.business`}
                          onSelect={() => {
                            if (isNew) setTimeout(() => push(), 250);
                          }}
                        />
                      </ThemeProvider>
                    </FormCell>

                    {/* New Item Button */}
                    <SecuredComponent
                      checkPermissions={{
                        objects: "BusinessTeamGroup",
                        actions: ["create"]
                      }}
                    >
                      {!isNew && index + 1 === length && (
                        <Button
                          leftIcon="plus"
                          margins="30px 0 0 10px"
                          ui="dangerLink"
                          leftIconWeight={900}
                          onPress={plus}
                        />
                      )}
                    </SecuredComponent>
                  </Section>
                </Container>
              )}
            </FormToManyField>
          )}
        </SessionQuery>
      </Fragment>
    )}
  </MutableInspect>
);

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

export default withRouter(injectIntl(withTheme(Inspect)));
