/**
 * ManageSubmitAddressesPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Grid } from "react-native-responsive-layout";
import styled, { withTheme } from "styled-components/native";
import {
  string as yupString,
  object as yupObject,
  array as yupArray
} from "yup";
import { PrimaryPage, InspectSubtitle } from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  FormEmailField,
  FormToManyField,
  Inspect,
  Label,
  RoundedIcon,
  ToastContext
} from "cci-component";
import { localize } from "cci-utility";
import {
  saveAddressesMutation,
  readAddressesQuery
} from "../query/CustomerOrder";
import { SecuredComponent } from "cci-logic";

const Wrapper = styled.View`
  margin-top: ${props => props.theme.pageContentMarginTop};
  margin-left: ${props => props.theme.pagePadding};
  margin-right: ${props => props.theme.pagePadding};
  margin-bottom: ${props => props.theme.pagePadding};
  flex: 1;
`;

const messages = defineMessages({
  emails: {
    id: "emails",
    defaultMessage: "E-mails"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  info: {
    id: "info",
    defaultMessage:
      "This a list of recipients when a Team user submits an order."
  },
  savedSuccessfully: {
    id: "savedSuccessfully",
    defaultMessage: "Information saved succesfully!"
  }
});

const SubmittedAddressesPage = ({ theme, intl, ...props }) => (
  <PrimaryPage>
    <ToastContext.Consumer>
      {toast => (
        <Inspect
          entityName="SubmittedOrderAddresses"
          query={{ query: readAddressesQuery }}
          queryName="readAddresses"
          mutation={saveAddressesMutation}
          mutationName="saveAddresses"
          objectWrapper="submittedAddresses"
          defaultValues={{}}
          validationSchema={yupObject().shape({
            submitted: yupArray()
              .required("atLeastOneEmailRequired")
              .min(1, "atLeastOneEmailRequired")
              .of(
                yupObject().shape({
                  address: yupString()
                    .required()
                    .email("invalidEmailFieldError")
                })
              )
          })}
          onCompleted={savedData => {
            // Let the user know the data was saved successfully
            toast.success(messages.savedSuccessfully);
          }}
          // {...restProps}
        >
          {(loading, queryData, formProps) => (
            <Wrapper>
              <Container flex={1}>
                <Grid>
                  {/* E-mails */}
                  <InspectSubtitle title={messages.emails} />
                  <Label>{localize(intl, messages.info)}</Label>

                  <FormToManyField
                    name="submitted"
                    addValidation={yupObject().shape({
                      address: yupString()
                        .required()
                        .email()
                    })}
                    renderNewOnButton
                  >
                    {({ name, isNew, push, remove, index, plus, length }) => (
                      <Container
                        key={name}
                        flexDirection="row"
                        alignItems="center"
                        paddings="10px"
                      >
                        <DeletableItem
                          allowDelete={!isNew}
                          height={60}
                          iconName="times"
                          confirmMessage={messages.confirmDeleteItem}
                          onConfirm={remove}
                          checkPermissions={{
                            objects: "ContactEmail",
                            actions: ["delete"]
                          }}
                        >
                          {/* E-mail or add icon */}
                          <RoundedIcon
                            iconSize={50}
                            color={
                              theme.roundedIconColor ||
                              theme.fieldFontColor ||
                              theme.fontColor
                            }
                            name={isNew ? "plus-circle" : "envelope"}
                          />
                        </DeletableItem>

                        {/* E-mail address field */}
                        <FormEmailField
                          name={`${name}.address`}
                          onBlur={push}
                          margins="0 0 0 10px"
                          width={200}
                        />

                        {/* New Item Button */}
                        <SecuredComponent
                          checkPermissions={{
                            objects: "ContactEmail",
                            actions: ["create"]
                          }}
                        >
                          {!isNew &&
                          index + 1 === length &&
                          theme.renderNewOnButton ? (
                            <Button
                              leftIcon="plus"
                              margins="0 0 0 10px"
                              ui="dangerLink"
                              leftIconWeight={900}
                              onPress={plus}
                            />
                          ) : null}
                        </SecuredComponent>
                      </Container>
                    )}
                  </FormToManyField>
                </Grid>
              </Container>

              <Container flexDirection="row" justifyContent="flex-end">
                <Button
                  title={messages.save}
                  disabled={formProps.pristine}
                  onPress={() => {
                    // this.setState({ loadingSave: true });
                    formProps.handleSubmit();
                  }}
                />
              </Container>
            </Wrapper>
          )}
        </Inspect>
      )}
    </ToastContext.Consumer>
  </PrimaryPage>
);

export default injectIntl(withTheme(SubmittedAddressesPage));
