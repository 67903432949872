import React, { Fragment } from "react";
import { defineMessages, injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components/native";
import { object as yupObject, string as yupString } from "yup";
import { Container, layoutCss, StoredRoundImage } from "cci-component";
import { deleteBusinessMutation, findBusinessByIdQuery, findBusinessesByNameQuery, saveBusinessMutation } from "cci-data";
import { withRouter } from "cci-utility";
import CrudField from "./CrudField";
import teamLogo from "../assets/images/order.png";

const messages = defineMessages({
  teamGroup: {
    id: "teamGroup",
    defaultMessage: "Team Group"
  },
  name: {
    id: "name",
    defaultMessage: "Name"
  },
  teams: {
    id: "teams",
    defaultMessage: "Teams"
  },
  team: {
    id: "team",
    defaultMessage: "Team"
  },
  selectOne: {
    id: "selectOne",
    defaultMessage: "Select one..."
  },
  orCreateOne: {
    id: "orCreateOne",
    defaultMessage: "Or create a new one..."
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  emptyMessage: {
    id: "emptyMessage",
    defaultMessage: "Not found"
  }
});

const StyledText = styled.Text`
  ${layoutCss};
  color: ${props => props.color || props.theme.fontColor};
`;

/**
 * TeamCRUDFIELD component.
 */
const TeamCrudField = ({ theme, intl, history, hideTeamPhoto = true, ...restProps }) => (
  <CrudField
    {...restProps}
    title={messages.team}
    photo={false}
    queryById={findBusinessByIdQuery}
    queryByName={findBusinessesByNameQuery}
    queryName="findBusinessById"
    queryNameByName="findBusinessesByName"
    saveMutation={saveBusinessMutation}
    saveMutationName="saveBusiness"
    deleteMutation={deleteBusinessMutation}
    entityName="Business"
    objectWrapper="business"
    emptyMessage={messages.emptyMessage}
    onPressEdit={({form}) => {
      history.push(`/businesses/${form.getState().values.id}`);
    }}
    onPressNew={() => {
      history.push(`/businesses/new`);
    }}
    selectedDisplay={selectedData => (
      <Container
        // width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="40px"
      >
        {selectedData && selectedData.contact && (
          <Fragment>
            {!hideTeamPhoto && (
              <StoredRoundImage
                size={50}
                imageId={selectedData.contact.photoId}
                placeholder={teamLogo}
              />
            )}

            <Container flex={1} margins={!hideTeamPhoto && "0 10px 0"}>
              <StyledText numberOfLines={1}>
                {selectedData.contact.companyName}
              </StyledText>
            </Container>
          </Fragment>
        )}
      </Container>
    )}
    rowData={data => (
      <StyledText numberOfLines={1}>
        {data.item.contact.companyName}
      </StyledText>
    )}
    inspectContent={() => null}
    defaultValues={{
      status: "active"
    }}
    validationSchema={yupObject().shape({
      name: yupString().required()
    })}
  />
);

export default withRouter(injectIntl(withTheme(TeamCrudField)));
