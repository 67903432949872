import gql from "graphql-tag";

const findUnitsByProductQuery = gql`
  query findUnitsByProduct(
    $business: BusinessInput
    $product: ProductInput
    $startIndex: Int
    $stopIndex: Int
  ) {
    findUnitsByProduct(
      business: $business
      product: $product
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        id
        version
        status
        product {
          id
          version
          status
          name
        }
        unit {
          id
          version
          status
          name
        }
      }
    }
  }
`;

export { findUnitsByProductQuery };
