import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { Field } from "react-final-form";
import styled, { ThemeProvider, withTheme } from "styled-components/native";
import {
  array as yupArray,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import {
  FormGeneralStatusField,
  InspectSubtitle,
  MutableInspect
} from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  FormCell,
  FormCheckbox,
  FormTextField,
  FormNumberField,
  FormToManyField,
  layoutCss,
  RoundedIcon
} from "cci-component";
import { SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import CrudField from "../CrudField";
import {
  findProductByIdQuery,
  saveProductMutation,
  deleteProductMutation
} from "../../query/Product";
import {
  findUnitByIdQuery,
  findUnitsByNameQuery,
  saveUnitMutation,
  deleteUnitMutation
} from "../../query/Unit";

const messages = defineMessages({
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  name: {
    id: "name",
    defaultMessage: "Name"
  },
  units: {
    id: "units",
    defaultMessage: "Strengths"
  },
  unit: {
    id: "unit",
    defaultMessage: "Unit"
  },
  products: {
    id: "products",
    defaultMessage: "Products"
  },
  selectOne: {
    id: "selectOne",
    defaultMessage: "Select one..."
  },
  orCreateOne: {
    id: "orCreateOne",
    defaultMessage: "Or create a new one..."
  },
  unitName: {
    id: "unitName",
    defaultMessage: "Unit Name"
  },
  referenceId: {
    id: "referenceId",
    defaultMessage: "Reference Id"
  },
  initialQuantity: {
    id: "initialQuantity",
    defaultMessage: "Initial Quantity"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  emptyMessage: {
    id: "emptyMessage",
    defaultMessage: "Unit not found"
  },
  inventoryBypass: {
    id: "inventoryBypass",
    defaultMessage: "Do not include in inventory"
  }
});

const StyledText = styled.Text`
  ${layoutCss};
  color: ${props => props.color || props.theme.fontColor};
`;

/**
 * Inspect component for the Product entity, allows the user to create,
 * edit or delete records if they have permission to do so.
 */
const Inspect = ({ theme, intl, initialQuantity, ...restProps }) => (
  <MutableInspect
    title={messages.product}
    entityName="Product"
    query={findProductByIdQuery}
    queryName="findProductById"
    mutation={saveProductMutation}
    mutationName="saveProduct"
    objectWrapper="product"
    defaultValues={{
      status: "active"
    }}
    validationSchema={yupObject().shape({
      name: yupString().required(),
      units: yupArray()
        .of(
          yupObject().shape({
            unit: yupObject()
              .required()
              .nullable()
          })
        )
        .required()
    })}
    deleteMutation={deleteProductMutation}
    beforeMutate={({ initialValues, options }) => {
      if (options.variables.product.name) {
        options.variables.product.name = options.variables.product.name.trim();
      }
    }}
    {...restProps}
  >
    {({ form }) => (
      <Fragment>
        <Section>
          <FormCell size="stretch">
            <FormTextField label={messages.name} name="name" />
          </FormCell>

          {/* Status */}
          {!restProps.blockRedirection && (
            <FormCell xlSize={150}>
              <FormGeneralStatusField />
            </FormCell>
          )}
        </Section>

        {/* Inventory Bypass */}
        <Section>
          <FormCell>
            <FormCheckbox
              rightLabel={messages.inventoryBypass}
              name="inventoryBypass"
              backgroundColor='white'
            />
          </FormCell>
        </Section>

        <InspectSubtitle
          title={messages.units}
          lgStyle={restProps.negativeMargin === false && { marginLeft: 0 }}
        />

        <SessionQuery>
          {({ wrapBusiness }) => (
            <FormToManyField
              name="units"
              addValidation={
                initialQuantity &&
                yupObject().shape({
                  unit: yupObject().required(),
                  childUnitQuantity: yupNumber().required()
                })
              }
              renderNewOnButton
            >
              {({ name, isNew, push, remove, value, index, plus, length }) => {

                // Does not show the Product Unit which is from another business (not systemRecord)
                if (!isNew && value && value[index] && !value[index].systemRecord && value[index].id && value[index].businessId2 !== wrapBusiness().business.id) {
                  return null;
                }

                // The rest of ProductUnits
                return (
                  <Container
                    key={name}
                    flexDirection="row"
                    alignItems="center"
                    paddings="10px"
                    width={initialQuantity && "100%"}
                    style={{ zIndex: 99 - index || 0 }}
                  >
                    <DeletableItem
                      allowDelete={!isNew}
                      margins="10px 0 0"
                      height={60}
                      iconName="times"
                      confirmMessage={messages.confirmDeleteItem}
                      onConfirm={() => {
                        // TODO This is a Workaround. Correct fix is to UPGRADE ARRAY FIELDS.
                        // These are similar bugs:
                        // https://github.com/final-form/react-final-form-arrays/issues/56
                        // https://github.com/final-form/react-final-form-arrays/issues/107
                        // I tried to upgrade it and the result was an error:
                        // "useField must be used inside of a <Form> component".
                        if (length - 1 === index) {
                          form.change("name", form.getFieldState("name").value + " ");
                        }
                        remove();
                      }}
                      checkPermissions={{
                        objects: "ProductUnit",
                        actions: ["delete"]
                      }}
                    >
                      <RoundedIcon
                        iconSize={50}
                        color={theme.fieldFontColor}
                        name={isNew ? "plus" : "capsules"}
                      />
                    </DeletableItem>

                    <Section style={{ flex: 1 }}>
                      <FormCell size="stretch">
                        <ThemeProvider theme={{ fontColor: "#0054a4" }}>
                          <CrudField
                            title={messages.unit}
                            name={`${name}.unit`}
                            photo={false}
                            queryById={findUnitByIdQuery}
                            queryByName={findUnitsByNameQuery}
                            queryName="findUnitById"
                            queryNameByName="findUnitsByName"
                            saveMutation={saveUnitMutation}
                            saveMutationName="saveUnit"
                            deleteMutation={deleteUnitMutation}
                            entityName="Unit"
                            objectWrapper="unit"
                            emptyMessage={messages.emptyMessage}
                            hideSearchPlaceholder={restProps.blockRedirection}
                            onSelect={() => {
                              if (isNew) setTimeout(() => push(), 250);
                            }}
                            selectedDisplay={selectedData => {
                              return (
                                <Container
                                  width={180}
                                  flexDirection="row"
                                  height="50px"
                                >
                                  <ThemeProvider
                                    theme={
                                      restProps.blockRedirection
                                        ? { fontColor: "white" }
                                        : {}
                                    }
                                  >
                                    <Container flex={1} margins="0 0 0 10px">
                                      {selectedData && selectedData.name && (
                                        <StyledText numberOfLines={1}>
                                          {selectedData.name}
                                        </StyledText>
                                      )}
                                    </Container>
                                  </ThemeProvider>
                                </Container>
                              );
                            }}
                            rowData={data => (
                              <StyledText numberOfLines={1}>
                                {data.item.name}
                              </StyledText>
                            )}
                            inspectProps={{
                              autoSnap: false,
                              height: 0.4,
                              style: { minWidth: 280 }
                            }}
                            inspectContent={() => (
                              <Section>
                                <FormCell size="stretch">
                                  <FormTextField
                                    placeholder={messages.unitName}
                                    name="name"
                                  />
                                </FormCell>
                              </Section>
                            )}
                            defaultValues={{
                              status: "active"
                            }}
                            validationSchema={yupObject().shape({
                              name: yupString().required()
                            })}
                          />
                        </ThemeProvider>
                      </FormCell>

                      {initialQuantity && (
                        <FormCell size="1/3">
                          <FormNumberField
                            label={messages.initialQuantity}
                            name={`${name}.childUnitQuantity`}
                            onBlur={push}
                          />
                        </FormCell>
                      )}

                      {/* New Item Button */}
                      <Field name="id">
                        {({ input }) => (
                          <SecuredComponent
                            // Units can be added only when a product is new for a Not ProScript User
                            checkPermissions={{
                              objects: input.value ? "Product" : "ProductUnit",
                              actions: input.value ? ["update"] : ["create"]
                            }}
                          >
                            {!isNew && index + 1 === length && (
                              <Button
                                leftIcon="plus"
                                margins="30px 0 0 10px"
                                ui="dangerLink"
                                leftIconWeight={900}
                                onPress={plus}
                              />
                            )}
                          </SecuredComponent>
                        )}
                      </Field>
                    </Section>
                  </Container>
                )
              }}
            </FormToManyField>
          )}
        </SessionQuery>
      </Fragment>
    )}
  </MutableInspect>
);

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

export default injectIntl(withTheme(Inspect));
