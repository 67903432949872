const countries = [
  {
    defaultMessage: "United States",
    phoneCode: "+1",
    id: "US",
    alpha3code: "USA"
  },
  {
    defaultMessage: "Afghanistan",
    phoneCode: "+93",
    id: "AF",
    alpha3code: "AFG"
  },
  {
    defaultMessage: "Albania",
    phoneCode: "+355",
    id: "AL",
    alpha3code: "ALB"
  },
  {
    defaultMessage: "Algeria",
    phoneCode: "+213",
    id: "DZ",
    alpha3code: "DZA"
  },
  {
    defaultMessage: "American Samoa",
    phoneCode: "+1",
    id: "AS",
    alpha3code: "ASM"
  },
  {
    defaultMessage: "Andorra",
    phoneCode: "+376",
    id: "AD",
    alpha3code: "AND"
  },
  {
    defaultMessage: "Angola",
    phoneCode: "+244",
    id: "AO",
    alpha3code: "AGO"
  },
  {
    defaultMessage: "Anguilla",
    phoneCode: "+1",
    id: "AI",
    alpha3code: "AIA"
  },
  {
    defaultMessage: "Antigua and Barbuda",
    phoneCode: "+1",
    id: "AG",
    alpha3code: "ATG"
  },
  {
    defaultMessage: "Argentina",
    phoneCode: "+54",
    id: "AR",
    alpha3code: "ARG"
  },
  {
    defaultMessage: "Armenia",
    phoneCode: "+374",
    id: "AM",
    alpha3code: "ARM"
  },
  {
    defaultMessage: "Aruba",
    phoneCode: "+297",
    id: "AW",
    alpha3code: "ABW"
  },
  {
    defaultMessage: "Australia",
    phoneCode: "+61",
    id: "AU",
    alpha3code: "AUS"
  },
  {
    defaultMessage: "Austria",
    phoneCode: "+43",
    id: "AI",
    alpha3code: "AUT"
  },
  {
    defaultMessage: "Azerbaijan",
    phoneCode: "+994",
    id: "AZ",
    alpha3code: "AZE"
  },
  {
    defaultMessage: "Bahrain",
    phoneCode: "+973",
    id: "BH",
    alpha3code: "BHR"
  },
  {
    defaultMessage: "Bangladesh",
    phoneCode: "+880",
    id: "BD",
    alpha3code: "BGD"
  },
  {
    defaultMessage: "Barbados",
    phoneCode: "+1",
    id: "BB",
    alpha3code: "BRB"
  },
  {
    defaultMessage: "Belarus",
    phoneCode: "+375",
    id: "BY",
    alpha3code: "BLR"
  },
  {
    defaultMessage: "Belgium",
    phoneCode: "+32",
    id: "BE",
    alpha3code: "BEL"
  },
  {
    defaultMessage: "Belize",
    phoneCode: "+501",
    id: "BZ",
    alpha3code: "BLZ"
  },
  {
    defaultMessage: "Benin",
    phoneCode: "+229",
    id: "BJ",
    alpha3code: "BEN"
  },
  {
    defaultMessage: "Bermuda",
    phoneCode: "+1",
    id: "BM",
    alpha3code: "BMU"
  },
  {
    defaultMessage: "Bhutan",
    phoneCode: "+975",
    id: "BT",
    alpha3code: "BTN"
  },
  {
    defaultMessage: "Bolivia",
    phoneCode: "+591",
    id: "BO",
    alpha3code: "BOL"
  },
  {
    defaultMessage: "Bonaire, Sint Eustatius and Saba",
    phoneCode: "+599",
    id: "BQ",
    alpha3code: "BES"
  },
  {
    defaultMessage: "Bosnia and Herzegovina",
    phoneCode: "+387",
    id: "BA",
    alpha3code: "BIH"
  },
  {
    defaultMessage: "Botswana",
    phoneCode: "+267",
    id: "BW",
    alpha3code: "BWA"
  },
  {
    defaultMessage: "Brazil",
    phoneCode: "+55",
    id: "BR",
    alpha3code: "BRA"
  },
  {
    defaultMessage: "British Indian Ocean Territory",
    phoneCode: "+246",
    id: "IO",
    alpha3code: "IOT"
  },
  {
    defaultMessage: "British Virgin Islands",
    phoneCode: "+1",
    id: "VG",
    alpha3code: "VGB"
  },
  {
    defaultMessage: "Brunei Darussalam",
    phoneCode: "+673",
    id: "BN",
    alpha3code: "BRN"
  },
  {
    defaultMessage: "Bulgaria",
    phoneCode: "+359",
    id: "BG",
    alpha3code: "BGR"
  },
  {
    defaultMessage: "Burkina Faso",
    phoneCode: "+226",
    id: "BF",
    alpha3code: "BFA"
  },
  {
    defaultMessage: "Myanmar",
    phoneCode: "+95",
    id: "MM",
    alpha3code: "MMR"
  },
  {
    defaultMessage: "Burundi",
    phoneCode: "+257",
    id: "BI",
    alpha3code: "BDI"
  },
  {
    defaultMessage: "Cambodia",
    phoneCode: "+855",
    id: "KH",
    alpha3code: "KHM"
  },
  {
    defaultMessage: "Cameroon",
    phoneCode: "+237",
    id: "CM",
    alpha3code: "CMR"
  },
  {
    defaultMessage: "Canada",
    phoneCode: "+1",
    id: "CA",
    alpha3code: "CAN"
  },
  {
    defaultMessage: "Cabo Verde",
    phoneCode: "+238",
    id: "CV",
    alpha3code: "CPV"
  },
  {
    defaultMessage: "Cayman Islands",
    phoneCode: "+1",
    id: "KY",
    alpha3code: "CYM"
  },
  {
    defaultMessage: "Central African Republic",
    phoneCode: "+236",
    id: "CF",
    alpha3code: "CAF"
  },
  {
    defaultMessage: "Chad",
    phoneCode: "+235",
    id: "ID",
    alpha3code: "TCD"
  },
  {
    defaultMessage: "Chile",
    phoneCode: "+56",
    id: "CL",
    alpha3code: "CHL"
  },
  {
    defaultMessage: "China",
    phoneCode: "+86",
    id: "CN",
    alpha3code: "CHN"
  },
  {
    defaultMessage: "Colombia",
    phoneCode: "+57",
    id: "CO",
    alpha3code: "COL"
  },
  {
    defaultMessage: "Comoros",
    phoneCode: "+269",
    id: "KM",
    alpha3code: "COM"
  },
  {
    defaultMessage: "Cook Islands",
    phoneCode: "+682",
    id: "CK",
    alpha3code: "COK"
  },
  {
    defaultMessage: "Costa Rica",
    phoneCode: "+506",
    id: "CR",
    alpha3code: "CRI"
  },
  {
    defaultMessage: "Côte dIvoire",
    phoneCode: "+225",
    id: "CI",
    alpha3code: "CIV"
  },
  {
    defaultMessage: "Croatia",
    phoneCode: "+385",
    id: "HR",
    alpha3code: "HRV"
  },
  {
    defaultMessage: "Cuba",
    phoneCode: "+53",
    id: "CU",
    alpha3code: "CUB"
  },
  {
    defaultMessage: "Curaçao",
    phoneCode: "+599",
    id: "CW",
    alpha3code: "CUW"
  },
  {
    defaultMessage: "Cyprus",
    phoneCode: "+357",
    id: "CY",
    alpha3code: "CYP"
  },
  {
    defaultMessage: "Czechia",
    phoneCode: "+420",
    id: "CZ",
    alpha3code: "CZE"
  },
  {
    defaultMessage: "Denmark",
    phoneCode: "+45",
    id: "DK",
    alpha3code: "DNK"
  },
  {
    defaultMessage: "Djibouti",
    phoneCode: "+253",
    id: "DJ",
    alpha3code: "DJI"
  },
  {
    defaultMessage: "Dominica",
    phoneCode: "+1",
    id: "DM",
    alpha3code: "DMA"
  },
  {
    defaultMessage: "Dominican Republic",
    phoneCode: "+1",
    id: "DO",
    alpha3code: "DOM"
  },
  {
    defaultMessage: "Ecuador",
    phoneCode: "+593",
    id: "EC",
    alpha3code: "ECU"
  },
  {
    defaultMessage: "Egypt",
    phoneCode: "+20",
    id: "EG",
    alpha3code: "EGY"
  },
  {
    defaultMessage: "El Salvador",
    phoneCode: "+503",
    id: "SV",
    alpha3code: "SLV"
  },
  {
    defaultMessage: "Equatorial Guinea",
    phoneCode: "+240",
    id: "GQ",
    alpha3code: "GNQ"
  },
  {
    defaultMessage: "Eritrea",
    phoneCode: "+291",
    id: "ER",
    alpha3code: "ERI"
  },
  {
    defaultMessage: "Estonia",
    phoneCode: "+372",
    id: "EE",
    alpha3code: "EST"
  },
  {
    defaultMessage: "Ethiopia",
    phoneCode: "+251",
    id: "ET",
    alpha3code: "ETH"
  },
  {
    defaultMessage: "Falkland Islands",
    phoneCode: "+500",
    id: "FK",
    alpha3code: "FLK"
  },
  {
    defaultMessage: "Faroe Islands",
    phoneCode: "+298",
    id: "FO",
    alpha3code: "FRO"
  },
  {
    defaultMessage: "Federated States of Micronesia",
    phoneCode: "+691",
    id: "FM",
    alpha3code: "FSM"
  },
  {
    defaultMessage: "Fiji",
    phoneCode: "+679",
    id: "FJ",
    alpha3code: "FJI"
  },
  {
    defaultMessage: "Finland",
    phoneCode: "+358",
    id: "FI",
    alpha3code: "FIN"
  },
  {
    defaultMessage: "France",
    phoneCode: "+33",
    id: "FR",
    alpha3code: "FRA"
  },
  {
    defaultMessage: "French Guiana",
    phoneCode: "+594",
    id: "GF",
    alpha3code: "GUF"
  },
  {
    defaultMessage: "French Polynesia",
    phoneCode: "+689",
    id: "PF",
    alpha3code: "PYF"
  },
  {
    defaultMessage: "Gabon",
    phoneCode: "+241",
    id: "GA",
    alpha3code: "GAB"
  },
  {
    defaultMessage: "Georgia",
    phoneCode: "+995",
    id: "GE",
    alpha3code: "GEO"
  },
  {
    defaultMessage: "Germany",
    phoneCode: "+49",
    id: "DE",
    alpha3code: "DEU"
  },
  {
    defaultMessage: "Ghana",
    phoneCode: "+233",
    id: "GH",
    alpha3code: "GHA"
  },
  {
    defaultMessage: "Gibraltar",
    phoneCode: "+350",
    id: "GI",
    alpha3code: "GIB"
  },
  {
    defaultMessage: "Greece",
    phoneCode: "+30",
    id: "GR",
    alpha3code: "GRC"
  },
  {
    defaultMessage: "Greenland",
    phoneCode: "+299",
    id: "GL",
    alpha3code: "GRL"
  },
  {
    defaultMessage: "Grenada",
    phoneCode: "+1",
    id: "GD",
    alpha3code: "GRD"
  },
  {
    defaultMessage: "Guadeloupe",
    phoneCode: "+590",
    id: "GP",
    alpha3code: "GLP"
  },
  {
    defaultMessage: "Guam",
    phoneCode: "+1",
    id: "GU",
    alpha3code: "GUM"
  },
  {
    defaultMessage: "Guatemala",
    phoneCode: "+502",
    id: "GT",
    alpha3code: "GTM"
  },
  {
    defaultMessage: "Guinea",
    phoneCode: "+224",
    id: "GN",
    alpha3code: "GIN"
  },
  {
    defaultMessage: "Guinea-Bissau",
    phoneCode: "+245",
    id: "GW",
    alpha3code: "GNB"
  },
  {
    defaultMessage: "Guyana",
    phoneCode: "+592",
    id: "GY",
    alpha3code: "GUY"
  },
  {
    defaultMessage: "Haiti",
    phoneCode: "+509",
    id: "HT",
    alpha3code: "HTI"
  },
  {
    defaultMessage: "Honduras",
    phoneCode: "+504",
    id: "HN",
    alpha3code: "HND"
  },
  {
    defaultMessage: "Hong Kong",
    phoneCode: "+852",
    id: "HK",
    alpha3code: "HKG"
  },
  {
    defaultMessage: "Hungary",
    phoneCode: "+36",
    id: "HU",
    alpha3code: "HUN"
  },
  {
    defaultMessage: "Iceland",
    phoneCode: "+354",
    id: "IS",
    alpha3code: "ISL"
  },
  {
    defaultMessage: "India",
    phoneCode: "+91",
    id: "IN",
    alpha3code: "IND"
  },
  {
    defaultMessage: "Indonesia",
    phoneCode: "+62",
    id: "ID",
    alpha3code: "IDN"
  },
  {
    defaultMessage: "Iran",
    phoneCode: "+98",
    id: "IR",
    alpha3code: "IRN"
  },
  {
    defaultMessage: "Iraq",
    phoneCode: "+964",
    id: "IQ",
    alpha3code: "IRQ"
  },
  {
    defaultMessage: "Ireland",
    phoneCode: "+353",
    id: "IE",
    alpha3code: "IRL"
  },
  {
    defaultMessage: "Israel",
    phoneCode: "+972",
    id: "IL",
    alpha3code: "ISR"
  },
  {
    defaultMessage: "Italy",
    phoneCode: "+39",
    id: "IT",
    alpha3code: "ITA"
  },
  {
    defaultMessage: "Jamaica",
    phoneCode: "+1",
    id: "JM",
    alpha3code: "JAM"
  },
  {
    defaultMessage: "Japan",
    phoneCode: "+81",
    id: "JP",
    alpha3code: "JPN"
  },
  {
    defaultMessage: "Jordan",
    phoneCode: "+962",
    id: "JO",
    alpha3code: "JOR"
  },
  {
    defaultMessage: "Kazakhstan",
    phoneCode: "+7",
    id: "KZ",
    alpha3code: "KAZ"
  },
  {
    defaultMessage: "Kenya",
    phoneCode: "+254",
    id: "KE",
    alpha3code: "KEN"
  },
  {
    defaultMessage: "Kiribati",
    phoneCode: "+686",
    id: "KI",
    alpha3code: "KIR"
  },
  {
    defaultMessage: "Kuwait",
    phoneCode: "+965",
    id: "KW",
    alpha3code: "KWT"
  },
  {
    defaultMessage: "Kyrgyzstan",
    phoneCode: "+996",
    id: "KG",
    alpha3code: "KGZ"
  },
  {
    defaultMessage: "Laos",
    phoneCode: "+856",
    id: "LA",
    alpha3code: "LAO"
  },
  {
    defaultMessage: "Latvia",
    phoneCode: "+371",
    id: "LV",
    alpha3code: "LVA"
  },
  {
    defaultMessage: "Lebanon",
    phoneCode: "+961",
    id: "LB",
    alpha3code: "LBN"
  },
  {
    defaultMessage: "Lesotho",
    phoneCode: "+266",
    id: "LS",
    alpha3code: "LSO"
  },
  {
    defaultMessage: "Liberia",
    phoneCode: "+231",
    id: "LR",
    alpha3code: "LBR"
  },
  {
    defaultMessage: "Libya",
    phoneCode: "+218",
    id: "LY",
    alpha3code: "LBY"
  },
  {
    defaultMessage: "Liechtenstein",
    phoneCode: "+423",
    id: "LI",
    alpha3code: "LIE"
  },
  {
    defaultMessage: "Lithuania",
    phoneCode: "+370",
    id: "LT",
    alpha3code: "LTU"
  },
  {
    defaultMessage: "Luxembourg",
    phoneCode: "+352",
    id: "LU",
    alpha3code: "LUX"
  },
  {
    defaultMessage: "Macao",
    phoneCode: "+853",
    id: "MO",
    alpha3code: "MAC"
  },
  {
    defaultMessage: "Macedonia",
    phoneCode: "+389",
    id: "MK",
    alpha3code: "MKD"
  },
  {
    defaultMessage: "Madagascar",
    phoneCode: "+261",
    id: "MG",
    alpha3code: "MDG"
  },
  {
    defaultMessage: "Malawi",
    phoneCode: "+265",
    id: "MW",
    alpha3code: "MWI"
  },
  {
    defaultMessage: "Malaysia",
    phoneCode: "+60",
    id: "MY",
    alpha3code: "MYS"
  },
  {
    defaultMessage: "Maldives",
    phoneCode: "+960",
    id: "MV",
    alpha3code: "MDV"
  },
  {
    defaultMessage: "Mali",
    phoneCode: "+223",
    id: "ML",
    alpha3code: "MLI"
  },
  {
    defaultMessage: "Malta",
    phoneCode: "+356",
    id: "MT",
    alpha3code: "MLT"
  },
  {
    defaultMessage: "Marshall Islands",
    phoneCode: "+692",
    id: "MH",
    alpha3code: "MHL"
  },
  {
    defaultMessage: "Martinique",
    phoneCode: "+596",
    id: "MQ",
    alpha3code: "MTQ"
  },
  {
    defaultMessage: "Mauritania",
    phoneCode: "+222",
    id: "MR",
    alpha3code: "MRT"
  },
  {
    defaultMessage: "Mauritius",
    phoneCode: "+230",
    id: "MU",
    alpha3code: "MUS"
  },
  {
    defaultMessage: "Mayotte",
    phoneCode: "+262",
    id: "YT",
    alpha3code: "MYT"
  },
  {
    defaultMessage: "Mexico",
    phoneCode: "+52",
    id: "MX",
    alpha3code: "MEX"
  },
  {
    defaultMessage: "Moldova",
    phoneCode: "+373",
    id: "MD",
    alpha3code: "MDA"
  },
  {
    defaultMessage: "Monaco",
    phoneCode: "+377",
    id: "MC",
    alpha3code: "MCO"
  },
  {
    defaultMessage: "Mongolia",
    phoneCode: "+976",
    id: "MN",
    alpha3code: "MNG"
  },
  {
    defaultMessage: "Montenegro",
    phoneCode: "+382",
    id: "ME",
    alpha3code: "MNE"
  },
  {
    defaultMessage: "Montserrat",
    phoneCode: "+1",
    id: "MS",
    alpha3code: "MSR"
  },
  {
    defaultMessage: "Morocco",
    phoneCode: "+212",
    id: "MA",
    alpha3code: "MAR"
  },
  {
    defaultMessage: "Mozambique",
    phoneCode: "+258",
    id: "MZ",
    alpha3code: "MOZ"
  },
  {
    defaultMessage: "Namibia",
    phoneCode: "+264",
    id: "NA",
    alpha3code: "NAM"
  },
  {
    defaultMessage: "Nauru",
    phoneCode: "+674",
    id: "NR",
    alpha3code: "NRU"
  },
  {
    defaultMessage: "Nepal",
    phoneCode: "+977",
    id: "NP",
    alpha3code: "NPL"
  },
  {
    defaultMessage: "Netherlands",
    phoneCode: "+31",
    id: "NL",
    alpha3code: "NLD"
  },
  {
    defaultMessage: "Netherlands Antilles",
    phoneCode: "+599",
    id: "AN",
    alpha3code: "ANT"
  },
  {
    defaultMessage: "New Caledonia",
    phoneCode: "+687",
    id: "NC",
    alpha3code: "NCL"
  },
  {
    defaultMessage: "New Zealand",
    phoneCode: "+64",
    id: "NZ",
    alpha3code: "NZL"
  },
  {
    defaultMessage: "Nicaragua",
    phoneCode: "+505",
    id: "NI",
    alpha3code: "NIC"
  },
  {
    defaultMessage: "Niger",
    phoneCode: "+227",
    id: "NE",
    alpha3code: "NER"
  },
  {
    defaultMessage: "Nigeria",
    phoneCode: "+234",
    id: "NG",
    alpha3code: "NGA"
  },
  {
    defaultMessage: "Niue",
    phoneCode: "+683",
    id: "NU",
    alpha3code: "NIU"
  },
  {
    defaultMessage: "Norfolk Island",
    phoneCode: "+672",
    id: "NF",
    alpha3code: "NFK"
  },
  {
    defaultMessage: "North Korea",
    phoneCode: "+850",
    id: "KP",
    alpha3code: "PRK"
  },
  {
    defaultMessage: "Northern Mariana Islands",
    phoneCode: "+1",
    id: "MP",
    alpha3code: "MNP"
  },
  {
    defaultMessage: "Norway",
    phoneCode: "+47",
    id: "NO",
    alpha3code: "NOR"
  },
  {
    defaultMessage: "Oman",
    phoneCode: "+968",
    id: "OM",
    alpha3code: "OMN"
  },
  {
    defaultMessage: "Pakistan",
    phoneCode: "+92",
    id: "PK",
    alpha3code: "PAK"
  },
  {
    defaultMessage: "Palau",
    phoneCode: "+680",
    id: "PW",
    alpha3code: "PLW"
  },
  {
    defaultMessage: "Palestine",
    phoneCode: "+970",
    id: "PS",
    alpha3code: "PSE"
  },
  {
    defaultMessage: "Panama",
    phoneCode: "+507",
    id: "PA",
    alpha3code: "PAN"
  },
  {
    defaultMessage: "Papua New Guinea",
    phoneCode: "+675",
    id: "PG",
    alpha3code: "PNG"
  },
  {
    defaultMessage: "Paraguay",
    phoneCode: "+595",
    id: "PY",
    alpha3code: "PRY"
  },
  {
    defaultMessage: "Peru",
    phoneCode: "+51",
    id: "PE",
    alpha3code: "PER"
  },
  {
    defaultMessage: "Philippines",
    phoneCode: "+63",
    id: "PH",
    alpha3code: "PHL"
  },
  {
    defaultMessage: "Poland",
    phoneCode: "+48",
    id: "PL",
    alpha3code: "POL"
  },
  {
    defaultMessage: "Portugal",
    phoneCode: "+351",
    id: "PT",
    alpha3code: "PRT"
  },
  {
    defaultMessage: "Puerto Rico",
    phoneCode: "+1",
    id: "PR",
    alpha3code: "PRI"
  },
  {
    defaultMessage: "Qatar",
    phoneCode: "+974",
    id: "QA",
    alpha3code: "QAT"
  },
  {
    defaultMessage: "Congo",
    phoneCode: "+242",
    id: "CG",
    alpha3code: "COG"
  },
  {
    defaultMessage: "Réunion",
    phoneCode: "+262",
    id: "RE",
    alpha3code: "REU"
  },
  {
    defaultMessage: "Romania",
    phoneCode: "+40",
    id: "RO",
    alpha3code: "ROU"
  },
  {
    defaultMessage: "Russia",
    phoneCode: "+7",
    id: "RU",
    alpha3code: "RUS"
  },
  {
    defaultMessage: "Rwanda",
    phoneCode: "+250",
    id: "RW",
    alpha3code: "RWA"
  },
  {
    defaultMessage: "Saint Barthélemy",
    phoneCode: "+590",
    id: "BL",
    alpha3code: "BLM"
  },
  {
    defaultMessage: "Saint Helena",
    phoneCode: "+290",
    id: "SH",
    alpha3code: "SHN"
  },
  {
    defaultMessage: "Saint Kitts and Nevis",
    phoneCode: "+1",
    id: "KN",
    alpha3code: "KNA"
  },
  {
    defaultMessage: "Saint Martin",
    phoneCode: "+590",
    id: "MF",
    alpha3code: "MAF"
  },
  {
    defaultMessage: "Saint Pierre and Miquelon",
    phoneCode: "+508",
    id: "PM",
    alpha3code: "SPM"
  },
  {
    defaultMessage: "Saint Vincent and the Grenadines",
    phoneCode: "+1",
    id: "VC",
    alpha3code: "VCT"
  },
  {
    defaultMessage: "Samoa",
    phoneCode: "+685",
    id: "WS",
    alpha3code: "WSM"
  },
  {
    defaultMessage: "San Marino",
    phoneCode: "+378",
    id: "SM",
    alpha3code: "SMR"
  },
  {
    defaultMessage: "São Tomé and Príncipe",
    phoneCode: "+239",
    id: "ST",
    alpha3code: "STP"
  },
  {
    defaultMessage: "Saudi Arabia",
    phoneCode: "+966",
    id: "SA",
    alpha3code: "SAU"
  },
  {
    defaultMessage: "Senegal",
    phoneCode: "+221",
    id: "SN",
    alpha3code: "SEN"
  },
  {
    defaultMessage: "Serbia",
    phoneCode: "+381",
    id: "RS",
    alpha3code: "SRB"
  },
  {
    defaultMessage: "Seychelles",
    phoneCode: "+248",
    id: "SC",
    alpha3code: "SYC"
  },
  {
    defaultMessage: "Sierra Leone",
    phoneCode: "+232",
    id: "SL",
    alpha3code: "SLE"
  },
  {
    defaultMessage: "Singapore",
    phoneCode: "+65",
    id: "SG",
    alpha3code: "SGP"
  },
  {
    defaultMessage: "Sint Maarten (Dutch part)",
    phoneCode: "+1",
    id: "SX",
    alpha3code: "SXM"
  },
  {
    defaultMessage: "Slovakia",
    phoneCode: "+421",
    id: "SK",
    alpha3code: "SVK"
  },
  {
    defaultMessage: "Slovenia",
    phoneCode: "+386",
    id: "SI",
    alpha3code: "SVN"
  },
  {
    defaultMessage: "Solomon Islands",
    phoneCode: "+677",
    id: "SB",
    alpha3code: "SLB"
  },
  {
    defaultMessage: "Somalia",
    phoneCode: "+252",
    id: "SO",
    alpha3code: "SOM"
  },
  {
    defaultMessage: "South Africa",
    phoneCode: "+27",
    id: "ZA",
    alpha3code: "ZAF"
  },
  {
    defaultMessage: "South Korea",
    phoneCode: "+82",
    id: "KR",
    alpha3code: "KOR"
  },
  {
    defaultMessage: "South Sudan",
    phoneCode: "+211",
    id: "SS",
    alpha3code: "SSD"
  },
  {
    defaultMessage: "Spain",
    phoneCode: "+34",
    id: "ES",
    alpha3code: "ESP"
  },
  {
    defaultMessage: "Sri Lanka",
    phoneCode: "+94",
    id: "LK",
    alpha3code: "LKA"
  },
  {
    defaultMessage: "Saint Lucia",
    phoneCode: "+1",
    id: "LC",
    alpha3code: "LCA"
  },
  {
    defaultMessage: "Sudan",
    phoneCode: "+249",
    id: "SD",
    alpha3code: "SDN"
  },
  {
    defaultMessage: "Suriname",
    phoneCode: "+597",
    id: "SR",
    alpha3code: "SUR"
  },
  {
    defaultMessage: "Swaziland",
    phoneCode: "+268",
    id: "SZ",
    alpha3code: "SWZ"
  },
  {
    defaultMessage: "Sweden",
    phoneCode: "+46",
    id: "SE",
    alpha3code: "SWE"
  },
  {
    defaultMessage: "Switzerland",
    phoneCode: "+41",
    id: "CH",
    alpha3code: "CHE"
  },
  {
    defaultMessage: "Syrian Arab Republic",
    phoneCode: "+963",
    id: "SY",
    alpha3code: "SYR"
  },
  {
    defaultMessage: "Taiwan",
    phoneCode: "+886",
    id: "TW",
    alpha3code: "TWN"
  },
  {
    defaultMessage: "Tajikistan",
    phoneCode: "+992",
    id: "TJ",
    alpha3code: "TJK"
  },
  {
    defaultMessage: "Tanzania",
    phoneCode: "+255",
    id: "TZ",
    alpha3code: "TZA"
  },
  {
    defaultMessage: "Thailand",
    phoneCode: "+66",
    id: "TH",
    alpha3code: "THA"
  },
  {
    defaultMessage: "Bahamas",
    phoneCode: "+1",
    id: "BS",
    alpha3code: "BHS"
  },
  {
    defaultMessage: "Gambia",
    phoneCode: "+220",
    id: "GM",
    alpha3code: "GMB"
  },
  {
    defaultMessage: "Timor-Leste",
    phoneCode: "+670",
    id: "TL",
    alpha3code: "TLS"
  },
  {
    defaultMessage: "Togo",
    phoneCode: "+228",
    id: "TG",
    alpha3code: "TGO"
  },
  {
    defaultMessage: "Tokelau",
    phoneCode: "+690",
    id: "TK",
    alpha3code: "TKL"
  },
  {
    defaultMessage: "Tonga",
    phoneCode: "+676",
    id: "TO",
    alpha3code: "TON"
  },
  {
    defaultMessage: "Trinidad and Tobago",
    phoneCode: "+1",
    id: "TT",
    alpha3code: "TTO"
  },
  {
    defaultMessage: "Tunisia",
    phoneCode: "+216",
    id: "TN",
    alpha3code: "TUN"
  },
  {
    defaultMessage: "Turkey",
    phoneCode: "+90",
    id: "TR",
    alpha3code: "TUR"
  },
  {
    defaultMessage: "Turkmenistan",
    phoneCode: "+993",
    id: "TM",
    alpha3code: "TKM"
  },
  {
    defaultMessage: "Turks and Caicos Islands",
    phoneCode: "+1",
    id: "TC",
    alpha3code: "TCA"
  },
  {
    defaultMessage: "Tuvalu",
    phoneCode: "+688",
    id: "TV",
    alpha3code: "TUV"
  },
  {
    defaultMessage: "Uganda",
    phoneCode: "+256",
    id: "UG",
    alpha3code: "UGA"
  },
  {
    defaultMessage: "Ukraine",
    phoneCode: "+380",
    id: "UA",
    alpha3code: "UKR"
  },
  {
    defaultMessage: "United Arab Emirates",
    phoneCode: "+971",
    id: "AE",
    alpha3code: "ARE"
  },
  {
    defaultMessage: "United Kingdom",
    phoneCode: "+44",
    id: "GB",
    alpha3code: "GBR"
  },
  {
    defaultMessage: "Uruguay",
    phoneCode: "+598",
    id: "UY",
    alpha3code: "URY"
  },
  {
    defaultMessage: "U.S. Virgin Islands",
    phoneCode: "+1",
    id: "VI",
    alpha3code: "VIR"
  },
  {
    defaultMessage: "Uzbekistan",
    phoneCode: "+998",
    id: "UZ",
    alpha3code: "UZB"
  },
  {
    defaultMessage: "Vanuatu",
    phoneCode: "+678",
    id: "VU",
    alpha3code: "VUT"
  },
  {
    defaultMessage: "Vatican City",
    phoneCode: "+39",
    id: "VA",
    alpha3code: "VAT"
  },
  {
    defaultMessage: "Venezuela",
    phoneCode: "+58",
    id: "VE",
    alpha3code: "VEN"
  },
  {
    defaultMessage: "Vietnam",
    phoneCode: "+84",
    id: "VN",
    alpha3code: "VNM"
  },
  {
    defaultMessage: "Wallis and Futuna",
    phoneCode: "+681",
    id: "WF",
    alpha3code: "WLF"
  },
  {
    defaultMessage: "Yemen",
    phoneCode: "+967",
    id: "YE",
    alpha3code: "YEM"
  },
  {
    defaultMessage: "Zambia",
    phoneCode: "+260",
    id: "ZM",
    alpha3code: "ZMB"
  },
  {
    defaultMessage: "Zimbabwe",
    phoneCode: "+263",
    id: "ZW",
    alpha3code: "ZWE"
  }
];
export { countries };
