import React from "react";
import { defineMessages } from "react-intl";
import { ImmutableList, MutableList } from "cci-admin";
import { LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { findPatientsByNameQuery } from "../../query/Patient";

const messages = defineMessages({
  searchPatients: {
    id: "searchPatients",
    defaultMessage: "Search Personnel"
  },
  title: {
    id: "personnelManagement",
    defaultMessage: "Personnel Management"
  }
});

/**
 * Patient List page which allows searching.
 */
const List = ({ teamChange, ...props }) => {
  const Wrapper = teamChange ? ImmutableList : MutableList;

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <Wrapper
          {...props}
          listTitle={messages.title}
          queryName="findPatientsByName"
          query={findPatientsByNameQuery}
          filterProperty="name"
          variables={teamChange ? wrapBusiness({ name: "", teamChange: true }) : wrapBusiness({ name: "", teamChange: false })}
          searchPlaceholder={messages.searchPatients}
          newUrl={teamChange ? "/personnelteam/new" : "/personnel/new"}
          recordRenderer={({ key, style }, rowData) => (
            <LinkedRow
              key={key}
              style={style}
              to={{
                pathname: teamChange ? `/personnelteam/${rowData.id}` : `/personnel/${rowData.id}`,
                state: { accountId: rowData.id }
              }}
            >
              <RowText style={{ textAlign: "left" }} flex={1}>
                {rowData.contact.firstName + " " + rowData.contact.lastName}
              </RowText>
            </LinkedRow>
          )}
        />
      )}
    </SessionQuery>
  )
};

export default List;
