import React from "react";
import { defineMessages } from "react-intl";
import { WideSplitView } from "cci-component";
import { PrimaryPage, ReportPreview } from "cci-admin";
import Options from "../report/inventoryUse/Options";

const messages = defineMessages({
  selectReportOptions: {
    id: "selectReportOptions",
    defaultMessage: "Select the desired options to preview or print the report."
  }
});

/**
 * InventoryUse Reports Page.
 */
const InventoryUseReportPage = () => (
  <PrimaryPage>
    <WideSplitView
      rootPath="/inventoryusereport"
      selectionPath="/inventoryusereport/preview/:options"
      left={Options}
      right={ReportPreview}
      leftRightOptions={{
        reportName: "prs/data/report/inventoryUse.rptdesign"
      }}
      leftOptions={{ previewPath: "/inventoryusereport" }}
      placeholderMessage={messages.selectReportOptions}
    />
  </PrimaryPage>
);

export default InventoryUseReportPage;
