import React from "react";
import { ActivityIndicator } from "react-native";
import { Query } from "react-apollo";
import { defineMessages, injectIntl } from "react-intl";
import { Container, Label } from "cci-component";
import { SessionQuery } from "cci-data";
import { localize, withRouter } from "cci-utility";
// TODO maybe is better to use findNotCountedTransactionsByProductUnitQuery ? Do we have to recount zeroed bottles?
import { findBusinessTransactionsQuery } from "../../query/InventoryUse";
import SubInspect from "./SubInspect";

const messages = defineMessages({
  someError: {
    id: "someError",
    defaultMessage: "Some error ocurred getting available stock."
  }
});

/**
 * Inspect component for the PhysicalCounts entity,
 * allows the user to create records if the user has permissions to do it.
 * 
 * This is an InventoryAudit.
 */
const Inspect = ({ intl, match, ...restProps }) => {
  const newRecord = match.params.id === "new";

  return (
    <SessionQuery>
      {({ wrapBusiness }) => {
        let invAudit = {status: "opened", counts: []}

        if (newRecord) {
          return (
            <Query
              query={findBusinessTransactionsQuery}
              fetchPolicy="no-cache"
              variables={wrapBusiness()}
            >
              {({data, loading, error}) => {

                if (loading) {
                  return (
                    <Container flex={1} margins="150px 0 0">
                      <ActivityIndicator />
                    </Container>
                  );
                }
                if (error) {
                  return (
                    <Container flex={1} margins="150px 0 0">
                      <Label>{localize(intl, messages.someError)}</Label>
                    </Container>
                  );
                }
                if (data.findBusinessTransactions && data.findBusinessTransactions.length > 0) {
                  let count = {};
                  data.findBusinessTransactions.forEach((record, i) => {
                    // console.log('transaction:', record);
                    const transaction = wrapBusiness({
                      compound: record.compound,
                      expirationDate: record.expirationDate,
                      scriptNumber: record.scriptNumber,
                      onHandQuantity: record.sum
                    })
                    if (count.productUnit && (record.productUnit.id === count.productUnit.id)) {
                      count.transactions.push(transaction)
                    } else {
                      if (count.productUnit) {
                        invAudit.counts.push(count);
                      }
                      count = {productUnit: record.productUnit, transactions: []};
                      count.transactions.push(transaction);
                    }
                    if (i + 1 === data.findBusinessTransactions.length) {
                      invAudit.counts.push(count);
                    }
                  });
                }

                return (
                  <SubInspect invAudit={invAudit} newRecord {...restProps} />
                )
              }}
            </Query>
          )

        } else {
          return <SubInspect invAudit={invAudit} {...restProps}/>;
        }
      }}
    </SessionQuery>
  );
};

export default withRouter(injectIntl(Inspect));
