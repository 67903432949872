/**
 * HomePage
 *
 * @format
 * @flow
 */

import React, { Fragment, useState } from "react";
import TeamsDialog from "../view/TeamsDialog";
import { defineMessages } from "react-intl";
import { BusinessesPage } from "cci-admin";

const messages = defineMessages({
  loginButtonTitle: {
    id: "loginButtonTitle",
    defaultMessage: "LOGIN"
  },
  viewUsersTitle: {
    id: "viewUsersTitle",
    defaultMessage: "View Users"
  },
  businessListTitle: {
    id: "manageTeams",
    defaultMessage: "Manage Teams"
  },
  usersListTitle: {
    id: "manageUsers",
    defaultMessage: "Manage Users"
  },
  businessInspectTitle: {
    id: "team",
    defaultMessage: "Team"
  },
  NBA: { defaultMessage: "NBA", id: "NBA" },
  MLB: { defaultMessage: "MLB", id: "MLB" },
  NHL: { defaultMessage: "NHL", id: "NHL" },
  NFL: { defaultMessage: "NFL", id: "NFL" },
  MLS: { defaultMessage: "MLS", id: "MLS" },
  proScripts: { defaultMessage: "Pro Scripts", id: "proScripts" },
  other: { defaultMessage: "Other", id: "other" }
});

/**
 * Replaces the Businesses Page imported on App.js
 */
const BusinessesTeamsPage = () => {
  const [visible, setVisible] = useState(false)
  const [idSelected, selectId] = useState(null)
  return (
    <Fragment>
      <BusinessesPage
        typesOptions={[
          messages.NBA,
          messages.MLB,
          messages.NHL,
          messages.NFL,
          messages.MLS,
          messages.proScripts,
          messages.other
        ]}
        listTitle={messages.businessListTitle}
        inspectTitle={messages.businessInspectTitle}
        link
        onLinkPress={setVisible}
        idSelected={idSelected}
        selectId={selectId}
        linkType="NBA"
      />

      <TeamsDialog
        isVisible={visible}
        onBackdropPress={() => setVisible(false)}
        onSelected={(id) => {
          setVisible(false)
          selectId(id)
        }}
        idSelected={idSelected}
      />
    </Fragment>
  );
};

export default BusinessesTeamsPage;
