import React from "react";
import { defineMessages, FormattedDate } from "react-intl";
import { ImmutableList, MutableList } from "cci-admin";
import { Container, LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { findInventoryUsesByNameQuery } from "../../query/InventoryUse";

const messages = defineMessages({
  searchInventoryUses: {
    id: "searchInventoryUses",
    defaultMessage: "Search by Name"
  },
  searchHystoryMedication: {
    id: "searchHystoryMedication",
    defaultMessage: "Search Dispensed History"
  },
  dispenseRecords: {
    id: "dispenseRecords",
    defaultMessage: "Dispense Records"
  },
  titleHistory: {
    id: "dispensedHistory",
    defaultMessage: "Dispensed History"
  }
});

/**
 * InventoryUses List page which allows searching.
 */
const List = ({ historical, ...props }) => {
  const ListComponent = historical ? ImmutableList : MutableList;
  const route = historical ? "inventoryuseshistory" : "inventoryuses";
  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <ListComponent
          {...props}
          listTitle={
            historical ? messages.titleHistory : messages.dispenseRecords
          }
          queryName="findInventoryUsesByName"
          query={findInventoryUsesByNameQuery}
          filterProperty="name"
          variables={wrapBusiness({ name: "" })}
          searchPlaceholder={
            historical
              ? messages.searchHystoryMedication
              : messages.searchInventoryUses
          }
          newUrl="/inventoryuses/new"
          recordRenderer={({ key, style }, rowData) => (
            <LinkedRow
              key={key}
              style={style}
              to={{
                pathname: `/${route}/${rowData.id}`,
                state: { accountId: rowData.id }
              }}
            >
              <Container
                margins="0 10px 0 0"
                flex={1}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Container width="69%">
                  <RowText style={{ textAlign: "left" }} numberOfLines={1}>
                    {rowData.patient.contact.firstName +
                      " " +
                      rowData.patient.contact.lastName}
                  </RowText>
                  {/* <RowText style={{ textAlign: "left" }} numberOfLines={1}>
                    {rowData.patient.type}
                    {/*player / staff/}
                  </RowText> */}
                </Container>

                <RowText style={{ textAlign: "left" }} width="31%">
                  <FormattedDate
                    value={rowData.ocurredOn}
                    year="numeric"
                    month="short"
                    day="2-digit"
                    timeZone="UTC"
                  />
                </RowText>
              </Container>
            </LinkedRow>
          )}
        />
      )}
    </SessionQuery>
  );
};

export default List;
