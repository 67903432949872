import React, { useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { Field } from "react-final-form";
import { format as formatFn, subYears } from "date-fns";
import { string as yupString } from "yup";
import { ReportOptions } from "cci-admin";
import {
  DateFormat,
  Error,
  FormCell,
  FormDateField,
  FormSelectRecordField,
  FormSelectValueField
} from "cci-component";
import { SessionQuery } from "cci-data";
import { localize } from "cci-utility";
import { findPatientsByNameQuery } from "../../query/Patient";

const messages = defineMessages({
  paymentType: {
    id: "paymentType",
    defaultMessage: "All Use"
  },
  from: {
    id: "from",
    defaultMessage: "From"
  },
  to: {
    id: "to",
    defaultMessage: "To"
  },
  personnel: {
    id: "personnel",
    defaultMessage: "Personnel"
  },
  payType: {
    id: "payType",
    defaultMessage: "Pay Type"
  },
  wrongFormat: {
    id: "wrongFormat",
    defaultMessage: "Wrong Date Format: yyyy-MM-dd"
  },
  view: {
    id: "view",
    defaultMessage: "View"
  }
});

const payTypeOptions = [
  { defaultMessage: "Worker's Compensation", id: "workers_compensation" },
  { defaultMessage: "Club Pay", id: "club_pay" },
  { defaultMessage: "Personnel Pay", id: "patient_pay" },
  { defaultMessage: "other", id: "other" },
];
const validation = (text, setFunction, intl) => {
  yupString()
    .required()
    .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, "wrongFormat")
    .validate(text, { abortEarly: false })
    .then(data => setFunction())
    .catch(err => {
      setFunction(
        localize(intl, {
          id:
            err.inner[0].type === "required"
              ? "requiredFieldError"
              : err.errors[0]
        })
      );
    });
};
/**
 * Options for PaymentType report.
 */
const Options = props => {
  const { intl } = props,
    [fromError, setFromError] = useState(null),
    [toError, setToError] = useState(null);

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <ReportOptions
          title={messages.paymentType}
          initialValues={{
            from: formatFn(subYears(new Date(), 1), "yyyy-MM-dd"),
            to: formatFn(new Date(), "yyyy-MM-dd"),
            businessId: wrapBusiness().business.id
          }}
          downloadFileType="pdf"
          previewButtonTitle={messages.view}
          {...props}
        >
          {/* From */}
          <Section>
            <FormCell size="stretch">
              <FormDateField
                name="from"
                label={messages.from}
                format={DateFormat.mediumDate}
                onChangeText={text => validation(text, setFromError, intl)}
                hideError
              />
              <Error>{fromError}</Error>
            </FormCell>
          </Section>

          {/* To */}
          <Section>
            <FormCell size="stretch">
              <FormDateField
                name="to"
                label={messages.to}
                format={DateFormat.mediumDate}
                onChangeText={text => validation(text, setToError, intl)}
                hideError
              />
              <Error>{toError}</Error>
            </FormCell>
          </Section>

          {/* Personnel */}
          <Section style={{ zIndex: 99 }}>
            <FormCell size="stretch" style={{ zIndex: 99 }}>
              <FormSelectRecordField
                query={findPatientsByNameQuery}
                enableServerSearch
                variables={wrapBusiness()}
                label={messages.personnel}
                displayLabel={option =>
                  option.contact.firstName +
                  " " +
                  option.contact.lastName
                }
                labelSearch={value =>
                  value && value.contact && value.contact.firstName
                }
                name="patientId"
                valueOnly
              />
            </FormCell>
          </Section>

          {/* PayType */}
          <Section>
            <FormCell size="stretch">
              <FormSelectValueField
                name="payType"
                label={messages.payType}
                options={payTypeOptions}
                clearable
              />
            </FormCell>
          </Section>

          {/* Business Id (Not displayed) */}
          <Field name="businessId">{() => null}</Field>
        </ReportOptions>
      )}
    </SessionQuery>
  );
};

export default injectIntl(Options);
