import React, { Fragment, PureComponent } from "react";
import {
  // Dimensions,
  View,
  ScrollView,
  Text,
  TouchableOpacity
} from "react-native";
import { defineMessages, injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components/native";
import { Field } from "react-final-form";
import { Mutation, Query } from "react-apollo";
import omit from "ramda/src/omit";
import { debounce } from "lodash";
import {
  Button,
  ConfirmButton,
  Container,
  containerCss,
  Dialog,
  Error,
  Icon,
  Inspect,
  Label,
  TextField,
  ToastContext
} from "cci-component";
import { SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import { localize } from "cci-utility";

const messages = defineMessages({
  searchByName: {
    id: "searchByName",
    defaultMessage: "Search By Name"
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete"
  },
  edit: {
    id: "edit",
    defaultMessage: "Edit"
  },
  confirmDelete: {
    id: "confirmDelete",
    defaultMessage: "Are you sure you want to delete this information?"
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company Name"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  type: {
    id: "type",
    defaultMessage: "Status"
  },
  work: {
    id: "work",
    defaultMessage: "Work"
  },
  personal: {
    id: "personal",
    defaultMessage: "Personal"
  },
  billing: {
    id: "billing",
    defaultMessage: "Billing"
  },
  home: {
    id: "home",
    defaultMessage: "Home"
  },
  previous: {
    id: "previous",
    defaultMessage: "Previous"
  },
  mobile: {
    id: "mobile",
    defaultMessage: "Mobile"
  },
  main: {
    id: "main",
    defaultMessage: "Main"
  },
  fax: {
    id: "fax",
    defaultMessage: "Fax"
  },
  emailAddress: {
    id: "emailAddress",
    defaultMessage: "E-mail Address"
  },
  internalNote: {
    id: "internalNote",
    defaultMessage: "Internal Note"
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  street1: {
    id: "street1",
    defaultMessage: "Street 1"
  },
  zipCode: {
    id: "zipCode",
    defaultMessage: "Zip Code"
  },
  state: {
    id: "state",
    defaultMessage: "State"
  },
  city: {
    id: "city",
    defaultMessage: "City"
  },
  country: {
    id: "country",
    defaultMessage: "Country"
  },
  number: {
    id: "number",
    defaultMessage: "Number"
  },
  extension: {
    id: "extension",
    defaultMessage: "Ext."
  },
  emptyList: {
    id: "emptyList",
    defaultMessage: "Empty List"
  },
  errorDeleting: {
    id: "errorDeleting",
    defaultMessage: "Error deleting"
  },
  cannotDeleteError: {
    id: "cannotDeleteError",
    defaultMessage:
      "This record cannot be deleted, this is most likely because the record is in use."
  },
  genericError: {
    id: "genericError",
    defaultMessage: "An error has occurred: {message}"
  }
});

const RoundedButton = styled(Button)`
  width: 20px;
  height: 20px;
`;

const RowWrapper = styled.View`
  ${props => props.theme.listRowStyle}
  ${containerCss}
`;

/* Round add button shown on the bottom left of the list */
const AddButton = styled(Button)`
  position: absolute;
  right: -10;
  bottom: -10;
  width: 40;
  height: 40;
`;

class CrudField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSearchFieldVisible: true,
      isUserListVisible: false,
      isUserInspectVisible: false,
      nameSearch: "",
      itemHeight: 250
    };

    this.filter = this.filter.bind(this);
    this.filterAndShow = this.filterAndShow.bind(this);
    this.debouncedShow = debounce(this.showUserList2.bind(this), 300);
    this.showUserList = this.showUserList.bind(this);
    this.blurSearch = this.blurSearch.bind(this);
    this.showSearchField = this.showSearchField.bind(this);
    this.showUserInspect = this.showUserInspect.bind(this);
    this.showNewUserInspect = this.showNewUserInspect.bind(this);
    this.selectAndShowUserInspect = this.selectAndShowUserInspect.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.saveInspect = this.saveInspect.bind(this);
    this.afterSaveInspect = this.afterSaveInspect.bind(this);
    this.onDeleteCompleted = this.onDeleteCompleted.bind(this);
    this.onSearchLayout = this.onSearchLayout.bind(this);
    this.onItemLayout = this.onItemLayout.bind(this);
    this.showButtons = this.showButtons.bind(this);
  }

  filter(nameSearch) {
    this.setState({ nameSearch });
  }

  filterAndShow(nameSearch) {
    this.filter(nameSearch);
    if (nameSearch) {
      this.debouncedShow();
    } else {
      this.showUserList();
      // !this.state.isUserListVisible
    }
  }

  blurSearch() {
    this.setState({
      isUserListVisible: false,
      buttonsVisibleIndex: -1
    });
  }

  showSearchField() {
    this.setState({
      isSearchFieldVisible: !this.state.isSearchFieldVisible
    });
  }

  showUserList2() {
    this.setState({
      isAddressListVisible: false,
      // nameSearch: this.state.isUserListVisible ?  "" : this.state.nameSearch,
      isUserListVisible: true
    });
  }

  showUserList() {
    this.setState({
      isAddressListVisible: false,
      // nameSearch: this.state.isUserListVisible ?  "" : this.state.nameSearch,
      isUserListVisible: !this.state.isUserListVisible,
      buttonsVisibleIndex: -1
    });
  }

  showUserInspect() {
    this.setState({
      isAddressListVisible: false,
      isUserInspectVisible: !this.state.isUserInspectVisible
    });
  }

  showNewUserInspect(form, defaultValues) {
    form.form.initialize(defaultValues);
    this.setState({
      isAddressListVisible: false,
      isUserInspectVisible: !this.state.isUserInspectVisible
    });
  }

  selectAndShowUserInspect(item, input, form) {
    form.form.initialize(item);
    // input.onChange(omit(["key"], item));
    this.setState({
      isUserListVisible: false,
      buttonsVisibleIndex: -1,
      isUserInspectVisible: true
    });
  }

  selectUser(item, input) {
    input.onChange(omit(["key"], item));
    this.setState({
      isUserListVisible: false,
      buttonsVisibleIndex: -1,
      nameSearch: ""
    });
  }

  saveInspect(form) {
    form.handleSubmit();
  }

  afterSaveInspect(savedData, input) {
    input.onChange(savedData);
    this.setState({
      isUserInspectVisible: false,
      isUserListVisible: false,
      nameSearch: ""
    });
  }

  onDeleteCompleted() {
    this.setState({
      nameSearch: "",
      buttonsVisibleIndex: -1,
      isUserListVisible: false
    });
  }

  onSearchLayout(e) {
    // TODO listToUp: is not working OK
    // if (this.heightRef) {
    //   this.heightRef.measureInWindow((x, y, width, height, pageX, pageY) => {
    this.setState({
      searchFieldWidth: e.nativeEvent.layout.width
      // listToUp: Dimensions.get('window').height - y < 330 ? true : false
    });
    //  })
    // }
  }

  onItemLayout(e, index) {
    this.setState({ itemHeight: e.nativeEvent.layout.height * (index + 1) });
  }

  showButtons(index) {
    this.setState({
      marginLeftList: index > -1 ? -115 : 0,
      buttonsVisibleIndex: index
    });
  }

  render() {
    const {
      intl,
      theme,
      label,
      title,
      queryById,
      queryByName,
      queryName,
      queryNameByName,
      entityName,
      objectWrapper,
      name,
      saveMutation,
      saveMutationName,
      deleteMutation,
      selectedDisplay,
      rowData,
      inspectContent,
      searchPlaceholder,
      hideSearchPlaceholder,
      onSelect = () => { },
      validationSchema,
      defaultValues = {},
      labelNumberofLines,
      listMargins = "5px 10px",
      topList,
      inspectProps,
      disabled,
      emptyMessage,
      onSearchFocus = () => { },
      onPressEdit,
      onPressNew,
      variablesByName,
      hidePlus = false
    } = this.props,
      {
        buttonsVisibleIndex,
        // isSearchFieldVisible,
        isUserInspectVisible,
        isUserListVisible,
        nameSearch,
        listToUp
      } = this.state,
      Title = props => (
        <Container {...props}>
          <Text style={{ color: "white", fontSize: 18 }}>
            {localize(intl, title)}
          </Text>
        </Container>
      );

    return (
      <Field name={name}>
        {({ input, meta }) => {
          return (
            <Inspect
              entityName={entityName}
              query={{
                query: queryById,
                skip: input.value === "",
                variables: input.value === "" ? null : { id: input.value.id }
              }}
              queryName={queryName}
              mutation={saveMutation}
              mutationName={saveMutationName}
              objectWrapper={objectWrapper}
              defaultValues={defaultValues}
              validationSchema={validationSchema}
              onCompleted={savedData => {
                this.afterSaveInspect(savedData, input);
                onSelect(savedData);
              }}
            >
              {(loading, qData, form) => (
                <Fragment>
                  <Container>
                    <Container
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      {/* Field Label */}
                      <Label
                        margins="0 0 5px 0"
                        numberOfLines={labelNumberofLines}
                      >
                        {localize(intl, label)}
                      </Label>
                      {qData &&
                        qData[queryName] &&
                        !isUserListVisible &&
                        !disabled && (
                          <Container
                            flexDirection="row"
                            width="50px"
                            justifyContent="space-between"
                          >
                            <RoundedButton
                              ui="miniRound"
                              leftIcon="search"
                              onPress={this.showUserList}
                            />
                            <RoundedButton
                              ui="miniRound"
                              leftIcon="pencil-alt"
                              onPress={() => {
                                onPressEdit
                                  ? onPressEdit(form)
                                  : this.selectAndShowUserInspect(
                                    qData[queryName],
                                    input,
                                    form
                                  )
                              }}
                            />
                          </Container>
                        )}
                    </Container>

                    <Fragment>
                      {qData && qData[queryName] && !isUserListVisible ? (
                        selectedDisplay(qData[queryName])
                      ) : (
                          <View
                            ref={view => {
                              this.heightRef = view;
                            }}
                            onLayout={this.onSearchLayout}
                          >
                            <TextField
                              onFocus={onSearchFocus}
                              rightComponent={
                                <Icon
                                  name="search"
                                  color={theme.fieldFontColor || theme.fontColor}
                                  style={{ marginRight: 10 }}
                                />
                              }
                              onChangeText={text => this.filterAndShow(text)}
                              placeholder={
                                hideSearchPlaceholder
                                  ? null
                                  : searchPlaceholder || messages.searchByName
                              }
                              value={nameSearch}
                              disabled={disabled}
                              // onBlur={this.blurSearch}
                            />
                          </View>
                        )}
                      {(meta.error || meta.submitError) && (
                        <Error>{meta.error || meta.submitError}</Error>
                      )}
                    </Fragment>
                  </Container>

                  {/* Entity List */}
                  {isUserListVisible && nameSearch && (
                    <Container
                      margins={listMargins}
                      width={this.state.searchFieldWidth}
                      height={this.state.itemHeight}
                      style={{
                        maxHeight: 250,
                        position: "absolute",
                        left: 0,
                        top: !listToUp
                          ? topList || theme.fieldMinHeight + 15
                          : null,
                        bottom: listToUp
                          ? topList || theme.fieldMinHeight + 25
                          : null,
                        borderColor: "#e0ecff",
                        boxShadow: "#cad3da 0px 5px 15px -5px",
                        // shadowOffset: {  width: 5,  height: 5,  },
                        // shadowColor: '#cad3da',
                        // shadowOpacity: 0.1,
                        borderRadius: theme.fieldBorderRadius,
                        borderWidth: 1,
                        zIndex: 100,
                        backgroundColor: "white"
                      }}
                    >
                      <Fragment>
                        <SessionQuery>
                          {({ wrapBusiness }) => (
                            <Query
                              query={queryByName}
                              skip={!nameSearch}
                              variables={{
                                ...wrapBusiness(),
                                name: nameSearch,
                                startIndex: 0,
                                stopIndex: 10,
                                ...variablesByName
                              }}
                              fetchPolicy="cache-and-network"
                            >
                              {({
                                loading: loadingList,
                                data: dataList,
                                error
                              }) => (
                                <Fragment>
                                  {dataList &&
                                    dataList[queryNameByName] &&
                                    dataList[queryNameByName].content &&
                                    dataList[queryNameByName].content.length >
                                    0 ? (
                                      <ScrollView
                                        contentContainerStyle={{ flex: 1 }}
                                      >
                                        {dataList[queryNameByName].content.map(
                                          (dataI, index) => {
                                            const data = { item: dataI, index };
                                            return (
                                              <Container
                                                flexDirection="row"
                                                key={dataI.id}
                                                onLayout={e =>
                                                  this.onItemLayout(
                                                    e,
                                                    dataList[queryNameByName]
                                                      .length
                                                  )
                                                }
                                              >
                                                <RowWrapper
                                                  flexDirection="row"
                                                  style={{
                                                    backgroundColor:
                                                      data.item.id === qData &&
                                                        qData[queryName] &&
                                                        qData[queryName].id
                                                        ? "#afcae6"
                                                        : "white",
                                                    borderRadius:
                                                      theme.fieldBorderRadius,
                                                    width: "100%",
                                                    borderColor: "white",
                                                    marginLeft:
                                                      buttonsVisibleIndex ===
                                                        data.index
                                                        ? this.state
                                                          .marginLeftList
                                                        : 0
                                                  }}
                                                >
                                                  <TouchableOpacity
                                                    onPress={() => {
                                                      this.selectUser(
                                                        data.item,
                                                        input
                                                      );
                                                      onSelect(data.item);
                                                    }}
                                                    style={{ flex: 1 }}
                                                  >
                                                    {rowData(data)}
                                                  </TouchableOpacity>
                                                  <TouchableOpacity
                                                    style={{
                                                      backgroundColor:
                                                        "transparent",
                                                      borderRadius:
                                                        theme.fieldBorderRadius,
                                                      width: 20,
                                                      alignItems: "flex-end",
                                                      justifyContent: "center"
                                                    }}
                                                    onPress={() =>
                                                      this.showButtons(
                                                        buttonsVisibleIndex ===
                                                          data.index
                                                          ? -1
                                                          : data.index
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      iconSize={20}
                                                      color="#0054a4"
                                                      name={
                                                        buttonsVisibleIndex ===
                                                          data.index
                                                          ? "chevron-right"
                                                          : "chevron-left"
                                                      }
                                                    />
                                                  </TouchableOpacity>
                                                </RowWrapper>

                                                {buttonsVisibleIndex ===
                                                  data.index && (
                                                    <Container
                                                      flexDirection="row"
                                                      justifyContent="flex-end"
                                                      alignItems="stretch"
                                                      width={115}
                                                    >
                                                      <SecuredComponent
                                                        checkPermissions={{
                                                          objects: entityName,
                                                          actions: ["delete"]
                                                        }}
                                                      >
                                                        <ToastContext.Consumer>
                                                          {toast => (
                                                            <Mutation
                                                              mutation={
                                                                deleteMutation
                                                              }
                                                              variables={{
                                                                [objectWrapper]: {
                                                                  id: dataI.id,
                                                                  version:
                                                                    dataI.version
                                                                }
                                                              }}
                                                              refetchQueries={[
                                                                queryName,
                                                                queryNameByName
                                                              ]}
                                                              onCompleted={
                                                                this
                                                                  .onDeleteCompleted
                                                              }
                                                              onError={errors => {
                                                                console.log(
                                                                  "console.error();",
                                                                  errors
                                                                );
                                                                if (
                                                                  errors
                                                                    .toString()
                                                                    .indexOf(
                                                                      "500"
                                                                    ) > -1
                                                                ) {
                                                                  // TODO This is an unsafe way to check if we have a foreign key constraint issue, this must be solved when DGV-109 is addressed
                                                                  toast.error(
                                                                    messages.cannotDeleteError
                                                                  );
                                                                } else {
                                                                  // A problem occurred delete the record
                                                                  toast.error(
                                                                    messages.genericError,
                                                                    {
                                                                      message: errors
                                                                    }
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {deleteHandler => (
                                                                <ConfirmButton
                                                                  title={
                                                                    messages.delete
                                                                  }
                                                                  message={
                                                                    messages.confirmDelete
                                                                  }
                                                                  ui="swipe"
                                                                  onConfirm={
                                                                    deleteHandler
                                                                  }
                                                                />
                                                              )}
                                                            </Mutation>
                                                          )}
                                                        </ToastContext.Consumer>
                                                      </SecuredComponent>
                                                      <Button
                                                        title={messages.edit}
                                                        backgroundColor="#2ecc71" //41.197.102
                                                        ui="swipe"
                                                        onPress={() => {
                                                          onPressEdit
                                                            ? onPressEdit(form, dataI, index)
                                                            : this.selectAndShowUserInspect(
                                                              data.item,
                                                              input,
                                                              form
                                                            )
                                                        }}
                                                      />
                                                    </Container>
                                                  )}
                                              </Container>
                                            );
                                          }
                                        )}
                                      </ScrollView>
                                    ) : (
                                      ((!loadingList || error) && (
                                        <Container
                                          paddings="20px"
                                          onLayout={e => this.onItemLayout(e, 0)}
                                        >
                                          <Label color="#0054a4">
                                            {error
                                              ? JSON.stringify(error)
                                              : localize(
                                                intl,
                                                emptyMessage ||
                                                messages.emptyList
                                              )}
                                          </Label>
                                        </Container>
                                      )) ||
                                      null
                                    )}
                                  {!hidePlus && <AddButton
                                    ui="round"
                                    leftIcon="plus"
                                    onPress={() => {
                                      onPressNew
                                      ? onPressNew(form)
                                        : this.showNewUserInspect(
                                          form,
                                          defaultValues
                                        );
                                    }}
                                  />}
                                </Fragment>
                              )}
                            </Query>
                          )}
                        </SessionQuery>
                      </Fragment>
                    </Container>
                  )}

                  {/* Entity Inspect */}
                  <Dialog
                    isVisible={isUserInspectVisible}
                    paddings="20px"
                    onBackdropPress={this.showUserInspect}
                    {...inspectProps}
                  >
                    <Container flex={1}>
                      <ScrollView>
                        <Title margins="5px 10px" />

                        {inspectContent(form)}
                      </ScrollView>

                      <Container
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        margins="0 10px"
                      >
                        <Button
                          title={messages.cancel}
                          ui="dangerLink"
                          onPress={this.showUserInspect}
                        />
                        <Button
                          title={messages.save}
                          disabled={form.pristine}
                          onPress={() => this.saveInspect(form)}
                        />
                      </Container>
                    </Container>
                  </Dialog>
                </Fragment>
              )}
            </Inspect>
          );
        }}
      </Field>
    );
  }
}

export default injectIntl(withTheme(CrudField));
