/**
 * OrdersPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import { withRouter } from "cci-utility";
import List from "../view/order/List";
import Inspect from "../view/order/Inspect";

const messages = defineMessages({
  ordersPlaceholder: {
    id: "ordersPlaceholder",
    defaultMessage:
      "Select an order from the left to view or edit.\n\n" +
      "Select + to create new order."
  },
  sentOrdersPlaceholder: {
    id: "sentOrdersPlaceholder",
    defaultMessage: "Select an order from the left to view or edit."
  },
  deliveredOrdersPlaceholder: {
    id: "deliveredOrdersPlaceholder",
    defaultMessage: "Select an order from the left to fulfill or view."
  }
});

const OrdersPage = ({ orderPage, location }) => {
  const Wrapper = location.pathname === "/" ? React.Fragment : PrimaryPage;
  let route = "orders",
    message = messages.ordersPlaceholder;

  if (orderPage === "admin") {
    route = "adminorders";
    message = messages.deliveredOrdersPlaceholder;
  } else if (orderPage === "saved") {
    route = "savedorders";
  } else if (orderPage === "sent") {
    route = "sentorders";
    message = messages.sentOrdersPlaceholder;
  }

  return (
    <Wrapper>
      <ListInspect
        rootPath={`/${route}`}
        left={List}
        placeholderMessage={message}
        selectionPath={`/${route}/:id`}
        right={Inspect}
        leftRightOptions={{ orderPage }}
      />
    </Wrapper>
  );
};

export default withRouter(OrdersPage);
