import React, { useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { Field } from "react-final-form";
import { format as formatFn, subYears } from "date-fns";
import { string as yupString } from "yup";
import { ReportOptions } from "cci-admin";
import { DateFormat, Error, FormCell, FormDateField } from "cci-component";
import { SessionQuery } from "cci-data";
import { localize } from "cci-utility";

const messages = defineMessages({
  expiredInventory: {
    id: "expiredInventory",
    defaultMessage: "Expired Inventory"
  },
  from: {
    id: "from",
    defaultMessage: "From"
  },
  to: {
    id: "to",
    defaultMessage: "To"
  },
  view: {
    id: "view",
    defaultMessage: "View"
  }
});

const validation = (text, setFunction, intl) => {
  yupString()
    .required()
    .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, "wrongFormat")
    .validate(text, { abortEarly: false })
    .then(data => setFunction())
    .catch(err => {
      setFunction(
        localize(intl, {
          id:
            err.inner[0].type === "required"
              ? "requiredFieldError"
              : err.errors[0]
        })
      );
    });
};

/**
 * Options for Expired Inventory report.
 */
const Options = props => {
  const { intl } = props,
    [fromError, setFromError] = useState(null),
    [toError, setToError] = useState(null);

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <ReportOptions
          title={messages.expiredInventory}
          initialValues={{
            businessId: wrapBusiness().business.id,
            from: formatFn(subYears(new Date(), 1), "yyyy-MM-dd"),
            to: formatFn(new Date(), "yyyy-MM-dd")
          }}
          downloadFileType="pdf"
          previewButtonTitle={messages.view}
          {...props}
        >
          {/* From */}
          <Section>
            <FormCell size="stretch">
              <FormDateField
                name="from"
                label={messages.from}
                format={DateFormat.mediumDate}
                onChangeText={text => validation(text, setFromError, intl)}
                hideError
              />
              <Error>{fromError}</Error>
            </FormCell>
          </Section>

          {/* To */}
          <Section>
            <FormCell size="stretch">
              <FormDateField
                name="to"
                label={messages.to}
                format={DateFormat.mediumDate}
                onChangeText={text => validation(text, setToError, intl)}
                hideError
              />
              <Error>{toError}</Error>
            </FormCell>
          </Section>

          {/* Business Id (Not displayed) */}
          <Field name="businessId">{() => null}</Field>
        </ReportOptions>
      )}
    </SessionQuery>
  );
};

export default injectIntl(Options);
