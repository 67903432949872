import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { ScrollView, ActivityIndicator } from "react-native";
import { Query } from "react-apollo";
import { Container, Dialog, TouchableRow, RowText } from "cci-component";
import { localize } from "cci-utility";
import { TeamsQuery } from "../query/Team";

const messages = defineMessages({
  teamsLinking: {
    id: "teamsLinking",
    defaultMessage: "Teams linking"
  },
  errorHappened: {
    id: "errorHappened",
    defaultMessage: "An error happened. Please try again later."
  }
});

/**
 * Modal dialog to with list of Teams from NBA API to link to.
 * 
 * Props:
 * - onSelected: Function that is called when the user taps one of the businesses in the list.
 */
const TeamsDialog = props => (
  <Dialog title={messages.teamsLinking} width={.5} height={.7} {...props}>
    <Query query={TeamsQuery}>
      {({ loading, error, data, client }) => (
        <ScrollView>
          {loading && <ActivityIndicator />}

          {!loading && error && <RowText>{localize(props.intl,messages.errorHappened)}</RowText>}

          {!loading && data && data.teams && data.teams.map(team => (
            <TouchableRow
              key={team.id}
              style={props.idSelected === team.id ? {backgroundColor: "#0054d4"} : {}}
              onPress={() => props.onSelected(team.id)}
            >
              <Container flexDirection="row">
                <RowText width={60}>{team.id}</RowText>
                <RowText flex={1}>{team.name}</RowText>
              </Container>
            </TouchableRow>
          ))}
        </ScrollView>
      )}
    </Query>
  </Dialog>
)

export default injectIntl(TeamsDialog);