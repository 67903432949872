import React from "react";
import { defineMessages } from "react-intl";
import { MutableList } from "cci-admin";
import { LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { findProductsByNameQuery } from "../../query/Product";

const messages = defineMessages({
  searchProducts: {
    id: "searchProducts",
    defaultMessage: "Search Products"
  },
  title: { id: "manageProducts", defaultMessage: "Manage Products" },
});

/**
 * Product List page which allows searching.
 */
const List = props => (
  <SessionQuery>
    {({ wrapBusiness }) => (
      <MutableList
        {...props}
        listTitle={messages.title}
        queryName="findProductsByName"
        query={findProductsByNameQuery}
        filterProperty="name"
        variables={wrapBusiness({ name: "" })}
        searchPlaceholder={messages.searchProducts}
        newUrl="/products/new"
        recordRenderer={({ key, style }, rowData) => (
          <LinkedRow
            key={key}
            style={style}
            to={{
              pathname: `/products/${rowData.id}`,
              state: { accountId: rowData.id }
            }}
          >
            <RowText style={{ textAlign: "left" }} flex={1}>
              {rowData.name}
            </RowText>
          </LinkedRow>
        )}
      />
    )}
  </SessionQuery>
);

export default List;
