import React from "react";
import { defineMessages, FormattedDate, injectIntl } from "react-intl";
import { withTheme } from "styled-components/native";
import { ImmutableList, MutableList } from "cci-admin";
import { Container, LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { localize, withRouter } from "cci-utility";
import { findCustomerOrdersByKeywordQuery } from "../../query/CustomerOrder";

const messages = defineMessages({
  searchOrders: { id: "searchOrders", defaultMessage: "Search Orders" },
  savedTitle: { id: "savedTitle", defaultMessage: "Saved Orders" },
  sentTitle: { id: "sentTitle", defaultMessage: "Sent Orders" },
  ordersTitle: { id: "ordersTitle", defaultMessage: "View Orders" },
  newTitle: { id: "newTitle", defaultMessage: "Create a New Order" },

  saved: { id: "saved", defaultMessage: "Saved" },
  open: { id: "open", defaultMessage: "Pending" },
  sent: { id: "sent", defaultMessage: "Sent" },
  submitted: { id: "submitted", defaultMessage: "Submitted" },
  delivered: { id: "delivered", defaultMessage: "Fulfilled" }
});

/**
 * Order List page which allows to searching.
 * If props.orderPage === "admin" then this is an Admin Order List
 */
const List = ({ orderPage, intl, to, location, theme, ...props }) => {
  let route, ListComponent, listTitle;

  if (orderPage === "admin") {
    route = "adminorders";
    ListComponent = ImmutableList;
    listTitle = messages.ordersTitle;
  } else if (orderPage === "sent") {
    route = "sentorders";
    ListComponent = ImmutableList;
    listTitle = messages.sentTitle;
  } else if (orderPage === "saved") {
    route = "savedorders";
    ListComponent = MutableList;
    listTitle = messages.savedTitle;
    if (location.pathname.includes("new")) {
      listTitle = messages.newTitle;
    }
  }

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <ListComponent
          {...props}
          listTitle={listTitle}
          queryName="findCustomerOrdersByKeyword"
          query={findCustomerOrdersByKeywordQuery}
          filterProperty="name"
          variables={wrapBusiness({
            name: "",
            // We send "i" as a filter in status beacause we do not want "saved" orders
            status: orderPage === "admin" || orderPage === "sent" ? "i" : orderPage
          })}
          fetchPolicy="cache-and-network"
          searchPlaceholder={messages.searchOrders}
          newUrl={`/${route}/new`}
          autoupdate={orderPage === "admin"}
          recordRenderer={({ key, style }, rowData) => {
            let deliveredColor = theme.fontColor;

            if (orderPage === "admin" && rowData.status !== "submitted") {
              deliveredColor = "#799be9";
            }

            if (orderPage === "sent" && rowData.status !== "submitted") {
              deliveredColor = "#0067c1";
            }

            const RowTextList = ({ children, flex }) => (
              <RowText flex={flex} color={deliveredColor}>
                {children}
              </RowText>
            );

            return (
              <LinkedRow
                key={key}
                style={style}
                to={{
                  pathname: `/${route}/${rowData.id}`,
                  state: { accountId: rowData.id }
                }}
              >
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Container flex={2}>
                    <Container flexDirection="row">
                      <RowTextList flex={0.8}>
                        <FormattedDate
                          value={rowData.deliveryDate}
                          timeZone="UTC"
                        />
                      </RowTextList>
                      <RowTextList flex={1.3}>{rowData.number}</RowTextList>
                    </Container>
                    {orderPage === "admin" && (
                      <RowTextList>
                        {"by " +
                          (rowData.business.contact.companyName ||
                            rowData.business.contact.firstName)}
                      </RowTextList>
                    )}
                  </Container>

                  <RowTextList style={{ textAlign: "left" }} flex={0.8}>
                    {localize(
                      intl,
                      messages[
                        orderPage === "admin" && rowData.status === "submitted"
                          ? "open"
                          : rowData.status
                      ]
                    )}
                  </RowTextList>
                </Container>
              </LinkedRow>
            );
          }}
        />
      )}
    </SessionQuery>
  );
};

export default injectIntl(withRouter(withTheme(List)));
