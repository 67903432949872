/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import { Platform } from "react-native";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "styled-components/native";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
// These are needed so that date formats work correctly on Android
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/es";
// End of these are needed so that date formats work correctly on Android
import { defineMessages } from "react-intl";
import { initApollo } from "cci-logic";
import {
  ApplicationUsersPage,
  LoginPage,
  UserGroupsPage,
  ResetPasswordPage
} from "cci-admin";
import { AppBase } from "cci-component";
import { Route } from "cci-utility";
import SubmittedAddressesPage from "./pages/SubmittedAddressesPage";
import InventoryUseReportPage from "./pages/InventoryUseReportPage";
import PaymentTypeReportPage from "./pages/PaymentTypeReportPage";
import InventoryOnHandReportPage from "./pages/InventoryOnHandReportPage";
import CertificateReportPage from "./pages/CertificateReportPage";
import ExpiredInventoryReportPage from "./pages/ExpiredInventoryReportPage";
import HomePage from "./pages/HomePage";
import OrdersPage from "./pages/OrdersPage";
import PatientsPage from "./pages/PatientsPage";
import ProductsPage from "./pages/ProductsPage";
import InventoryUsesPage from "./pages/InventoryUsesPage";
import OutsideInventoryPage from "./pages/OutsideInventoryPage";
import TeamGroupsPage from "./pages/TeamGroupsPage";
// import InventoryPage from "./pages/InventoryPage";
import MinimumAlertsPage from "./pages/MinimumAlertsPage";
import PhysicalCountsPage from "./pages/PhysicalCountsPage";
import loadLocales from "./loadLocales";
import pageBackgroundUrl from "./assets/images/bg.jpg";
import logoUrl from "./assets/images/logo-one-team.png";
import BusinessesTeamsPage from "./pages/BusinessesTeamsPage";

// Uncomment this code to log the rendering details
// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update')
//   whyDidYouUpdate(React, { exclude: [/\bYellowBoxListRow\b/, /\bTouchableText\b/, /\bYellowBox\b/, /\bYellowBoxList\b/, /\bCellRenderer\b/] })
// }

const client = initApollo({
  appUrl: "https://ws.proscriptsrx.com",
  // appUrl: "http://192.168.100.45:5000",
  imageResizerUrl: "https://md.proscriptsrx.com",
  defaults: {
    MainMenu: {
      __typename: "MainMenu",
      visible: false,
      items: [
        {
          __typename: "MenuTitle",
          leftIcon: "copy",
          title: "reports",
          defaultMessage: "Reports",
          collapsible: true,
          expanded: true,
          checkPermissions: {
            __typename: "Permission",
            objects: "Report",
            actions: "read"
          },
          children: [
            {
              __typename: "MenuItem",
              leftIcon: "file-signature",
              text: "inventoryOnHandReport",
              defaultMessage: "Inventory On-Hand Report",
              url: "/inventoryonhandreport",
              checkPermissions: {
                __typename: "Permission",
                objects: "Report",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-signature",
              text: "expiredInventoryReport",
              defaultMessage: "Expired Inventory Report",
              url: "/expiredinventoryreport",
              checkPermissions: {
                __typename: "Permission",
                objects: "Report",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-signature",
              text: "inventoryUseReport",
              defaultMessage: "Inventory Use Reports",
              url: "/inventoryusereport",
              checkPermissions: {
                __typename: "Permission",
                objects: "Report",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-signature",
              text: "paymentTypeReport",
              defaultMessage: "All Use Reports",
              url: "/paymenttypereport",
              checkPermissions: {
                __typename: "Permission",
                objects: "Report",
                actions: "read"
              }
            }
          ]
        },

        {
          __typename: "MenuTitle",
          leftIcon: "eye",
          title: "accountManagement",
          defaultMessage: "Account Management",
          collapsible: true,
          expanded: true,
          checkPermissions: {
            __typename: "Permission",
            objects: "Management",
            actions: "read"
          },
          children: [
            {
              __typename: "MenuItem",
              leftIcon: "user-check",
              text: "personnelManagement",
              defaultMessage: "Personnel Management",
              url: "/personnel",
              checkPermissions: {
                __typename: "Permission",
                objects: "Patient",
                actions: "create"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-plus",
              text: "createOrder",
              defaultMessage: "Create a New Order",
              url: "/savedorders/new",
              checkPermissions: {
                __typename: "Permission",
                objects: "CustomerOrder",
                actions: "create"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-alt",
              text: "savedOrders",
              defaultMessage: "Manage Saved Orders",
              url: "/savedorders",
              checkPermissions: {
                __typename: "Permission",
                objects: "ViewOrder",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-export",
              text: "manageSentOrders",
              defaultMessage: "View Sent Orders",
              url: "/sentorders",
              checkPermissions: {
                __typename: "Permission",
                objects: "ViewOrder",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "prescription-bottle-alt",
              text: "dispenseRecords",
              defaultMessage: "Dispense Records",
              url: "/inventoryuses/new",
              checkPermissions: {
                __typename: "Permission",
                objects: "InventoryUse",
                actions: "create"
              }
            },

            // {
            //   __typename: "MenuItem",
            //   leftIcon: "warehouse-alt",
            //   text: "dispensedHistory",
            //   defaultMessage: "Dispensed History",
            //   url: "/inventoryuseshistory",
            //   checkPermissions: {
            //     __typename: "Permission",
            //     objects: "InventoryUse",
            //     actions: "read,update"
            //   }
            // },

            {
              __typename: "MenuItem",
              leftIcon: "abacus",
              text: "inventoryAudit",
              defaultMessage: "Inventory Audit",
              url: "/physicalcounts",
              checkPermissions: {
                __typename: "Permission",
                objects: "PhysicalCount",
                actions: "read"
              }
            },

            // {
            //   __typename: "MenuItem",
            //   leftIcon: "inventory",
            //   text: "inventoryOnHand",
            //   defaultMessage: "Inventory On-Hand",
            //   url: "/inventory",
            //   checkPermissions: {
            //     __typename: "Permission",
            //     objects: "OfficeProduct",
            //     actions: "read"
            //   }
            // },

            {
              __typename: "MenuItem",
              leftIcon: "inventory",
              text: "minimumInventoryAlerts",
              defaultMessage: "Minimum Inventory Alerts",
              url: "/minimumalerts",
              checkPermissions: {
                __typename: "Permission",
                objects: "OfficeProduct",
                actions: "update"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "external-link-alt",
              text: "outsideInventory",
              defaultMessage: "Outside Inventory",
              url: "/outsideinventory",
              checkPermissions: {
                __typename: "Permission",
                objects: "OutsideInventory",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "user",
              text: "viewUsers",
              defaultMessage: "View Users",
              url: "/viewusers",
              checkPermissions: {
                __typename: "Permission",
                objects: "ViewUser",
                actions: "read"
              }
            }
          ]
        },

        {
          __typename: "MenuTitle",
          leftIcon: "prescription-bottle-alt",
          title: "adminMenu",
          defaultMessage: "Admin Menu",
          collapsible: true,
          expanded: true,
          checkPermissions: {
            __typename: "Permission",
            objects: "Administrator",
            actions: "read"
          },
          children: [
            {
              __typename: "MenuItem",
              leftIcon: "file-plus",
              text: "createOrder",
              defaultMessage: "Create a New Order",
              url: "/savedorders/new",
              checkPermissions: {
                __typename: "Permission",
                objects: "CustomerOrder",
                actions: "create"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "file-alt",
              text: "viewOrders",
              defaultMessage: "View Orders",
              url: "/adminorders",
              checkPermissions: {
                __typename: "Permission",
                objects: "CustomerOrder",
                actions: "update"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "building",
              text: "manageTeams",
              defaultMessage: "Manage Teams",
              url: "/businesses",
              checkPermissions: {
                __typename: "Permission",
                objects: "Business",
                actions: "read,create,update"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "sitemap",
              text: "manageTeamGroups",
              defaultMessage: "Manage Team Groups",
              url: "/teamgroups",
              checkPermissions: {
                __typename: "Permission",
                objects: "TeamGroup",
                actions: "read,create,update"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "user",
              text: "manageUsers",
              defaultMessage: "Manage Users",
              url: "/users",
              checkPermissions: {
                __typename: "Permission",
                objects: "ApplicationUser",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "capsules",
              text: "manageProducts",
              defaultMessage: "Manage Products",
              url: "/products",
              checkPermissions: {
                __typename: "Permission",
                objects: "Product",
                actions: "read"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "users-class",
              text: "personnelManagement",
              defaultMessage: "Personnel Management",
              url: "/personnelteam",
              checkPermissions: {
                __typename: "Permission",
                objects: "Patient",
                actions: "update"
              }
            },

            {
              __typename: "MenuItem",
              leftIcon: "envelope",
              text: "manageAddresses",
              defaultMessage: "Manage Email Addresses",
              url: "/submittedemails",
              checkPermissions: {
                __typename: "Permission",
                objects: "Setting",
                actions: "update"
              }
            }
          ]
        }
      ]
    }
  },

  linksModifier: ({ links }) => [
    links.stateLink,
    links.cleanKeysLink,
    links.authLink,
    links.reauthorizeLink,
    links.reconnectServerLink,
    links.errorLink,
    // split(
    //   // split based on operation type
    //   ({ query }) => {
    //     const { kind, operation } = getMainDefinition(query);
    //     return kind === "OperationDefinition" && operation === "subscription";
    //   },
    //   wsLink,
    links.serverLink
    // )
  ]
});

const authenticationTheme = {
  pageBackgroundUrl,
  pageBackgroundColor: "#d0d0d0",
  // pageBackgroundOpacity: 0.4,
  logoStyle: "width: 310px; height: 100px; margin-bottom: 50px;",
  logoUrl,
  fontFamily: "Helvetica",
  fontSize: 15,
  fontWeight: "normal",
  fontColor: "#0054a4",
  dialogBackgroundColor: "#233852",
  // mainMenuIconSize: 18,
  // mainMenuItemLeftPadding: 28,

  // These fields are used in BaseField and SelectField instead of fieldStyle
  fieldBorderTopWidth: 0,
  fieldBorderRightWidth: 0,
  fieldBorderBottomWidth: 2,
  fieldBorderLeftWidth: 0,
  fieldBorderColor: "#a2a2d2",
  fieldBackgroundColor: "#0054a4",
  fieldBorderRadius: 5,

  // fieldStyle: "border: 1px solid #fff; border-radius: 5px;",
  /* {
    border: "1px solid #A5A5A5",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.25)"
  }, */
  fieldStyle:
    "border-bottom-width: 0px; border-style: solid; border-bottom-color: transparent; height: 35px",
  fieldPadding: "5px 15px",
  fieldMinHeight: 35,
  fieldIconColor: "white",
  fieldFontColor: "white",
  fieldErrorColor: "#ff5252",
  placeholderColor: "#a2a2d2",
  // placeholderImageBigSize: 400,
  // placeholderImageLittleSize: 200,

  successToast: {
    fontColor: "#000",
    backgroundColor: "rgba(51, 217, 178, 0.3)"
  },

  warningToast: {
    fontColor: "#000",
    backgroundColor: "rgba(255, 255, 10, 0.7)"
  },

  errorToast: {
    fontColor: "#fff",
    backgroundColor: "rgba(255, 82, 82, 0.5)"
  },

  button: {
    primary: {
      borderRadius: "30px",
      border: "2px solid #d61e3a",
      backgroundColor: "#d61e3a",
      fontColor: "white",
      iconSize: 15,
      padding: "20px 53px",

      disabled: {
        fontColor: "#356ca2",
        border: "2px solid #d61e3a"
      }
    },

    link: {
      fontSize: 12,
      letterSpacing: 0.5,

      disabled: {
        fontColor: "#356ca2"
      }
    }
  }
};

const primaryTheme = {
  pageBackgroundUrl,
  pageBackgroundColor: "white",
  pagePadding: 30,
  pageContentMarginTop: 110,
  logoStyle: "width: 230px; height: 75px;",
  logoUrl,
  fontFamily: "Helvetica",
  fontSize: 14,
  fontColor: "#0054a4",
  disabledFontColor: "#dcdcdc",
  secondaryColor: "transparent",
  overlayColor: "white",
  mainMenuColor: "rgba(255, 255, 255, 1)",
  mainMenuShadow: "0px 0px 15px #000",
  // mainMenuIconWeight: 900,

  // titleInspectUppercase: false,
  // titleInspectSpacing: 1,
  // subtitleInspectSpacing: 1,

  dialogBackgroundColor: "#0d4c8c", // rgba(18, 135, 235, 0.9)", //"#cbdaea", //196.213.231
  dialogFontColor: "white",

  dialog: {
    fieldBackgroundColor: "white",
    fieldIconColor: "#0054a4",
    fieldFontColor: "#0054a4",
    selectFontColor: "#0054a4",
    fontColor: "white"
  },

  calendarMonthColor: "#b2c4d6",
  calendarDayColor: "#889aad",
  calendarTitleColor: "#b2c4d6",
  sidebarWidth: 400,
  hideCciLogo: true,
  hideUserInspectDocument: true,
  hideUserInspectPhoto: true,
  hideUserInspectFastLogin: true,
  hide2FACheckbox: false,
  // hideBusinessInspectPhoto: true,
  // hideUserBusinesses: true,
  emailNewUser: true,
  renderNewOnButton: true,
  logoutOnUserInfo: true,

  indicatorSeparator: false,
  indicatorWidth: 60,
  contentDefaultWidth: 100,
  letterSize: 7,
  // These fields are used in BaseField and SelectField instead of fieldStyle
  fieldBorderTopWidth: 1,
  fieldBorderRightWidth: 1,
  fieldBorderBottomWidth: 1,
  fieldBorderLeftWidth: 1,
  fieldBorderColor: "#a2a2d2",
  fieldBackgroundColor: "#0054a4",
  fieldBorderRadius: 4,

  inspectPercentage: "90%",
  inspectTitleBottomMargin: 20,
  titleNegativeMargin: 1,

  // fieldStyle: "border: 1px solid #fff; border-radius: 5px;",
  fieldStyle:
    "border-bottom-width: 0px; border-style: solid; border-bottom-color: transparent; height: 35px",
  // "border: 1px solid #A5A5A5; border-radius: 4px; background-color: rgba(255, 255, 255, 0.25);",

  fieldPadding: "8px",
  // fieldMinHeight: 35,
  fieldIconColor: "white",
  fieldFontColor: "white",
  fieldErrorColor: "#ff5252",
  placeholderColor: "#d2d0d0",
  widePlaceholderFontColor: "#0054a4",
  // widePlaceholderFontSize: 15,
  // widePlaceholderIconSize: 40,
  widePlaceholderMargins: "0 10%",

  roundImageBorder: "1px solid #fff",
  avatarShadow: "0px 0px 20px transparent",
  avatarSelected: "business",
  avatarSize: 70,
  userNameInfoFontSize: 13,
  userInfoFontSize: 13,

  list: {
    horizontalMargins: 30,
    titleMarginTop: 10,
    titleMarginBottom: 20
  },
  listRowStyle:
    "padding: 15px; border-bottom-width: 1px; border-color: #0054a4;",
  selectedListRowStyle:
    "background-color: rgba(0, 0, 255, .1); border-radius: 10px; border-color: #0054a4;",
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;`,

  addButtonWidth: "50px",
  addButtonHeight: "50px",
  arrayIconBackgroundColor: "#d61e3a",
  // arrayIconWeight: "light",
  // roundedIconColor: "white",
  deleteIconName: "times",

  button: {
    primary: {
      borderRadius: "5px",
      backgroundColor: "#d61e3a",
      fontColor: "white",
      iconSize: 15,
      padding: "15px 30px",
      textTransform: "uppercase",
      letterSpacing: "1px",

      disabled: {
        backgroundColor: "#ef3f5f",
        fontColor: "#999999"
      }
    },

    secondary: {
      borderRadius: "5px",
      backgroundColor: "#044580",
      fontColor: "white",
      iconSize: 15,
      padding: "15px 20px",
      textTransform: "uppercase",
      letterSpacing: "1px",

      disabled: {
        backgroundColor: "#ffaf7f"
      }
    },

    round: {
      borderRadius: "25px",
      backgroundColor: "#d61e3a",
      fontColor: "white",
      iconSize: 20,
      padding: "5px",

      disabled: {
        backgroundColor: "#ffb487"
      }
    },

    miniRound: {
      borderRadius: "12px",
      backgroundColor: "#d61e3a", //208.20.51
      fontColor: "white",
      iconSize: 11,

      disabled: {
        fontColor: "#999999",
        backgroundColor: "#ef3f5f"
      }
    },

    link: {
      fontSize: 12,
      letterSpacing: 0.5,

      disabled: {
        fontColor: "#000"
      }
    },

    dangerLink: {
      fontSize: 13,
      fontColor: "#e74c3c", //228.66.53
      margins: "0 auto 0 10px",
      letterSpacing: 1,
      textTransform: "uppercase"
    },

    swipe: {
      // fontSize: 13,
      backgroundColor: "#d61e3a",
      fontColor: "white",
      // margins: "0 auto 0 10px",
      padding: "10px",
      letterSpacing: 1
    },

    paired: {
      fontColor: "#11e744",
      [Platform.OS === "web" ? "textShadowColor" : "shadowColor"]: "#11e744",
      [Platform.OS === "web" ? "textShadowOffset" : "shadowOffset"]: {
        width: 1,
        height: 1
      },
      shadowOpacity: 5,
      [Platform.OS === "web" ? "textShadowRadius" : "shadowRadius"]:
        Platform.OS === "web" ? 45 : 10,
      iconSize: 90
    },

    notPaired: {
      fontColor: "#d0021b",
      [Platform.OS === "web" ? "textShadowColor" : "shadowColor"]: "#d0021b",
      [Platform.OS === "web" ? "textShadowOffset" : "shadowOffset"]: {
        width: 1,
        height: 1
      },
      shadowOpacity: 5,
      [Platform.OS === "web" ? "textShadowRadius" : "shadowRadius"]:
        Platform.OS === "web" ? 45 : 10,
      iconSize: 90
    },

    transparent: {
      backgroundColor: "transparent",
      color: "#fff",
      iconSize: 20,

      disabled: {
        fontColor: "#9B9B9B",
        iconSize: 20
      }
    },

    linkedTeam: {
      borderRadius: "5px",
      backgroundColor: "#2ecc71",
      fontColor: "white",
      iconSize: 15,
      padding: "10px 10px",
      margin: "20px 0 0",
      textTransform: "uppercase",
      letterSpacing: "1px",

      disabled: {
        backgroundColor: "#ef3f5f",
        fontColor: "#999999"
      }
    },

    notLinkedTeam: {
      borderRadius: "5px",
      backgroundColor: "#d61e3a",
      fontColor: "white",
      iconSize: 15,
      padding: "10px 10px",
      margin: "20px 0 0",
      textTransform: "uppercase",
      letterSpacing: "1px",

      disabled: {
        backgroundColor: "#ef3f5f",
        fontColor: "#999999"
      }
    }
  }
};

const messages = defineMessages({
  loginButtonTitle: {
    id: "loginButtonTitle",
    defaultMessage: "LOGIN"
  },
  viewUsersTitle: {
    id: "viewUsersTitle",
    defaultMessage: "View Users"
  },
  usersListTitle: {
    id: "manageUsers",
    defaultMessage: "Manage Users"
  }
});

const LoginPage2 = () => (
  <LoginPage loginButtonTitle={messages.loginButtonTitle} />
);

const App = () => (
  <ApolloProvider client={client}>
    <AppBase
      loginComponent={LoginPage2}
      resetPasswordComponent={ResetPasswordPage}
      theme={authenticationTheme}
      loadLocaleData={addLocaleData => {
        addLocaleData([...en, ...es]);
      }}
      loadLocales={loadLocales}
    >
      <ThemeProvider theme={primaryTheme}>
        <React.Fragment>
          <Route exact path="/" component={HomePage} />
          <Route path="/inventoryonhandreport" component={InventoryOnHandReportPage} />
          <Route path="/expiredinventoryreport" component={ExpiredInventoryReportPage} />
          <Route path="/inventoryusereport" component={InventoryUseReportPage} />
          <Route path="/paymenttypereport" component={PaymentTypeReportPage} />
          <Route path="/certificatereport" component={CertificateReportPage} />
          <Route path="/submittedemails" component={SubmittedAddressesPage} />
          <Route path="/personnel" component={PatientsPage} />
          <Route path="/orders" component={OrdersPage} />
          <Route
            path="/savedorders"
            render={() => <OrdersPage orderPage="saved" />}
          />
          <Route
            path="/sentorders"
            render={() => <OrdersPage orderPage="sent" />}
          />
          <Route
            path="/adminorders"
            render={() => <OrdersPage orderPage="admin" />}
          />
          <Route path="/products" component={ProductsPage} />
          <Route path="/inventoryuses" component={InventoryUsesPage} />
          {/* <Route path="/inventoryuseshistory" render={() => <InventoryUsesPage historical />} /> */}
          {/* <Route path="/inventory" component={InventoryPage} /> */}
          <Route path="/outsideinventory" component={OutsideInventoryPage} />
          <Route path="/minimumalerts" component={MinimumAlertsPage} />
          <Route path="/physicalcounts" component={PhysicalCountsPage} />
          <Route
            path="/users"
            render={() => (
              <ApplicationUsersPage listTitle={messages.usersListTitle} changeBusiness />
            )}
          />
          <Route
            path="/viewusers"
            render={() => (
              <ApplicationUsersPage
                isMutable={false}
                rootPath="viewusers"
                listTitle={messages.viewUsersTitle}
              />
            )}
          />
          <Route
            path="/businesses"
            render={() => <BusinessesTeamsPage />}
          />
          <Route
            path="/personnelteam"
            render={() => <PatientsPage teamChange />}
          />
          <Route path="/teamgroups" component={TeamGroupsPage} />
          <Route path="/usergroups" component={UserGroupsPage} />
        </React.Fragment>
      </ThemeProvider>
    </AppBase>
  </ApolloProvider>
);

export default App;
