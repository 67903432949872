import gql from "graphql-tag";

const officeProductFieldsFragment = gql`
  fragment OfficeProductFields on OfficeProduct {
    id
    version
    status
    product {
      id
      version
      name
      status
    }
    units {
      id
      version
      onHandQuantity
      availableQuantity
      minimumLowLevel
      alertDate
      daysAlert
      daysAlertSavedOn
      productUnit {
        id
        version
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
    }
  }
`;

const findOfficeProductByIdQuery = gql`
  query findOfficeProductById($id: UUID) {
    findOfficeProductById(id: $id) {
      ...OfficeProductFields
    }
  }
  ${officeProductFieldsFragment}
`;

const findOfficeProductsByNameQuery = gql`
  query findOfficeProductsByName (
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findOfficeProductsByName (
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...OfficeProductFields
      }
    }
  }
  ${officeProductFieldsFragment}
`;

const findMinimumOfficeProductsByNameQuery = gql`
  query findMinimumOfficeProductsByName (
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findMinimumOfficeProductsByName (
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...OfficeProductFields
      }
    }
  }
  ${officeProductFieldsFragment}
`;

const saveOfficeProductMutation = gql`
  mutation($officeProduct: OfficeProductInput) {
    saveOfficeProduct(officeProduct: $officeProduct) {
      ...OfficeProductFields
    }
  }
  ${officeProductFieldsFragment}
`;

const deleteOfficeProductMutation = gql`
  mutation($officeProduct: OfficeProductInput) {
    deleteOfficeProduct(officeProduct: $officeProduct) {
      id
      version
    }
  }
`;

export {
  findOfficeProductByIdQuery,
  findOfficeProductsByNameQuery,
  findMinimumOfficeProductsByNameQuery,
  saveOfficeProductMutation,
  deleteOfficeProductMutation
};
