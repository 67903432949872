import gql from "graphql-tag";

const outsideInventoryFieldsFragment = gql`
  fragment OutsideInventoryFields on OutsideInventory {
    id
    version
    status
    referenceId
    createdOn
    createdBy {
      id
      version
      status
      contact {
        id
        version
        firstName
        lastName
      }
    }
    items {
      id
      version
      quantity
      packaging
      quantityPackage
      oldQuantity
      oldQuantityPackage
      business {
        id
      }
      productUnit {
        id
        version
        product {
          id
          version
          name
        }
        unit {
          id
          version
          name
        }
      }
      transactions {
        id
        version
        expirationDate
        scriptNumber
      }
    }
  }
`;

const findOutsideInventoryByIdQuery = gql`
  query findOutsideInventoryById($id: UUID) {
    findOutsideInventoryById(id: $id) {
      ...OutsideInventoryFields
    }
  }
  ${outsideInventoryFieldsFragment}
`;

const saveOutsideInventoryMutation = gql`
  mutation saveOutsideInventory($outsideInventory: OutsideInventoryInput) {
    saveOutsideInventory(outsideInventory: $outsideInventory) {
      ...OutsideInventoryFields
    }
  }
  ${outsideInventoryFieldsFragment}
`;

const deleteOutsideInventoryMutation = gql`
  mutation deleteOutsideInventory($outsideInventory: OutsideInventoryInput) {
    deleteOutsideInventory(outsideInventory: $outsideInventory) {
      id
      version
    }
  }
`;

const findOutsideInventorysByKeywordQuery = gql`
  query findOutsideInventorysByKeyword(
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findOutsideInventorysByKeyword(
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...OutsideInventoryFields
      }
    }
  }
  ${outsideInventoryFieldsFragment}
`;

const countByOutsideItemQuery = gql`
  query countByOutsideItem (
    $id: UUID
    $business: BusinessInput
  ) {
    countByOutsideItem (
      id: $id
      business: $business,
    ) {
      scriptNumber
      count
    }
  }
`;


export {
  findOutsideInventoryByIdQuery,
  saveOutsideInventoryMutation,
  deleteOutsideInventoryMutation,
  findOutsideInventorysByKeywordQuery,
  countByOutsideItemQuery
};
