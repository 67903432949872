import gql from "graphql-tag";

const productFieldsFragment = gql`
  fragment ProductFields on Product {
    id
    version
    status
    name
    inventoryBypass
    units {
      id
      version
      status
      universalProductCode
      childUnitQuantity
      businessId2
      systemRecord
      unit {
        id
        version
        name
        referenceId
      }
    }
  }
`;

const findProductByIdQuery = gql`
  query($id: UUID) {
    findProductById(id: $id) {
      ...ProductFields
    }
  }
  ${productFieldsFragment}
`;

const findProductsByNameQuery = gql`
  query findProductsByName(
    $business: BusinessInput
    $name: String
    $allTeam: Boolean
    $startIndex: Int
    $stopIndex: Int
  ) {
    findProductsByName(
      business: $business
      name: $name
      allTeam: $allTeam
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...ProductFields
      }
    }
  }
  ${productFieldsFragment}
`;

const saveProductMutation = gql`
  mutation($product: ProductInput) {
    saveProduct(product: $product) {
      ...ProductFields
    }
  }
  ${productFieldsFragment}
`;

const deleteProductMutation = gql`
  mutation deleteProduct($product: ProductInput) {
    deleteProduct(product: $product) {
      id
      version
    }
  }
`;

export {
  findProductByIdQuery,
  findProductsByNameQuery,
  saveProductMutation,
  deleteProductMutation
};
