import React from "react";
import { defineMessages } from "react-intl";
import { MutableList } from "cci-admin";
import { LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { findMinimumOfficeProductsByNameQuery } from "../../query/OfficeProduct";

const messages = defineMessages({
  searchAlert: {
    id: "searchAlert",
    defaultMessage: "Search Alert"
  },
  title: { id: "minimumAlerts", defaultMessage: "Inventory Alerts" },
  productMedication: { id: "productMedication", defaultMessage: "Product / Medication"}
});

/**
 * MinimumAlerts List page which allows searching.
 */
const List = props => (
  <SessionQuery>
    {({ wrapBusiness }) => (
      <MutableList
        {...props}
        listTitle={messages.title}
        queryName="findMinimumOfficeProductsByName"
        query={findMinimumOfficeProductsByNameQuery}
        filterProperty="name"
        variables={wrapBusiness({ name: "" })}
        searchPlaceholder={messages.searchAlert}
        newUrl="/minimumalerts/new"
        recordRenderer={({ key, style }, rowData) => (
          <LinkedRow
            key={key}
            style={style}
            to={{
              pathname: `/minimumalerts/${rowData.id}`,
              state: { accountId: rowData.id }
            }}
          >
            <RowText style={{ textAlign: "left" }} flex={1}>
              {rowData.product.name}
            </RowText>
          </LinkedRow>
        )}
      />
    )}
  </SessionQuery>
);

export default List;
