import React from "react";
import { Field } from "react-final-form";

/**
 * Copied from CCI-COMPONENT because it is not exposed.
 * This is for the use of SelectRecordOptionsField.
 */
const FormBaseField = ({
  fieldComponent: FieldComponent,
  onChangeText,
  onBlur,
  changeEvent,
  setNegative,
  hideError,
  ...restProps
}) => (
  <Field name={restProps.name}>
    {({ input, meta }) => {
      const { value } = input,
        // We separate out the events so that the change event name can be different based on the changeEvent prop
        events = {
          [changeEvent]: text => {
            input.onChange(text);

            onChangeText(text);
          },
          onBlur: e => {
            input.onBlur();

            onBlur(e);
          }
        };

      return (
        <FieldComponent
          value={setNegative ? (value < 0 ? -value : value) : value}
          error={hideError ? null : meta.error || meta.submitError}
          {...restProps}
          {...events}
        />
      );
    }}
  </Field>
);

FormBaseField.defaultProps = {
  changeEvent: "onChangeText",
  onChangeText: () => {},
  onBlur: () => {}
};

export default FormBaseField;
