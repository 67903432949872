import React from "react";
import { defineMessages } from "react-intl";
import { WideSplitView } from "cci-component";
import { PrimaryPage, ReportPreview } from "cci-admin";
import Options from "../report/paymentType/Options";

const messages = defineMessages({
  selectReportOptions: {
    id: "selectReportOptions",
    defaultMessage: "Select the desired options to preview or print the report."
  }
});

/**
 * PaymentType Reports Page.
 */
const PaymentTypeReportPage = () => (
  <PrimaryPage>
    <WideSplitView
      rootPath="/paymenttypereport"
      selectionPath="/paymenttypereport/preview/:options"
      left={Options}
      right={ReportPreview}
      leftRightOptions={{
        reportName: "prs/data/report/paymentType.rptdesign"
      }}
      leftOptions={{ previewPath: "/paymenttypereport" }}
      placeholderMessage={messages.selectReportOptions}
    />
  </PrimaryPage>
);

export default PaymentTypeReportPage;
