import React from "react";
import { getIn } from "final-form";
import { pick } from "lodash";
import { SelectField } from "cci-component";

/**
 * A variation of the SelectRecordFieldField component.
 * This is nos querying to the server. Instead, this component uses options prop.
 *
 * Props:
 * - options: Options array
 * - value: Value record whose valueKey will be used to determine the selected record.
 * - valueKey: Property name in the record that contains the unique identifier for the record. Defaults to "id".
 * - labelKey: Property name in the record that contains the label that the user will see to select the record. Defaults to "name".
 * - displayLabel: Function that returns the text that the user will see to select the record. If this prop is not used labelKey Prop will be used. This overrides labelKey.
 * - valueOnly: Indicates if only the value should be set in the field. Defaults to false.
 * - valueVersionOnly
 * - onChange
 */
const SelectRecordOptionsField = ({
  options,
  value,
  valueKey,
  labelKey,
  displayLabel,
  valueOnly,
  valueVersionOnly,
  onChange,
  ...restProps
}) => {

  // Using the valueKey and labelKey, we create the object that SelectField expects
  const optionsObjectArray = options.map(
      (option, index) => ({
        value: getIn(option, valueKey),
        label:
          displayLabel(option) ||
          getIn(option, labelKey) ||
          getIn(option, valueKey),
        status: option.status,
        index
      })
    ),
    // Get the record that is currently selected.
    // We use null here to ensure data is set, using undefined in final form does not reset the value
    selectedOption = valueOnly
      ? value[valueKey]
      : optionsObjectArray.find(
          option => option.value === value[valueKey]
        ) || null;

  return (
    <SelectField
      {...restProps}
      onChange={selected => {
        if (!selected) {
          onChange(null);
        } else if (valueOnly) {
          onChange(selected.value);
        } else if (valueVersionOnly) {
          onChange(
            pick(options.find(option => option[valueKey] === selected.value), ["id", "version"])
          );
        } else {
          // We use the entire record to include the version, status and any other field that might be needed.
          // Version is needed for serialization to the server, status is used for coloring certain fields.
          onChange(
            options.find(option => option[valueKey] === selected.value)
          );
        }
      }}
      options={optionsObjectArray}
      value={selectedOption}
      clearable={true}
    />
  );
};

SelectRecordOptionsField.defaultProps = {
  options: [],
  value: {},
  valueKey: "id",
  labelKey: "name",
  displayLabel: opt => {},
  valueOnly: false,
  valueVersionOnly: false
};

export default SelectRecordOptionsField;
