import React, { Fragment, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { withTheme } from "styled-components/native";
import {
  array as yupArray,
  date as yupDate,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import { InspectTitle, ImmutableInspect, MutableInspect } from "cci-admin";
import {
  Button,
  Container,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  RowText} from "cci-component";
import { SessionQuery } from "cci-data";
import { localize, withRouter } from "cci-utility";
import {
  findPhysicalCountByIdQuery,
  savePhysicalCountMutation,
  deletePhysicalCountMutation} from "../../query/PhysicalCount";
import Transactions from "./Transactions";

const messages = defineMessages({
  newPhysicalCount: {
    id: "newPhysicalCount",
    defaultMessage: "New Inventory Audit"
  },
  newPhysicalCountMessage: {
    id: "newPhysicalCountMessage",
    defaultMessage:
      "Enter the items current quantity you have in stock. If inventory matches the physical count, you will be able to print the certificate when you submit this inventory audit."
  },
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  unitsPackage: {
    id: "unitsPackage",
    defaultMessage: "Strength"
  },
  maxQuantity: {
    id: "maxQuantity",
    defaultMessage: "{max} is the maximum"
  },
  printCertificate: {
    id: "printCertificate",
    defaultMessage: "Print Certificate"
  },
  saveAsPending: {
    id: "saveAsPending",
    defaultMessage: "Save as pending"
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit"
  },
  // reasonError: {
  //   id: "reasonError",
  //   defaultMessage: "Select a Reason"
  // }
});

/**
 * SubInspect component for the PhysicalCounts entity.
 * 
 * This is an InventoryAudit.
 */
const SubInspect = ({
  history,
  theme,
  intl,
  match,
  invAudit,
  newRecord,
  ...restProps
}) => {
  const [status, setStatus] = useState('opened')
  const ThisInspect = status === 'opened' ? MutableInspect : ImmutableInspect;
  const BottomComponent = ({
      form,
      handleSubmit,
      pristine,
      loadingSave,
      setSaving
    }) =>
      !form.getState().submitFailed && loadingSave ? null : (
        <Fragment>
          <Button
            title={messages.submit}
            disabled={newRecord && pristine}
            onPress={() => {
              setSaving();
              form.mutators.submitMutator(handleSubmit);
            }}
          />
          <Button
            title={messages.saveAsPending}
            margins="0 10px 0"
            disabled={pristine}
            onPress={() => {
              setSaving();
              handleSubmit();
            }}
          />
        </Fragment>
      );

  return (
    <SessionQuery>
      {({ wrapBusiness, wrapOffice }) => (
        <ThisInspect
          entityName="PhysicalCount"
          query={findPhysicalCountByIdQuery}
          queryName="findPhysicalCountById"
          mutation={savePhysicalCountMutation}
          mutationName="savePhysicalCount"
          objectWrapper="physicalCount"
          onQueryCompleted={data => {
            data.findPhysicalCountById && setStatus(data.findPhysicalCountById.status);
          }}
          defaultValues={wrapBusiness(wrapOffice(invAudit)) || wrapOffice()}
          validationSchema={yupObject().shape({
            counts: yupArray()
              .of(
                yupObject().shape({
                  productUnit: yupObject().required().nullable(),
                  transactions: yupArray().of(
                    yupObject().shape({
                      scriptNumber: yupString()
                        .test(
                          "compound1", // function name
                          "notCompoundRequiredMSG", // intl message
                          function (value) {
                            const thisItem = this.from[0];
                            return !!value || !!thisItem.value.compound;
                          }
                        ),
                      expirationDate: yupDate()
                        .nullable()
                        .test(
                          "compound2", // function name
                          "notCompoundRequiredMSG", // intl message
                          function (value) {
                            const thisItem = this.from[0];
                            return !!value || !!thisItem.value.compound;
                          }
                        ),
                      // quantity: yupNumber(),
                      // onHandQuantity: yupNumber(),
                      // reason: yupString().nullable()
                      //   .when(["quantity", "onHandQuantity"], (quantity, onHandQuantity, schema) =>
                      //     quantity && (onHandQuantity !== quantity)
                      //       ? schema.required("reasonError")
                      //       : schema
                      //   )
                    }))
                    .required()
                })
              )
              .required()
          })}
          footerAddedComponent={BottomComponent}
          mutators={{
            submitMutator: (args, state, utils) => {
              // Means that count has been submitted, the rest of the process is done on the server side.
              utils.changeValue(state, "status", () => {
                return 'failed'
              });

              args[0]();// handleSubmit()
            }
          }}
          hideSaveButton
          deleteMutation={deletePhysicalCountMutation}
          {...restProps}
        >
          {({ form }) => (
            <FormSpy subscription={{ modified: true, values: true }}>
              {({ form: { getFieldState } }) => {

                const mutableValues = form.getState() && form.getState().values && form.getState().values.status === "opened";
                const check = (form.getState() && form.getState().values && form.getState().values.status === "successful") || false;

                return (
                  <Fragment>
                    <Container flex={1}>
                      <Field name="createdOn">
                        {({ input }) => (
                          <InspectTitle
                            title={
                              newRecord
                                ? messages.newPhysicalCount
                                : input.value &&
                                  intl.formatDate(input.value, {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                  })
                            }
                          />
                        )}
                      </Field>

                      {check && (
                        <Container
                          flexDirection="row"
                          justifyContent="flex-end"
                        >
                          <Button
                            margins="20px 0"
                            title={messages.printCertificate}
                            onPress={() => {
                              history.push({
                                pathname: `/certificatereport/preview/physicalCountId=${match.params.id}`,
                                state: {
                                  physicalCountId: match.params.id
                                }
                              });
                            }}
                          />
                        </Container>
                      )}

                      {mutableValues && (
                        <Section>
                          <RowText style={{ textAlign: "left" }} flex={1}>
                            {localize(intl, messages.newPhysicalCountMessage)}
                          </RowText>
                        </Section>
                      )}

                      <FormToManyField
                        name="counts"
                        renderNewOnButton
                        addValidation={yupObject().shape({
                          productUnit: yupObject().required(),
                          transactions: yupArray()
                            .of(
                              yupObject().shape({
                                scriptNumber: yupString().required(),
                                expirationDate: yupDate().required(),
                                quantity: yupNumber().required()
                              })
                            )
                            .required()
                        })}
                      >
                        {({ name, push }) => (
                          <Container
                            key={name}
                            flexDirection="row"
                            flexShrink={1}
                            paddings="0 10px"
                            width="100%"
                          >
                            <Container margins="10px 0 0">
                              <RoundedIcon
                                iconSize={50}
                                color={theme.fieldFontColor}
                                name={"capsules"}
                              />
                            </Container>

                            <Section style={{ flex: 1 }}>
                              <Fragment>
                                <Container
                                  margins="10px 0 0 10px"
                                  flex={2}
                                  style={{ minWidth: 200 }}
                                >
                                  <FormTextField
                                    name={`${name}.productUnit.product.name`}
                                    label={messages.product}
                                    disabled
                                  />
                                </Container>
                                <Container
                                  margins="10px 0 0 10px"
                                  flex={1}
                                  style={{ minWidth: 150 }}
                                >
                                  <FormTextField
                                    name={`${name}.productUnit.unit.name`}
                                    label={messages.unitsPackage}
                                    disabled
                                  />
                                </Container>

                                <Container
                                  margins="10px 0 0 10px"
                                  flex={4}
                                  style={{ minWidth: 250 }}
                                >
                                  <Transactions {...{ name, push, mutableValues, getFieldState }} />
                                </Container>
                              </Fragment>
                            </Section>
                          </Container>
                        )}
                      </FormToManyField>
                    </Container>
                  </Fragment>
                );
              }}
            </FormSpy>
          )}
        </ThisInspect>
      )}
    </SessionQuery>
  );
};

export default withRouter(injectIntl(withTheme(SubInspect)));
