/**
 * MinimumAlertsPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/minimumAlerts/List";
import Inspect from "../view/minimumAlerts/Inspect";

const messages = defineMessages({
  minimumAlertsPlaceholder: {
    id: "minimumAlertsPlaceholder",
    defaultMessage:
      "Select an alert from the left to update the minimum."
  }
});

const MinimumAlertsPage = () => (
  <PrimaryPage>
    <ListInspect
      rootPath="/minimumalerts"
      left={List}
      placeholderMessage={messages.minimumAlertsPlaceholder}
      selectionPath="/minimumalerts/:id"
      right={Inspect}
    />
  </PrimaryPage>
);

export default MinimumAlertsPage;
