import React from "react";
import { defineMessages, FormattedDate, injectIntl } from "react-intl";
import { withTheme } from "styled-components/native";
import { MutableList } from "cci-admin";
import { Container, LinkedRow, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { withRouter } from "cci-utility";
import { findOutsideInventorysByKeywordQuery } from "../../query/OutsideInventory";

const messages = defineMessages({
  searchOutsideInventory: { id: "searchOutsideInventory", defaultMessage: "Search External Inventory by reference" },
  title: { id: "title", defaultMessage: "Outside Inventory" },

  saved: { id: "saved", defaultMessage: "Saved" },
  open: { id: "open", defaultMessage: "Pending" },
  sent: { id: "sent", defaultMessage: "Sent" },
  submitted: { id: "submitted", defaultMessage: "Submitted" },
  delivered: { id: "delivered", defaultMessage: "Fulfilled" }
});

/**
 * OutsideInventory List page which allows searching.
 */
const List = props => (
  <SessionQuery>
    {({ wrapBusiness }) => (
      <MutableList
        {...props}
        listTitle={messages.title}
        queryName="findOutsideInventorysByKeyword"
        query={findOutsideInventorysByKeywordQuery}
        filterProperty="name"
        variables={wrapBusiness({ name: "" })}
        fetchPolicy="cache-and-network"
        searchPlaceholder={messages.searchOutsideInventory}
        newUrl="/outsideinventory/new"
        recordRenderer={({ key, style }, rowData) => (
          <LinkedRow
            key={key}
            style={style}
            to={{
              pathname: `/outsideinventory/${rowData.id}`,
              state: { accountId: rowData.id }
            }}
          >
            <Container
              flexDirection="row"
              flex={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Container flex={1}>
                <Container flexDirection="row">
                  <RowText flex={1}>
                    <FormattedDate
                      value={rowData.createdOn}
                      timeZone="UTC"
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </RowText>
                  <RowText flex={1}>{rowData.referenceId}</RowText>
                </Container>
                <RowText>
                  {"by " +
                    (rowData.createdBy.contact.firstName + " " +
                      rowData.createdBy.contact.lastName)}
                </RowText>
              </Container>

            </Container>
          </LinkedRow>
        )}
      />
    )}
  </SessionQuery>
);

export default injectIntl(withRouter(withTheme(List)));
