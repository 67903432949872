import React from "react";
import { defineMessages } from "react-intl";
import { MutableList } from "cci-admin";
import { LinkedRow, RowText } from "cci-component";
import { findTeamGroupsByNameQuery } from "../../query/TeamGroup";

const messages = defineMessages({
  searchTeamGroups: {
    id: "searchTeamGroups",
    defaultMessage: "Search Team Groups"
  },
  title: { id: "manageTeamGroups", defaultMessage: "Manage Team Groups" },
});

/**
 * TeamGroup List page which allows searching.
 */
const List = props => (
  <MutableList
    {...props}
    listTitle={messages.title}
    queryName="findTeamGroupsByName"
    query={findTeamGroupsByNameQuery}
    filterProperty="name"
    variables={{ name: "" }}
    searchPlaceholder={messages.searchTeamGroups}
    newUrl="/teamgroups/new"
    recordRenderer={({ key, style }, rowData) => (
      <LinkedRow
        key={key}
        style={style}
        to={{
          pathname: `/teamgroups/${rowData.id}`,
          state: { accountId: rowData.id }
        }}
      >
        <RowText style={{ textAlign: "left" }} flex={1}>
          {rowData.name}
        </RowText>
      </LinkedRow>
    )}
  />
);

export default List;
