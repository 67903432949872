import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { withTheme } from "styled-components/native";
import {
  array as yupArray,
  number as yupNumber,
  object as yupObject
} from "yup";
import { Query, withApollo } from "react-apollo";
import { Field } from "react-final-form";
import { InspectTitle, InspectSubtitle, MutableInspect } from "cci-admin";
import {
  Button,
  Container,
  FormDateField,
  FormNumberField,
  FormSelectRecordField,
  FormToManyField,
  RowText
} from "cci-component";
import { SessionQuery } from "cci-data";
import { localize, withRouter } from "cci-utility";
import {
  findOfficeProductByIdQuery,
  findOfficeProductsByNameQuery,
  saveOfficeProductMutation
} from "../../query/OfficeProduct";
import { findUnitsByNameQuery } from "../../query/Unit";
import { findProductsByNameQuery } from "../../query/Product";

const messages = defineMessages({
  onHand: {
    id: "onHand",
    defaultMessage: "On Hand"
  },
  available: {
    id: "available",
    defaultMessage: "Available"
  },
  minLowLevel: {
    id: "minLowLevel",
    defaultMessage: "Alert Level"
  },
  add: {
    id: "add",
    defaultMessage: "Add"
  },
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  strength: {
    id: "strength",
    defaultMessage: "Strength"
  },
  selectAnUnit: {
    id: "selectAnUnit",
    defaultMessage: "Select an Unit"
  },
  alertDate: {
    id: "alertDate",
    defaultMessage: "Alert Date"
  },
  mustEnterAlert: {
    id: "mustEnterAlert",
    defaultMessage: "You must enter a minimum number or a date for the alert"
  },
  toDeleteAlert: {
    id: "toDeleteAlert",
    defaultMessage: "To delete an alert, please leave all three fields blank then save."
  },
  allStrengths: {
    id: "allStrengths",
    defaultMessage: "All Strengths"
  },
  daysAlert: {
    id: "daysAlert",
    defaultMessage: "Days Alert"
  }
});

/**
 * Inspect component for the Minimum Alerts, part of the OfficeProductUnit model.
 * Allows the user to create or edit records if he has permission to do so.
 */
const Inspect = ({ match, history, location, theme, client, intl, ...restProps }) => {
  const newRecord = match.params.id === "new";
  const redirected = location.state && location.state.redirected;

  return (
    <SessionQuery>
      {({ wrapBusiness }) => (
        <Query
          query={findOfficeProductsByNameQuery}
          variables={wrapBusiness({ name: "", startIndex: 0, stopIndex: 200 })}
        >
          {({ data }) => {
            const officeProducts = data && data.findOfficeProductsByName && data.findOfficeProductsByName.content;

            return (
              <MutableInspect
                entityName="OfficeProduct"
                query={findOfficeProductByIdQuery}
                queryName="findOfficeProductById"
                mutation={saveOfficeProductMutation}
                mutationName="saveOfficeProduct"
                objectWrapper="officeProduct"
                defaultValues={{
                  status: "active"
                }}
                validationSchema={yupObject().shape({
                  product: yupObject().nullable().required(),
                  units: yupArray()
                    .of(
                      yupObject().shape({
                        onHandQuantity: yupNumber(),
                        availableQuantity: yupNumber(),
                        // minimumLowLevel: yupNumber().nullable()
                        //   .when('alertDate', (alertDate, schema) =>
                        //     alertDate ? schema : schema.required("mustEnterAlert")
                        //   ),
                        productUnit: yupObject().required().nullable()
                      })
                    )
                    .required()
                })}
                {...restProps}
              >
                {({ form }) => (
                  <Container>
                    <Container flexDirection="row">
                      {/* PRODUCT NAME AND TIMES BUTTON */}
                      <Field name="product.name">
                        {({ input }) => (
                          <Fragment>
                            <InspectTitle title={input.value} />

                            {input.value && (redirected || newRecord) ? <Button
                              leftIcon="times"
                              margins="0 0 20px 10px"
                              ui="dangerLink"
                              leftIconWeight={900}
                              onPress={() => {
                                redirected && history.goBack();
                                !redirected && input.onChange(null);
                              }}
                            /> : null}
                          </Fragment>
                        )}
                      </Field>
                    </Container>

                    {!newRecord && !redirected
                      ? (
                        <Section>
                          <RowText style={{ textAlign: "left" }} flex={1}>
                            {localize(intl, messages.toDeleteAlert)}
                          </RowText>
                        </Section>
                      )
                      : redirected && (
                        <Section>
                          <InspectSubtitle title={messages.allStrengths} />
                        </Section>
                      )
                    }

                    {/* UNITS */}
                    <FormToManyField
                      name="units"
                      addValidation={yupObject().shape({
                        // minimumLowLevel: yupNumber().required(),
                        productUnit: yupObject().required().nullable()
                      })}
                      width="100%"
                      renderNewOnButton
                    >
                      {({ name, isNew, push, remove, value, plus, index, length }) => {
                        if (newRecord || redirected || (
                          index !== undefined && (
                            value[index].minimumLowLevel !== null ||
                            value[index].alertDate !== null ||
                            value[index].daysAlert !== null
                          )
                        )) {
                          return (
                            <Container
                              key={name}
                              flexDirection="row"
                              paddings="10px"
                              width="100%"
                              flexShrink={1}
                            >
                              <Section style={{ flex: 1 }}>
                                {isNew ? (
                                  <Fragment>
                                    {/* PRODUCT */}
                                    {(newRecord && !redirected) && (
                                      <Container
                                        margins="10px 0 0 10px"
                                        flex={1}
                                        style={{ minWidth: 200 }}
                                      >
                                        <FormSelectRecordField
                                          query={findProductsByNameQuery}
                                          enableServerSearch
                                          variables={wrapBusiness()}
                                          name="product"
                                          label={messages.product}
                                          onChangeText={selected => {
                                            const officeProductFound = selected && officeProducts.find(op => op.product.id === selected.id)
                                            if (officeProductFound) {
                                              history.push({
                                                pathname: `/minimumalerts/${officeProductFound.id}`,
                                                state: { redirected: true }
                                              })
                                            }
                                          }}
                                        />
                                      </Container>
                                    )}

                                    {/* UNIT NAME */}
                                    <Container
                                      margins="10px 0 0 10px"
                                      flex={1}
                                      style={{ minWidth: 200 }}
                                    >
                                      <FormSelectRecordField
                                        query={findUnitsByNameQuery}
                                        enableServerSearch
                                        variables={wrapBusiness()}
                                        name={`${name}.productUnit.unit`}
                                        label={messages.strength}
                                        onChangeText={() =>
                                          form.change(`${name}.productUnit.product`, form.getState().values.product)
                                        }
                                        excludeValues={{
                                          values: value.map(
                                            unitItem => unitItem.productUnit.unit
                                          ),
                                          equalsFn: (option, unitValue) =>
                                            option &&
                                            unitValue &&
                                            option.id === unitValue.id
                                        }}
                                      />
                                    </Container>
                                  </Fragment>
                                ) : (
                                    <Container
                                      margins="10px 0 0 10px"
                                      flex={1}
                                      style={{ minWidth: 100, maxWidth: 200 }}
                                    >
                                      <Field name={`${name}.productUnit.unit.name`}>
                                        {({ input }) => (
                                          <InspectSubtitle title={input.value} />
                                        )}
                                      </Field>
                                    </Container>
                                  )}

                                {/* MINIMUM LOW LEVEL */}
                                <Container
                                  margins="10px 0 0 10px"
                                  flex={1}
                                  style={{ minWidth: 130, maxWidth: 200 }}
                                >
                                  <FormNumberField
                                    label={messages.minLowLevel}
                                    name={`${name}.minimumLowLevel`}
                                    onBlur={push}
                                  />
                                </Container>

                                {/* ALERT DATE */}
                                <Container
                                  margins="10px 0 0 10px"
                                  flex={1}
                                  style={{ minWidth: 100, maxWidth: 200 }}
                                >
                                  <FormDateField
                                    label={messages.alertDate}
                                    name={`${name}.alertDate`}
                                    onBlur={push}
                                  />
                                </Container>

                                {/* DAYS ALERT */}
                                <Container
                                  margins="10px 0 0 10px"
                                  flex={1}
                                  style={{ minWidth: 100, maxWidth: 200 }}
                                >
                                  <FormNumberField
                                    label={messages.daysAlert}
                                    name={`${name}.daysAlert`}
                                    onBlur={push}
                                  />
                                </Container>

                                {(newRecord || redirected) && !isNew && index + 1 === length && (
                                  <Button
                                    leftIcon="plus"
                                    margins="37px 0 0 10px"
                                    ui="dangerLink"
                                    leftIconWeight={900}
                                    onPress={plus}
                                  />
                                )}
                              </Section>
                            </Container>
                          )
                        }
                        return null
                      }}
                    </FormToManyField>
                  </Container>
                )}
              </MutableInspect>
            )
          }}
        </Query>
      )}
    </SessionQuery>
  );
};

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

export default withApollo(withRouter(injectIntl(withTheme(Inspect))));
