import React, { Fragment, useState } from "react";
import { defineMessages } from "react-intl";
import { Dialog, FormSelectRecordField } from "cci-component";
import { findProductsByNameQuery } from "../../query/Product";
import ProductInspect from "../product/Inspect";

const messages = defineMessages({
  product: {
    id: "product",
    defaultMessage: "Product"
  },
  other: {
    id: "other",
    defaultMessage: "Other"
  }
});

/**
 * Product Name Component for the Order Item entity, allows the user to select a Product
 * or add a new one by selecting the OTHER option.
 * 
 * Shows a popup with a Product inspect.
 */
const ProductField = ({ name, push, businessData, disabled , getFieldState}) => {
  const [nameForOther, setNameForOther] = useState(null);

  return (
    <Fragment>
      <FormSelectRecordField
        query={findProductsByNameQuery}
        enableServerSearch
        variables={{ business: { id: businessData.id } }}
        labelSearch={value => {
          if (value && value.name) {
            return value.name;
          }
        }}
        name={`${name}.productUnit.product`}
        label={messages.product}
        onChangeText={selected => {
          if (selected && selected.id === "new") {
            setNameForOther(name)
          }
          push && push();
        }}
        enableNew
        newLabel={messages.other}
        isDisabled={disabled}
      />

      <Dialog
        isVisible={!!nameForOther}
        paddings="5px"
        width={0.8}
        height={0.8}
        autoSnap={false}
        onBackdropPress={() => setNameForOther(null)}
      >
        <ProductInspect
          myId="new"
          mdStyle={{ width: "90%" }}
          marginTop={20}
          negativeMargin={false}
          onCompleted={savedData => {
            setNameForOther(null);
            getFieldState(`${nameForOther}.productUnit.product`).change(savedData);
          }}
          refetchQueries={["findProductsByName"]}
          blockRedirection
        />
      </Dialog>
    </Fragment>
  );
};

export default ProductField;
