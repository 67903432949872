/**
 * HomePage
 *
 * @format
 * @flow
 */

import React, { Fragment, useState } from "react";
import { Dimensions } from "react-native";
import { Query } from "react-apollo";
import { ThemeProvider } from "styled-components/native";
import { GetMainMenuInfoQuery, Header } from "cci-admin";
import { VerticalMenu } from "cci-component";

const HomePage = () => {
  const [direction, setDirection] = useState(Dimensions.get("window").width < 650 ? "column" : "row")

  return (
    <Fragment>
      {/* Hamburger logo, menu button hidden */}
      <Header hideHamburguer hideUserInfo />

      <Query query={GetMainMenuInfoQuery} errorPolicy="ignore">
        {({ loading, error, data }) => (
          <ThemeProvider
            theme={{
              mainMenuTextSize: 17,
              mainMenuTitleSize: 20
            }}
          >
            <VerticalMenu
              onLayout={({ nativeEvent: { layout: { width } } }) => setDirection(width < 650 ? "column" : "row")}
              margins="100px 0 0 0"
              flex={1}
              centerStyle={{
                flexDirection: direction,
                flex: 1
              }}
              hideExpenderIcon
              items={data.MainMenu.items}
              itemStyle={{
                style: { padding: 50 }
              }}
            />
          </ThemeProvider>
        )}
      </Query>
    </Fragment>
  );
};

export default HomePage;
