import gql from "graphql-tag";

const unitFieldsFragment = gql`
  fragment UnitFields on Unit {
    id
    version
    status
    name
    referenceId
  }
`;

const findUnitByIdQuery = gql`
  query findUnitById($id: UUID) {
    findUnitById(id: $id) {
      ...UnitFields
    }
  }
  ${unitFieldsFragment}
`;

const saveUnitMutation = gql`
  mutation saveUnit($unit: UnitInput) {
    saveUnit(unit: $unit) {
      ...UnitFields
    }
  }
  ${unitFieldsFragment}
`;

const deleteUnitMutation = gql`
  mutation deleteUnit($unit: UnitInput) {
    deleteUnit(unit: $unit) {
      id
      version
    }
  }
`;

const findUnitsByNameQuery = gql`
  query findUnitsByName(
    $business: BusinessInput
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findUnitsByName(
      business: $business
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...UnitFields
      }
    }
  }
  ${unitFieldsFragment}
`;

export {
  findUnitByIdQuery,
  saveUnitMutation,
  deleteUnitMutation,
  findUnitsByNameQuery
};
