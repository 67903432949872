import React from "react";
import { defineMessages, FormattedDate } from "react-intl";
import { withTheme } from "styled-components/native";
import { MutableList } from "cci-admin";
import { Container, LinkedRow, Icon, RowText } from "cci-component";
import { SessionQuery } from "cci-data";
import { findPhysicalCountsByNameQuery } from "../../query/PhysicalCount";

const messages = defineMessages({
  searchPhysicalCounts: {
    id: "searchPhysicalCounts",
    defaultMessage: "Search Inventory Audit"
  },
  title: { id: "inventoryAudit", defaultMessage: "Inventory Audit" }
});

/**
 * PhysicalCounts List page which allows searching.
 */
const List = props => (
  <SessionQuery>
    {({ wrapBusiness }) => (
      <MutableList
        {...props}
        listTitle={messages.title}
        queryName="findPhysicalCountsByName"
        query={findPhysicalCountsByNameQuery}
        filterProperty="name"
        variables={wrapBusiness({ name: "" })}
        searchPlaceholder={messages.searchPhysicalCounts}
        newUrl="/physicalcounts/new"
        recordRenderer={({ key, style }, rowData) => {

          const check = rowData.status === "successful" || false;

          return (
            <LinkedRow
              key={key}
              style={style}
              to={{
                pathname: `/physicalcounts/${rowData.id}`,
                state: { accountId: rowData.id }
              }}
            >
              <Container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
              >
                <Container>
                  <RowText style={{ textAlign: "left" }} flex={1}>
                    <FormattedDate
                      value={rowData.createdOn}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </RowText>
                  <RowText style={{ textAlign: "left" }} flex={1}>
                    {"by " + rowData.updatedBy.contact.firstName}
                  </RowText>
                </Container>
                <RowText style={{ textAlign: "left", marginRight: 20 }}>
                  {rowData.status !== "opened" && <Icon
                    iconSize={25}
                    color={
                      check
                        ? "#2ecc71" //41.197.102
                        : "#e74c3c" //228.66.53
                    }
                    name={check ? "check-circle" : "times-circle"}
                    weight="solid"
                  />}
                </RowText>
              </Container>
            </LinkedRow>
          );
        }}
      />
    )}
  </SessionQuery>
);

export default withTheme(List);
