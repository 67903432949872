import React, { Fragment, useState } from "react";
import { ActivityIndicator, ScrollView } from "react-native";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import { withTheme } from "styled-components/native";
import { Query } from "react-apollo";
import { Field, FormSpy } from "react-final-form";
import {
  array as yupArray,
  number as yupNumber,
  object as yupObject,
  string as yupString
} from "yup";
import {
  FormGeneralStatusField,
  InspectSubtitle,
  InspectTitle,
  MutableInspect
} from "cci-admin";
import {
  Button,
  Container,
  DeletableItem,
  Dialog,
  FormCell,
  FormDateField,
  FormEmailField,
  FormSelectValueField,
  FormTextField,
  FormToManyField,
  RoundedIcon,
  RowText,
  StoredRoundImage,
  TouchableRow
} from "cci-component";
import { findBusinessByIdQuery, SessionQuery } from "cci-data";
import { SecuredComponent } from "cci-logic";
import { localize, serverFormatDateRegex, withRouter } from "cci-utility";
import {
  findPatientByIdQuery,
  savePatientMutation,
  deletePatientMutation,
  PlayersQuery
} from "../../query/Patient";
import { countries } from "../countries";
import { findTeamGroupsByBusinessQuery } from "../../query/TeamGroup";
import FormBaseField from "./FormBaseField";
import SelectRecordOptionsField from "./SelectRecordOptionsField";
import teamLogo from "../../assets/images/order.png";

const messages = defineMessages({
  team: {
    id: "team",
    defaultMessage: "Team"
  },
  teamGroup: {
    id: "teamGroup",
    defaultMessage: "Team Group"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  notes: {
    id: "notes",
    defaultMessage: "Notes"
  },
  emails: {
    id: "emails",
    defaultMessage: "E-mails"
  },
  addresses: {
    id: "addresses",
    defaultMessage: "Addresses"
  },
  phones: {
    id: "phones",
    defaultMessage: "Phones"
  },
  patient: {
    id: "patient",
    defaultMessage: "Personnel"
  },
  name: {
    id: "name",
    defaultMessage: "Name"
  },
  type: {
    id: "type",
    defaultMessage: "Type"
  },
  birthDate: {
    id: "birthDate",
    defaultMessage: "DOB"
  },
  line1: {
    id: "line1",
    defaultMessage: "Address"
  },
  city: {
    id: "city",
    defaultMessage: "City"
  },
  state: {
    id: "state",
    defaultMessage: "State"
  },
  zipCode: {
    id: "zipCode",
    defaultMessage: "Zip Code"
  },
  country: {
    id: "country",
    defaultMessage: "Country"
  },
  identifiers: {
    id: "identifiers",
    defaultMessage: "Identifiers"
  },
  identifierNumber: {
    id: "identifierNumber",
    defaultMessage: "Identifier Number"
  },
  identifierNumberPlaceholder: {
    id: "identifierNumberPlaceholder",
    defaultMessage: "##### ####"
  },
  socialSecurity: {
    id: "socialSecurity",
    defaultMessage: "Social Security"
  },
  passport: {
    id: "passport",
    defaultMessage: "Passport"
  },
  driverLicense: {
    id: "driverLicense",
    defaultMessage: "Driver License"
  },
  other: {
    id: "other",
    defaultMessage: "Other"
  },
  unknown: {
    id: "unknown",
    defaultMessage: "Unknown"
  },
  work: {
    id: "work",
    defaultMessage: "Work"
  },
  personal: {
    id: "personal",
    defaultMessage: "Personal"
  },
  billing: {
    id: "billing",
    defaultMessage: "Billing"
  },
  main: {
    id: "main",
    defaultMessage: "Main"
  },
  home: {
    id: "home",
    defaultMessage: "Home"
  },
  shipping: {
    id: "shipping",
    defaultMessage: "Shipping"
  },
  previous: {
    id: "previous",
    defaultMessage: "Previous"
  },
  mobile: {
    id: "mobile",
    defaultMessage: "Mobile"
  },
  fax: {
    id: "fax",
    defaultMessage: "Fax"
  },
  number: {
    id: "number",
    defaultMessage: "Number"
  },
  selectAType: {
    id: "selectAType",
    defaultMessage: "Select a Type"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  playersLinking: {
    id: "playersLinking",
    defaultMessage: "Players linking"
  },
  errorHappened: {
    id: "errorHappened",
    defaultMessage: "An error happened. Please try again later."
  },
  empty: {
    id: "empty",
    defaultMessage: "No data was found."
  }
});

/**
 * Inspect component for the Patient entity, allows the user to create,
 * edit or delete records if they have permission to do so.
 */
const Inspect = ({ theme, intl, teamChange, history, ...restProps }) => {
  const [visible, setVisible] = useState(false),
    [idSelected, selectId] = useState(null),
    [businessExternalId, setBusinessExternalId] = useState(null);

  return (
    <SessionQuery>
      {({ wrapBusiness, wrapOffice }) => (
        <MutableInspect
          entityName="Patient"
          query={findPatientByIdQuery}
          queryName="findPatientById"
          mutation={savePatientMutation}
          mutationName="savePatient"
          objectWrapper="patient"
          defaultValues={{
            status: "active",
            contact: {
              office: wrapOffice().office,
              type: "person"
            }
          }}
          blockRedirection={!teamChange}
          onCompleted={saved => {
            !teamChange && saved.business.id !== wrapBusiness().business.id && history.replace(`${restProps.rootPath}`);
          }}
          validationSchema={yupObject().shape({
            contact: yupObject().shape({
              birthDate: yupString()
                .nullable()
                .matches(serverFormatDateRegex, "invalidDate"),
              companyName: yupString()
                .nullable()
                .max(50, "max50"),
              firstName: yupString()
                .max(35, "max35")
                .required(),
              lastName: yupString()
                .max(35, "max35")
                .required(),
              emails: yupArray().of(
                yupObject().shape({
                  address: yupString().email("invalidEmailFieldError")
                })
              )
            })
          })}
          deleteMutation={teamChange ? undefined : deletePatientMutation}
          {...restProps}
        >
          {() => (
            <Container width="100%">
              <Field name="business">{() => null}</Field>

              {/* TEAM CHANGING */}
              <FormSpy subscription={{ initialValues: true, modified: true, values: true }}>
                {({ form: { getFieldState } }) => {
                  if (getFieldState("business") && getFieldState("business").value) {
                    return (
                      <Query
                        query={findTeamGroupsByBusinessQuery}
                        variables={{ business: { id: getFieldState("business").value.id } }}
                      >
                        {({ data, loading, error }) => {
                          let teamsArray = [],
                            imageId = '';
                          if (!loading && !error) {
                            data.findTeamGroupsByBusiness.forEach(group => {
                              teamsArray = teamsArray.concat(group.teams);
                            });
                            imageId = teamsArray.length > 0 &&
                              teamsArray.find(btg => btg.business.id === getFieldState("business").value.id)
                                .business.contact.photoId
                          }

                          if (teamsArray.length > 0) {
                            return (
                              <SubInspect {...{ teamChange, teamsArray, imageId }} />
                            );
                          }

                          return (
                            <Query
                              query={findBusinessByIdQuery}
                              variables={{ id: getFieldState("business").value.id }}
                            >
                              {({ data: data1, loading: loading1, error: error1 }) => {

                                if (!loading1 && !error1) {
                                  teamsArray = [{ business: data1.findBusinessById }];
                                  imageId = data1.findBusinessById.contact.photoId;
                                }
                                return (
                                  <SubInspect {...{ teamChange, teamsArray, imageId }} />
                                )
                              }}
                            </Query>
                          )
                        }}
                      </Query>
                    );
                  }
                  return null
                }}
              </FormSpy>

              {!teamChange && (
                <Fragment>

                  <Section>
                    {/* First Name */}
                    <FormCell xlSize="37%">
                      <FormTextField
                        label={messages.firstName}
                        name="contact.firstName"
                      />
                    </FormCell>

                    {/* Last Name */}
                    <FormCell xlSize="stretch">
                      <FormTextField
                        label={messages.lastName}
                        name="contact.lastName"
                      />
                    </FormCell>

                    {/* Birth Date */}
                    <FormCell xlSize="stretch">
                      <FormDateField
                        label={messages.birthDate}
                        name="contact.birthDate"
                        placeholder="mm/dd/yyyy"
                      />
                    </FormCell>

                    {/* Status */}
                    <FormCell xlSize={150}>
                      <FormGeneralStatusField />
                    </FormCell>
                  </Section>

                  <Section>
                    {/* Notes / Allergies */}
                    <FormCell size="stretch">
                      <FormTextField
                        label={messages.notes}
                        name="allergies"
                        maxLength={256}
                      />
                    </FormCell>

                    <FormSpy>
                      {({ form: { getFieldState } }) => (
                        <Query
                          query={findBusinessByIdQuery}
                          skip={!getFieldState("business").value}
                          variables={{ id: getFieldState("business").value && getFieldState("business").value.id }}
                        >
                          {({ data }) => {

                            if (data && data.findBusinessById && data.findBusinessById.externalId && data.findBusinessById.type && data.findBusinessById.type === "NBA") {
                              return (
                                <FormCell xlSize={100}>
                                  <Field name="externalId">
                                    {({ input }) => (
                                      <Button
                                        leftIcon="link"
                                        ui={input.value ? "linkedTeam" : "notLinkedTeam"}
                                        disabled={false}
                                        onPress={() => {
                                          if (input.value && (idSelected === null || idSelected !== input.value)) {
                                            selectId && selectId(input.value)
                                          }
                                          if (!input.value) {
                                            selectId && selectId(null)
                                          }

                                          setBusinessExternalId(data.findBusinessById.externalId)
                                          setVisible(true)
                                        }}
                                      />
                                    )}
                                  </Field>
                                </FormCell>
                              )
                            }
                            return null;
                          }}
                        </Query>
                      )}
                    </FormSpy>
                  </Section>

                  {/* Identifiers */}
                  <InspectSubtitle title={messages.identifiers} />

                  <FormToManyField
                    name="contact.identifiers"
                    addValidation={yupObject().shape({
                      identifierNumber: yupString()
                        .required()
                    })}
                    defaultValues={{ type: "driverLicense" }}
                    renderNewOnButton
                  >
                    {({ name, isNew, push, remove, index, plus, length }) => (
                      <Container
                        key={name}
                        flexDirection="row"
                        alignItems="center"
                        paddings="0 10px"
                        width="100%"
                        style={{ maxWidth: 500 }}
                      >
                        <DeletableItem
                          allowDelete={!isNew}
                          height={60}
                          iconName="times"
                          confirmMessage={messages.confirmDeleteItem}
                          onConfirm={remove}
                          checkPermissions={{
                            objects: "ContactIdentifier",
                            actions: ["delete"]
                          }}
                        >
                          {/* id-card or add icon */}
                          <RoundedIcon
                            iconSize={50}
                            color={theme.fieldFontColor || theme.fontColor}
                            name={isNew ? "plus-circle" : "id-card"}
                          />
                        </DeletableItem>

                        <FormSelectValueField
                          options={[
                            messages.socialSecurity,
                            messages.passport,
                            messages.driverLicense,
                            messages.other,
                            messages.unknown
                          ]}
                          label={messages.type}
                          placeholder={messages.driverLicense}
                          name={`${name}.type`}
                          margins="0 0 0 10px"
                          width={135}
                        />

                        {/* IDENTIFIER Number field */}
                        <FormTextField
                          label={messages.identifierNumber}
                          placeholder={messages.identifierNumberPlaceholder}
                          name={`${name}.identifierNumber`}
                          onBlur={push}
                          margins="0 0 0 10px"
                          flex={1}
                        />

                        {/* New Item Button */}
                        <SecuredComponent
                          checkPermissions={{
                            objects: "ContactEmail",
                            actions: ["create"]
                          }}
                        >
                          {!isNew && index + 1 === length && (
                            <Button
                              leftIcon="plus"
                              margins="20px 0 0 10px"
                              ui="dangerLink"
                              leftIconWeight={900}
                              onPress={plus}
                            />
                          )}
                        </SecuredComponent>
                      </Container>
                    )}
                  </FormToManyField>

                  {/* E-mails */}
                  <InspectSubtitle title={messages.emails} />

                  <FormToManyField
                    name="contact.emails"
                    addValidation={yupObject().shape({
                      address: yupString()
                        .required()
                        .email()
                    })}
                    renderNewOnButton
                  >
                    {({ name, isNew, push, remove, index, plus, length }) => (
                      <Container
                        key={name}
                        flexDirection="row"
                        alignItems="center"
                        paddings="0 10px"
                        width="100%"
                        style={{ maxWidth: 500 }}
                      >
                        <DeletableItem
                          allowDelete={!isNew}
                          height={60}
                          iconName="times"
                          confirmMessage={messages.confirmDeleteItem}
                          onConfirm={remove}
                          checkPermissions={{
                            objects: "ContactEmail",
                            actions: ["delete"]
                          }}
                        >
                          {/* E-mail or add icon */}
                          <RoundedIcon
                            iconSize={50}
                            color={theme.fieldFontColor || theme.fontColor}
                            name={isNew ? "plus-circle" : "envelope"}
                          />
                        </DeletableItem>

                        <FormSelectValueField
                          options={[
                            messages.work,
                            messages.personal,
                            messages.billing
                          ]}
                          placeholder={messages.selectAType}
                          name={`${name}.tags`}
                          margins="0 0 0 10px"
                          width={135}
                        />
                        {/* E-mail address field */}
                        <FormEmailField
                          name={`${name}.address`}
                          onBlur={push}
                          margins="0 0 0 10px"
                          flex={1}
                        />

                        {/* New Item Button */}
                        <SecuredComponent
                          checkPermissions={{
                            objects: "ContactEmail",
                            actions: ["create"]
                          }}
                        >
                          {!isNew && index + 1 === length && (
                            <Button
                              leftIcon="plus"
                              margins="0 0 0 10px"
                              ui="dangerLink"
                              leftIconWeight={900}
                              onPress={plus}
                            />
                          )}
                        </SecuredComponent>
                      </Container>
                    )}
                  </FormToManyField>

                  {/* Addresses */}
                  <InspectSubtitle title={messages.addresses} />
                  <FormToManyField
                    name="contact.addresses"
                    addValidation={yupObject().shape({
                      line1: yupString().required(),
                      city: yupString().required(),
                      // country: yupString().required(),
                      state: yupString().required(),
                      zipCode: yupNumber().required()
                    })}
                    renderNewOnButton
                  >
                    {({ name, isNew, push, remove, value, index, plus, length }) => (
                      <Container
                        key={name}
                        flexDirection="row"
                        paddings="0 10px"
                        flexShrink={1}
                        width="100%"
                      >
                        <DeletableItem
                          allowDelete={!isNew}
                          height={60}
                          margins="10px 0 0"
                          iconName="times"
                          confirmMessage={messages.confirmDeleteItem}
                          onConfirm={remove}
                          checkPermissions={{
                            objects: "ContactAddress",
                            actions: ["delete"]
                          }}
                        >
                          <RoundedIcon
                            iconSize={50}
                            color={theme.fieldFontColor}
                            name={isNew ? "map-marker-plus" : "map-marker-alt"}
                          />
                        </DeletableItem>

                        <Section style={{ flex: 1 }}>
                          <Container
                            margins="10px 0 0 10px"
                            flex={1}
                            style={{ minWidth: 100 }}
                          >
                            <FormSelectValueField
                              label={messages.type}
                              options={[
                                messages.main,
                                messages.work,
                                messages.home,
                                messages.billing,
                                messages.shipping,
                                messages.previous
                              ]}
                              name={`${name}.tags`}
                            />
                          </Container>
                          <Container
                            margins="10px 0 0 10px"
                            flex={2}
                            style={{ minWidth: 220 }}
                          >
                            <FormTextField
                              label={messages.line1}
                              name={`${name}.line1`}
                              onBlur={push}
                            />
                          </Container>
                          <Container
                            margins="10px 0 0 10px"
                            flex={1}
                            style={{ minWidth: 100 }}
                          >
                            <FormTextField
                              label={messages.city}
                              name={`${name}.city`}
                              onBlur={push}
                            />
                          </Container>
                          <Container
                            margins="10px 0 0 10px"
                            flex={1}
                            style={{ minWidth: 100 }}
                          >
                            <FormTextField
                              label={messages.state}
                              name={`${name}.state`}
                              onBlur={push}
                            />
                          </Container>
                          <Container
                            margins="10px 0 0 10px"
                            flex={1}
                            style={{ minWidth: 170 }}
                          >
                            <FormSelectValueField
                              label={messages.country}
                              options={countries}
                              name={`${name}.country`}
                              onChangeText={push}
                              defaultValue="US"
                            />
                          </Container>
                          <Container
                            margins="10px 0 0 10px"
                            flex={1}
                            style={{ minWidth: 100, maxWidth: 200 }}
                          >
                            <FormTextField
                              label={messages.zipCode}
                              name={`${name}.zipCode`}
                              onBlur={push}
                            />
                          </Container>

                          {/* New Item Button */}
                          <SecuredComponent
                            checkPermissions={{
                              objects: "ContactAddress",
                              actions: ["create"]
                            }}
                          >
                            {!isNew && index + 1 === length && (
                              <Button
                                leftIcon="plus"
                                margins="40px 0 0 10px"
                                ui="dangerLink"
                                leftIconWeight={900}
                                onPress={plus}
                              />
                            )}
                          </SecuredComponent>
                        </Section>
                      </Container>
                    )}
                  </FormToManyField>

                  {/* Phones */}
                  <InspectSubtitle title={messages.phones} />
                  <FormToManyField
                    name="contact.phones"
                    addValidation={yupObject().shape({
                      phoneNumber: yupString().required()
                    })}
                    renderNewOnButton
                  >
                    {({ name, isNew, push, remove, value, index, plus, length }) => (
                      <Container
                        key={name}
                        flexDirection="row"
                        alignItems="center"
                        paddings="0 10px"
                        width="100%"
                        style={{ maxWidth: 500 }}
                      >
                        <DeletableItem
                          allowDelete={!isNew}
                          height={60}
                          iconName="times"
                          confirmMessage={messages.confirmDeleteItem}
                          onConfirm={remove}
                          checkPermissions={{
                            objects: "ContactPhone",
                            actions: ["delete"]
                          }}
                        >
                          <RoundedIcon
                            iconSize={50}
                            color={theme.fieldFontColor}
                            name={isNew ? "plus-circle" : "phone"}
                          />
                        </DeletableItem>

                        <FormSelectValueField
                          margins="0 0 0 10px"
                          width={135}
                          label={messages.type}
                          options={[
                            messages.work,
                            messages.home,
                            messages.mobile,
                            messages.main,
                            messages.fax,
                            messages.shipping,
                            messages.billing
                          ]}
                          name={`${name}.tags`}
                        />
                        <FormTextField
                          margins="0 0 0 10px"
                          label={messages.number}
                          name={`${name}.phoneNumber`}
                          onBlur={push}
                          flex={1}
                        />

                        {/* New Item Button */}
                        <SecuredComponent
                          checkPermissions={{
                            objects: "ContactPhone",
                            actions: ["create"]
                          }}
                        >
                          {!isNew && index + 1 === length && (
                            <Button
                              leftIcon="plus"
                              margins="20px 0 0 10px"
                              ui="dangerLink"
                              leftIconWeight={900}
                              onPress={plus}
                            />
                          )}
                        </SecuredComponent>
                      </Container>
                    )}
                  </FormToManyField>

                  <FormSpy>
                    {({ form: { getState, change } }) => (
                      <Dialog
                        title={messages.playersLinking}
                        width={.5}
                        height={.7}
                        isVisible={visible}
                        onBackdropPress={() => setVisible(false)}
                        onSelected={(id) => {
                          setVisible(false)
                          selectId(id)
                        }}
                        idSelected={idSelected}
                      >
                        <Query query={PlayersQuery} variables={{ pid: businessExternalId }}>
                          {({ loading, error, data }) => (
                            <ScrollView>
                              {loading && <ActivityIndicator />}

                              {!loading && error && <RowText>{localize(intl, messages.errorHappened)}</RowText>}

                              {!loading && !error && (!data || (data && !data.players) || (data && data.players.length === 0)) && <RowText>{localize(intl, messages.empty)}</RowText>}

                              {!loading && data && data.players && data.players.map(player => (
                                <TouchableRow
                                  key={player.nbaId}
                                  style={idSelected === player.nbaId ? { backgroundColor: "#0054d4" } : {}}
                                  onPress={() => {
                                    // selectId(player.nbaId)
                                    change("externalId", player.nbaId)
                                    setVisible(false)
                                  }}
                                >
                                  <Container flexDirection="row" flex={1}>
                                    <RowText width={70}>{player.nbaId}</RowText>
                                    <RowText flex={1}>{player.firstName + " " + player.lastName}</RowText>
                                    <RowText width={100}>
                                      {new Date(player.dob + " 5:00")
                                        .toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit"
                                        })
                                      }
                                    </RowText>
                                  </Container>
                                </TouchableRow>
                              ))}
                            </ScrollView>
                          )}
                        </Query>
                      </Dialog>
                    )}
                  </FormSpy>
                </Fragment>
              )}


            </Container>
          )}
        </MutableInspect>
      )}
    </SessionQuery>
  )
};

Inspect.propTypes = {
  theme: PropTypes.shape().isRequired
};

const SubInspect = ({ teamChange, teamsArray, imageId }) => {
  const imageSize = 100,
    styleM = teamChange ? {} : { alignItems: 'center' };

  return (
    <Fragment>
      <Section>
        <InspectTitle title={messages.patient} />
      </Section>

      {teamChange && (
        <Section>
          <Field name="contact.firstName">
            {({ input }) => (
              <Field name="contact.lastName">
                {({ input: lastInput }) => (
                  <InspectSubtitle
                    title={`${input.value} ${lastInput.value}`}
                  />
                )}
              </Field>
            )}
          </Field>
        </Section>
      )}

      <Section style={styleM}>
        <FormCell xlSize="1/2" size="1">
          <FormBaseField
            fieldComponent={SelectRecordOptionsField}
            changeEvent="onChange"
            valueVersionOnly
            label={messages.team}
            options={teamsArray.map(btg => btg.business)}
            name="business"
            labelKey="contact.companyName"
          />
        </FormCell>

        <FormCell xlSize="1/2" size="1" style={{ alignItems: "center" }}>
          <StoredRoundImage
            size={imageSize}
            imageId={imageId}
            placeholder={teamLogo}
          />
        </FormCell>
      </Section>
    </Fragment>
  );
};


export default withRouter(injectIntl(withTheme(Inspect)));
