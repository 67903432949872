/**
 * ProductsPage
 *
 * @format
 * @flow
 */

import React from "react";
import { defineMessages } from "react-intl";
import { ListInspect } from "cci-component";
import { PrimaryPage } from "cci-admin";
import List from "../view/product/List";
import Inspect from "../view/product/Inspect";

const messages = defineMessages({
  productsPlaceholder: {
    id: "productsPlaceholder",
    defaultMessage:
      "Select an item from the left to view or edit.\n\n" +
      "Select + to add a new item."
  }
});

const ProductsPage = () => (
  <PrimaryPage>
    <ListInspect
      rootPath="/products"
      left={List}
      placeholderMessage={messages.productsPlaceholder}
      selectionPath="/products/:id"
      right={Inspect}
    />
  </PrimaryPage>
);

export default ProductsPage;
