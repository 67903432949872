import React, { Fragment } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Section } from "react-native-responsive-layout";
import styled, { withTheme } from "styled-components/native";
import {
  object as yupObject,
  string as yupString
} from "yup";
import {
  Container,
  FormCell,
  FormTextField,
  FormUploadImageField,
  Label,
  layoutCss
} from "cci-component";
import {
  findDoctorByIdQuery,
  findDoctorsByNameQuery,
  saveDoctorMutation,
  deleteDoctorMutation
} from "../query/Doctor";
import CrudField from "./CrudField";

const messages = defineMessages({
  searchByNameEmail: {
    id: "searchByNameEmail",
    defaultMessage: "Search By Name or E-mail"
  },
  noAddressData: {
    id: "noAddressData",
    defaultMessage: "No Address"
  },
  noPhoneData: {
    id: "noPhoneData",
    defaultMessage: "No Phone"
  },
  doctor: {
    id: "doctor",
    defaultMessage: "Doctor"
  },
  date: {
    id: "date",
    defaultMessage: "Date"
  },
  confirmDeleteItem: {
    id: "confirmDeleteItem",
    defaultMessage: "Are you sure you want to delete this item?"
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company Name"
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name"
  },
  birthDate: {
    id: "birthDate",
    defaultMessage: "Birthdate"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  emptyMessage: {
    id: "emptyMessage",
    defaultMessage: "Doctor not found"
  }
});

const StyledText2 = styled.Text`
  ${layoutCss};
  color: ${props => props.color || props.theme.fontColor};
`;

const StyledText = ({ children, ...props }) => (
  <StyledText2 numberOfLines={1} {...props}>
    {children}
  </StyledText2>
);

/**
 * Crud Field for Doctor Model
 */
const DoctorCrudField = ({
  name,
  listMargins,
  topList,
  defaultValues,
  onSelect,
  theme,
  disabled,
  onSearchFocus,
  variablesByName
}) => (
  <CrudField
    title={messages.doctor}
    label={messages.doctor}
    photo={false}
    queryById={findDoctorByIdQuery}
    queryByName={findDoctorsByNameQuery}
    queryName="findDoctorById"
    queryNameByName="findDoctorsByName"
    variablesByName={variablesByName}
    saveMutation={saveDoctorMutation}
    saveMutationName="saveDoctor"
    deleteMutation={deleteDoctorMutation}
    entityName="Doctor"
    objectWrapper="doctor"
    name={name}
    listMargins={listMargins}
    topList={topList}
    emptyMessage={messages.emptyMessage}
    searchPlaceholder={messages.searchByNameEmail}
    onSelect={onSelect}
    defaultValues={defaultValues}
    disabled={disabled}
    onSearchFocus={onSearchFocus}
    validationSchema={yupObject().shape({
      contact: yupObject().shape({
        // birthDate: yupString()
        //   .nullable()
        //   .matches(serverFormatDateRegex, "invalidDate"),
        companyName: yupString()
          .nullable()
          .max(50, "max50"),
        firstName: yupString()
          .max(35, "max35")
          .required(),
        lastName: yupString()
          .max(35, "max35")
          .required(),
      })
    })}
    selectedDisplay={selectedData => (
      <Container
        flexDirection="row"
        height={"35px"}
        backgroundColor={theme.fieldBackgroundColor}
        style={{ borderRadius: 5 }}
        alignItems="center"
      >
        <Container flex={1}>
          {selectedData && selectedData.contact && (
            <FormCell>
              <Label color="white" numberOfLines={1}>
                {selectedData.contact.firstName +
                  " " +
                  selectedData.contact.lastName}
              </Label>
            </FormCell>
          )}
        </Container>
      </Container>
    )}
    rowData={data => (
      <Fragment>
        <StyledText>
          {data.item.contact.firstName + " " + data.item.contact.lastName}
        </StyledText>
      </Fragment>
    )}
    inspectContent={() => (
      <Fragment>
        <Section>
          {/* Photo */}
          <FormCell size={60} style={{ paddingRight: 0 }}>
            <FormUploadImageField
              name="contact.photoId"
              size={50}
              border={4}
              entityName="Contact"
              alignSelf="center"
            />
          </FormCell>
          <FormCell size="stretch">
            <FormTextField
              placeholder={messages.firstName}
              name="contact.firstName"
              margins="10px 0"
            />
            <FormTextField
              placeholder={messages.lastName}
              name="contact.lastName"
            />
            {/* <FormDateField
              placeholder={messages.birthDate}
              name="contact.birthDate"
              margins="10px 0 0"
            /> */}
          </FormCell>
        </Section>

      </Fragment>
    )}
  />
);

export default injectIntl(withTheme(DoctorCrudField));
