import gql from "graphql-tag";

const patientFieldsFragment = gql`
  fragment PatientFields on Patient {
    id
    version
    status
    allergies
    externalId
    contact {
      id
      version
      firstName
      middleName
      lastName
      companyName
      birthDate
      photoId
      identifiers {
        id
        version
        identifierNumber
        status
        type
      }
      emails {
        id
        version
        address
        status
        tags
      }
      addresses {
        id
        version
        city
        country
        line1
        state
        tags
        zipCode
      }
      phones {
        id
        version
        phoneNumber
        tags
      }
    }
    business {
      id
      version
    }
  }
`;

const findPatientByIdQuery = gql`
  query findPatientById($id: UUID) {
    findPatientById(id: $id) {
      ...PatientFields
    }
  }
  ${patientFieldsFragment}
`;

const findPatientsByNameQuery = gql`
  query findPatientsByName(
    $business: BusinessInput
    $name: String
    $teamChange: Boolean
    $startIndex: Int
    $stopIndex: Int
  ) {
    findPatientsByName(
      business: $business
      name: $name
      teamChange: $teamChange
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
        sort: {
          orders: [
            { direction: "ASC", property: "contact.lastName" }
            { direction: "ASC", property: "contact.firstName" }
          ]
        }
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...PatientFields
      }
    }
  }
  ${patientFieldsFragment}
`;

const savePatientMutation = gql`
  mutation($patient: PatientInput) {
    savePatient(patient: $patient) {
      ...PatientFields
    }
  }
  ${patientFieldsFragment}
`;

const deletePatientMutation = gql`
  mutation($patient: PatientInput) {
    deletePatient(patient: $patient) {
      id
      version
    }
  }
`;

const PlayersQuery = gql`
  query players ($pid: Int!) {
    players(pid: $pid) {
      nbaId
      firstName
      lastName
      dob
    }
  }
`

export {
  findPatientByIdQuery,
  findPatientsByNameQuery,
  savePatientMutation,
  deletePatientMutation,
  PlayersQuery
};
