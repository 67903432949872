import gql from "graphql-tag";

const teamGroupFieldsFragment = gql`
  fragment TeamGroupFields on TeamGroup {
    id
    version
    status
    name
    teams {
      id
      version
      business {
        id
        version
        contact {
          id
          version
          companyName
          photoId
        }
      }
    }
  }
`;

const findTeamGroupByIdQuery = gql`
  query($id: UUID) {
    findTeamGroupById(id: $id) {
      ...TeamGroupFields
    }
  }
  ${teamGroupFieldsFragment}
`;

const findTeamGroupsByNameQuery = gql`
  query findTeamGroupsByName(
    $name: String
    $startIndex: Int
    $stopIndex: Int
  ) {
    findTeamGroupsByName(
      name: $name
      pageable: {
        startIndex: $startIndex
        stopIndex: $stopIndex
        _type_: Range
      }
    ) {
      numberOfElements
      totalPages
      totalElements
      size
      number
      content {
        ...TeamGroupFields
      }
    }
  }
  ${teamGroupFieldsFragment}
`;

const findTeamGroupsByBusinessQuery = gql`
  query findTeamGroupsByBusiness (
    $business: BusinessInput
  ) {
    findTeamGroupsByBusiness (
      business: $business
    ) {
      ...TeamGroupFields
    }
  }
  ${teamGroupFieldsFragment}
`;

const saveTeamGroupMutation = gql`
  mutation($teamGroup: TeamGroupInput) {
    saveTeamGroup(teamGroup: $teamGroup) {
      ...TeamGroupFields
    }
  }
  ${teamGroupFieldsFragment}
`;

const deleteTeamGroupMutation = gql`
  mutation deleteTeamGroup($teamGroup: TeamGroupInput) {
    deleteTeamGroup(teamGroup: $teamGroup) {
      id
      version
    }
  }
`;

export {
  findTeamGroupByIdQuery,
  findTeamGroupsByNameQuery,
  findTeamGroupsByBusinessQuery,
  saveTeamGroupMutation,
  deleteTeamGroupMutation
};
