import React from "react";
import { defineMessages } from "react-intl";
import { WideSplitView } from "cci-component";
import { PrimaryPage, ReportPreview } from "cci-admin";
import Options from "../report/certificate/Options";

const messages = defineMessages({
  selectReportOptions: {
    id: "selectReportOptions",
    defaultMessage: "Select the desired options to preview or print the report."
  }
});

/**
 * Certificate Reports Page.
 */
const CertificateReportPage = () => (
  <PrimaryPage>
    <WideSplitView
      rootPath="/certificatereport"
      selectionPath="/certificatereport/preview/:options"
      left={Options}
      right={ReportPreview}
      leftRightOptions={{
        reportName: "prs/data/report/inventoryAuditCertificate.rptdesign"
      }}
      leftOptions={{ previewPath: "/certificatereport" }}
      placeholderMessage={messages.selectReportOptions}
    />
  </PrimaryPage>
);

export default CertificateReportPage;
